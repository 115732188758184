import { ELevel, IPetProfileDetailsRecord } from '@fido/common';
import { unit } from '@fido/styles';
import { Checkbox, Description, Heading, Text } from '@fido/web-components';
import React from 'react';
import styled from 'styled-components';

const DetailsCheckboxesContainer = styled.div`
  padding: ${unit * 2}px 0;
  display: flex;
  flex-direction: column;
`;

const DetailsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxField = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${unit}px;
  align-items: center;
  justify-content: flex-start;
`;

const CheckboxFieldText = styled(Text)`
  margin-left: ${unit}px;
  font-size: 11px;
`;

const DetailsFieldsContainer = styled.div`
  padding: ${unit * 2}px 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 375px) {
    width: 300px;
  }
  @media (max-width: 768px) and (min-width: 375px) {
    width: 350px;
  }
`;

export interface IPetProfileDetailsProps {
  details: IPetProfileDetailsRecord;
  onChangeDetails(details: IPetProfileDetailsRecord): void;
}

export const PetProfileDetails = ({
  details,
  onChangeDetails,
}: IPetProfileDetailsProps) => (
  <DetailsCheckboxesContainer>
    <DetailsTextContainer>
      <Heading>Details</Heading>
      <Description>Check all that apply</Description>
    </DetailsTextContainer>
    <DetailsFieldsContainer>
      <LevelField
        label="Kid Friendly"
        level={details.kidFriendly}
        onChangeLevel={level =>
          onChangeDetails(details.set('kidFriendly', level))
        }
      />
      <LevelField
        label="Friendly with Other Pets"
        level={details.petFriendly}
        onChangeLevel={level =>
          onChangeDetails(details.set('petFriendly', level))
        }
      />
      <LevelField
        label="Friendly with Other Dogs"
        level={details.dogFriendly}
        onChangeLevel={level =>
          onChangeDetails(details.set('dogFriendly', level))
        }
      />
      <LevelField
        label="Bonds Easily"
        level={details.bondsEasily}
        onChangeLevel={level =>
          onChangeDetails(details.set('bondsEasily', level))
        }
      />
      <LevelField
        label="Friendly with People"
        level={details.friendlyWithPeople}
        onChangeLevel={level =>
          onChangeDetails(details.set('friendlyWithPeople', level))
        }
      />
      <LevelField
        label="Tolerance to Being Alone"
        level={details.toleranceToBeingAlone}
        onChangeLevel={level =>
          onChangeDetails(details.set('toleranceToBeingAlone', level))
        }
      />
      <LevelField
        label="Easy to Train"
        level={details.easyToTrain}
        onChangeLevel={level =>
          onChangeDetails(details.set('easyToTrain', level))
        }
      />
      <LevelField
        label="Drool"
        level={details.drool}
        onChangeLevel={level => onChangeDetails(details.set('drool', level))}
      />
      <LevelField
        label="Amount of Shedding"
        level={details.amountOfShedding}
        onChangeLevel={level =>
          onChangeDetails(details.set('amountOfShedding', level))
        }
      />
      <LevelField
        label="Grooming Required"
        level={details.groomingRequired}
        onChangeLevel={level =>
          onChangeDetails(details.set('groomingRequired', level))
        }
      />
      <LevelField
        label="Energy"
        level={details.energy}
        onChangeLevel={level => onChangeDetails(details.set('energy', level))}
      />
      <LevelField
        label="Excercise Needs"
        level={details.exerciseNeeds}
        onChangeLevel={level =>
          onChangeDetails(details.set('exerciseNeeds', level))
        }
      />
      <LevelField
        label="Adapts to Apartment Life"
        level={details.adaptsToApartmentLife}
        onChangeLevel={level =>
          onChangeDetails(details.set('adaptsToApartmentLife', level))
        }
      />
    </DetailsFieldsContainer>
  </DetailsCheckboxesContainer>
);

const LevelsFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${unit}px 0;
`;

const CheckboxFields = styled.div`
  display: flex;
  flex-direction: row;
`;

const LevelLabelText = styled(Text)`
  font-size: 14px;
`;

export interface ILevelFieldProps {
  label?: string;
  level: ELevel;
  onChangeLevel(level: ELevel): void;
}

export const LevelField = ({
  label,
  level,
  onChangeLevel,
}: ILevelFieldProps) => (
  <LevelsFieldContainer>
    <LevelLabelText>{label}</LevelLabelText>
    <CheckboxFields>
      <CheckboxField>
        <Checkbox
          selected={level === ELevel.low}
          onClick={() => onChangeLevel(ELevel.low)}
        />
        <CheckboxFieldText>Low</CheckboxFieldText>
      </CheckboxField>
      <CheckboxField>
        <Checkbox
          selected={level === ELevel.medium}
          onClick={() => onChangeLevel(ELevel.medium)}
        />
        <CheckboxFieldText>Medium</CheckboxFieldText>
      </CheckboxField>
      <CheckboxField>
        <Checkbox
          selected={level === ELevel.high}
          onClick={() => onChangeLevel(ELevel.high)}
        />
        <CheckboxFieldText>High</CheckboxFieldText>
      </CheckboxField>
    </CheckboxFields>
  </LevelsFieldContainer>
);
