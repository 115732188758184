import { IPetProfileDetailsRecord } from '@fido/common';
import { unit } from '@fido/styles';
import {
  Button,
  Heading,
  isDesktop,
  TextAreaFormField,
} from '@fido/web-components';
import every from 'lodash/every';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PetProfileDetails } from '../../../petProfileDetails';

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormColumn = styled.div`
  padding: ${unit * 3}px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledTextAreaFormField = styled(TextAreaFormField)`
  padding: ${unit}px 0;

  textarea {
    min-height: 250px;
  }
`;

const NavButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > button {
    width: 167px;

    &:first-child {
      margin-right: ${unit * 2}px;
    }
  }
`;

const validateBio = (bio: string) => !!bio;

const validateForm = (fields: { bio: string }) =>
  every([validateBio(fields.bio)]);

export interface ICreatePuppyProfileSecondStepProps {
  bio: string;
  details: IPetProfileDetailsRecord;
  onChangeBio(bio: string): void;
  onChangeDetails(details: IPetProfileDetailsRecord): void;
  onClickGoBack(): void;
  onClickContinue(): void;
}

export const CreatePuppyProfileSecondStep = ({
  bio,
  details,
  onChangeDetails,
  onChangeBio,
  onClickGoBack,
  onClickContinue,
}: ICreatePuppyProfileSecondStepProps) => {
  const [showValidStates, setShowValidStates] = useState(false);
  const valid = validateForm({
    bio,
  });
  const continueToNextScreen = () => {
    setShowValidStates(true);
    if (valid) {
      onClickContinue();
    }
  };
  return (
    <>
      <Heading>Step 2: Description</Heading>
      {isDesktop() ? (
        <FormContainer>
          <FormColumn>
            <StyledTextAreaFormField
              label="Pup Description"
              value={bio}
              valid={showValidStates ? validateBio(bio) : true}
              onChangeValue={onChangeBio}
            />
          </FormColumn>
          <FormColumn>
            <PetProfileDetails
              details={details}
              onChangeDetails={onChangeDetails}
            />
          </FormColumn>
        </FormContainer>
      ) : (
        <FormContainer>
          <FormColumn>
            <StyledTextAreaFormField
              label="Pup Description"
              value={bio}
              valid={showValidStates ? validateBio(bio) : true}
              onChangeValue={onChangeBio}
            />
            <PetProfileDetails
              details={details}
              onChangeDetails={onChangeDetails}
            />
          </FormColumn>
        </FormContainer>
      )}
      <NavButtonContainer>
        <Button size="large" onClick={onClickGoBack}>
          Go Back
        </Button>
        <Button variant="primary" size="large" onClick={continueToNextScreen}>
          Continue
        </Button>
      </NavButtonContainer>
    </>
  );
};
