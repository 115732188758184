import {
  ConversationRecord,
  createConversationIfNoneExists,
  fetchMessages,
  selectMessages,
  selectUserID,
} from '@fido/state';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import {
  CurrentConversationOwnProps,
  CurrentConversationProps,
} from './CurrentConversation';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectRouteComponentProps = (state: IState, props: RouteComponentProps) =>
  props;

const selectLoadInitialData = createSelector(
  selectRouteComponentProps,
  props => (dispatch: Dispatch) => async () => {
    const { id: conversationId } = props.match.params as { id: string };
    const conversationIdParts = conversationId && conversationId.split('-');
    if (
      conversationId &&
      conversationIdParts &&
      conversationIdParts.length > 1
    ) {
      const [userID, sellerProfileID] = conversationIdParts;
      await dispatch(
        createConversationIfNoneExists(
          ConversationRecord({
            userID,
            sellerProfileID,
          })
        )
      );
      await dispatch(fetchMessages(userID));
    }
  }
);

const selectOnUpdateMessages = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => async () => {
    if (userID) await dispatch(fetchMessages(userID));
  }
);

export const selectCurrentConversationProps = (
  dispatch: ThunkDispatch<IState, null, AnyAction>
) => (
  state: IState,
  props: RouteComponentProps & CurrentConversationOwnProps
): CurrentConversationProps => ({
  userID: selectUserID(state),
  messages: selectMessages(state),
  loadInitialData: dispatch(selectLoadInitialData(state, props)),
  onUpdateMessages: dispatch(selectOnUpdateMessages(state)), 
  ...props,
});
