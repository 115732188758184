import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Heading,
  Button,
  Description,
  Text,
  UploadInput,
  ImageUploadGrid,
  ImageUploadGridItem,
  desktop,
} from '@fido/web-components';
import { unit } from '@fido/styles';
import { Map } from 'immutable';
import times from 'lodash/times';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${unit * 3}px 0;

  ${desktop(css`
    flex-direction: row;
  `)}
`;

const NavButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > button {
    width: 167px;

    &:first-child {
      margin-right: ${unit * 2}px;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${unit}px 0;
`;

const GridContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  padding-top: ${unit * 3}px;

  & > div {
    width: 100%;
  }

  ${desktop(css`
    padding-top: 0;
    padding-left: ${unit * 4}px;
  `)}
`;

export interface ICreatePuppyProfileThirdStepProps {
  disableBtn: boolean;
  images: Map<string, File | null>;
  onSetImage(key: string, file: File | null): void;
  onClickGoBack(): void;
  onClickContinue(): void;
}

export const CreatePuppyProfileThirdStep = ({
  disableBtn,
  images,
  onSetImage,
  onClickGoBack,
  onClickContinue,
}: ICreatePuppyProfileThirdStepProps) => {
  return (
    <>
      <Heading>Step 3: Pictures</Heading>
      <InfoContainer>
        <Text weight="bold">Pictures</Text>
        <Description>Upload up to 9 pictures. Max 5mb per image.</Description>
      </InfoContainer>
      <FormContainer>
        <UploadInput
          value={images.get('img-primary')}
          onChangeValue={file => onSetImage('img-primary', file)}
        />
        <GridContainer>
          <ImageUploadGrid>
            {times(9, key => (
              <ImageUploadGridItem
                key={key}
                value={images.get(`img-grid-${key}`)}
                onChangeValue={value => onSetImage(`img-grid-${key}`, value)}
              />
            ))}
          </ImageUploadGrid>
        </GridContainer>
      </FormContainer>
      <NavButtonContainer>
        <Button size="large" onClick={onClickGoBack}>
          Go Back
        </Button>
        <Button variant="primary" disabled = {disableBtn} size="large" onClick={ onClickContinue }>
          Continue
        </Button>
      </NavButtonContainer>
    </>
  );
};
