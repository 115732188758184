import React from 'react';
import styled from 'styled-components';
import {
  Logo,
  LoginButton,
  TextButton,
  ELogoTextStyle,
} from '@fido/web-components';
import { unit } from '@fido/styles';

const Separator = styled.div`
  height: 10px;
`;

const LogoContainer = styled.div`
  margin-bottom: ${unit * 10}px;
`;

export interface ISelectLoginTypeFormProps {
  onSelectLoginWithFacebook(): void;
  onSelectLoginWithEmail(): void;
  onSelectCreateWithEmail(): void;
}

export const SelectLoginTypeForm = ({
  onSelectLoginWithFacebook,
  onSelectLoginWithEmail,
  onSelectCreateWithEmail,
}: ISelectLoginTypeFormProps) => (
  <>
    <LogoContainer>
      <Logo textStyle={ELogoTextStyle.dark} />
    </LogoContainer>
    {/* <LoginButton variant="facebook" onClick={onSelectLoginWithFacebook}>
      Sign In with Facebook
    </LoginButton>
    <Separator /> */}
    <LoginButton variant="email" onClick={onSelectLoginWithEmail}>
      Sign In with Email
    </LoginButton>
    <Separator />
    <TextButton weight="medium" onClick={onSelectCreateWithEmail}>
      Create account
    </TextButton>
  </>
);
