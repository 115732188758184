"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const userProfileStateTypes_1 = require("./userProfileStateTypes");
exports.userProfileStateReducers = {
    resetUserProfileState: (state) => userProfileStateTypes_1.UserProfileStateRecord(),
    setUserProfiles: (state, { payload: { userProfiles }, }) => state.set('userProfiles', userProfiles),
    setUserProfile: (state, { payload: { id, userProfile }, }) => state.setIn(['userProfiles', id], userProfile),
};
exports.userProfileStateReducer = reduxHelpers_1.makeReducer(userProfileStateTypes_1.UserProfileStateRecord(), exports.userProfileStateReducers);
