"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@fido/common");
const result_1 = require("../result");
exports.fetchSellerProfile = (sellerProfileID) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const snapshot = yield common_1.firestore
            .collection('seller-profiles')
            .doc(sellerProfileID)
            .get();
        const data = snapshot.data();
        return new result_1.Ok(common_1.SellerProfileRecord(data || {}));
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
