"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@fido/common");
exports.loginWithEmailAndPassword = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const user = yield common_1.auth.signInWithEmailAndPassword(email, password);
        return new common_1.Ok(user);
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
exports.createUserWithEmailAndPassword = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const user = yield common_1.auth.createUserWithEmailAndPassword(email, password);
        return new common_1.Ok(user);
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
exports.loginWithFacebook = (accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const credential = common_1.firebase.auth.FacebookAuthProvider.credential(accessToken);
        const user = yield common_1.auth.signInWithCredential(credential);
        return new common_1.Ok(user);
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
exports.loginWithApple = (credential, nonce) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const appleCredential = common_1.firebase.auth.AppleAuthProvider.credential(credential, nonce);
        const user = yield common_1.auth.signInWithCredential(appleCredential);
        return new common_1.Ok(user);
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
exports.logout = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield common_1.auth.signOut();
        return new common_1.Ok(null);
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
exports.sendPasswordResetEmail = (email) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield common_1.auth.sendPasswordResetEmail(email);
        return new common_1.Ok(null);
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
