"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@fido/common");
const state_1 = require("../state");
exports.fetchRole = (userID) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const snapshot = yield common_1.firestore
            .collection('userRoles')
            .doc(userID)
            .get();
        const data = snapshot.data();
        return new common_1.Ok(state_1.RoleRecord(data || {}));
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
exports.createRole = (role) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield common_1.firestore.collection('userRoles').add(role.toJS());
        return new common_1.Ok({
            id: data.id
        });
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
