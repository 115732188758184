import {
  EMessageReadStatus,
  fetchCurrentSellerProfile,
  fetchMessages,
  fetchUserProfile,
  selectAuthLoadingStatus,
  selectCurrentSellerProfile,
  selectIsLoggedIn,
  selectMessages,
  selectUserID,
  fetchApplications,
  selectApplications,
  EApplicationReadStatus,
  fetchApplicationsBySeller,
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { loadSavedCredential, logout } from '../../../actionCreators';
import { IState } from '../../../state';
import { IRoutesProps } from '../Routes';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch, getState: () => IState) => async () => {
    dispatch(loadSavedCredential());
  }
);

const selectLoadInitialLoggedInData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch, getState: () => IState) => async () => {
    if (!userID) {
      return;
    }
    dispatch(fetchUserProfile());
    dispatch(fetchCurrentSellerProfile());
    dispatch(fetchMessages(userID));
    dispatch(fetchApplicationsBySeller(userID));
  }
);

const selectShowMessagesBadge = createSelector(
  selectMessages,
  selectUserID,
  (messages, userID) => {
    const unreadMessages = messages
      .filter(msg => msg.toID === userID)
      .filter(msg => msg.readStatus === EMessageReadStatus.unread);
    const numberOfUnreadMessages = unreadMessages.size;
    return numberOfUnreadMessages > 0;
  }
);

const selectShowApplicationsBadge = createSelector(
  selectApplications,
  applications => {
    const unreadApplications = applications.filter(
      app => app.sellerReadStatus === EApplicationReadStatus.unread
    );
    const numberOfUnreadApplications = unreadApplications.size;
    return numberOfUnreadApplications > 0;
  }
);

export const selectRouteProps = (dispatch: Dispatch) => (
  state: IState
): IRoutesProps => ({
  userId: selectUserID(state),
  loadInitialState: dispatch(selectLoadInitialData(state)),
  loadInitialLoggedInData: dispatch(selectLoadInitialLoggedInData(state)),
  isLoggedIn: selectIsLoggedIn(state),
  authLoadingStatus: selectAuthLoadingStatus(state),
  logout: () => dispatch(logout()),
  sellerProfile: selectCurrentSellerProfile(state),
  showMessagesBadge: selectShowMessagesBadge(state),
  showApplicationsBadge: selectShowApplicationsBadge(state),
});
