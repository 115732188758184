import { IUserProfileRecord, firebase } from '@fido/common';
import {
  CreateMessageRecord,
  IConversationRecord,
  ICreateMessageRecord,
  IMessageRecord,
} from '@fido/state';
import { conversationUpdateListener } from '@fido/state/lib/api';
import { unit } from '@fido/styles';
import {
  Avatar,
  Chat,
  ChatBubble,
  ChatConversationArea,
  ChatInput,
  EChatBubbleVariant,
  Heading,
  isDesktop,
} from '@fido/web-components';
import { Map } from 'immutable';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import './chat.css';
import logo from './icon.png';
import security from './security.png';
import { uploadImage } from '@fido/state/lib/api';

var Loader = require('react-loader');

const ChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${unit}px;
  padding-left: ${unit * 3}px;
  padding-right: ${unit * 3}px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 57px;
  background-color: #fff;
`;

const SecurityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  padding-left: 100px;
  padding-right: 100px;
  justify-content: center;
  background: #f2f2f2;
  @media (max-width: 479px) {
    margin-top: ${unit * 2}px;
    margin-left: ${unit * 0}px;
    margin-right: ${unit * 0}px;
    padding-bottom: 0px;
  }
`;
const SecurityText = styled.div`
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Red Hat Text', sans-serif;
  -webkit-line-clamp: 2;
  line-height: 1.4;
  color: #717171;
  @media (max-width: 479px) {
    position: fixed;
    padding-left: 65px;
    padding-right: ${unit * 3}px;
    font-size: 12px;
    z-index: 999;
    top: 57px;
    background: #f2f2f2;
  }
`;

const IconContainer = styled.div``;

var isMobile = window.matchMedia("only screen and (max-width: 479px)");

const HeaderBackIcon = () => (
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-46.000000, -49.000000)"
        fill="#000000"
        fillRule="nonzero">
        <g transform="translate(48.000000, 51.000000)">
          <path d="M13.9192388,8.71923882 C14.4269204,8.21155724 14.4269204,7.38844276 13.9192388,6.88076118 C13.4115572,6.37307961 12.5884428,6.37307961 12.0807612,6.88076118 L6.88076118,12.0807612 C6.37307961,12.5884428 6.37307961,13.4115572 6.88076118,13.9192388 L12.0807612,19.1192388 C12.5884428,19.6269204 13.4115572,19.6269204 13.9192388,19.1192388 C14.4269204,18.6115572 14.4269204,17.7884428 13.9192388,17.2807612 L9.63847763,13 L13.9192388,8.71923882 Z" />
        </g>
      </g>
    </g>
  </svg>
);

const CenterSpacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const useConversationUpdateListener = (
  conversationID: string,
  onNewMessages: (messages: Map<string, IMessageRecord>) => void
) => {
  return useEffect(() => {
    if (!conversationID) {
      return;
    }
    return conversationUpdateListener(conversationID, onNewMessages);
  }, []);
};

const analytics = firebase.analytics();

const sellerMessageEvent = (
  currentConversationID: string,
  inputValue: string,
  fromId: string,
  toId: string
) => {
  analytics.logEvent('seller_message', {
    conversationID: currentConversationID,
    message: inputValue,
    sellerId: fromId,
    buyerId: toId,
  });
};

export interface CurrentConversationReduxProps {
  userID?: string;
  messages: Map<string, IMessageRecord>;
  onUpdateMessages(): Promise<void>;
  loadInitialData(): Promise<void>;
}

export interface CurrentConversationOwnProps {
  otherUserProfile?: IUserProfileRecord;
  conversations: Map<string, IConversationRecord>;
  onSendMessage(message: ICreateMessageRecord): void;
  onSelectConversation(conversationID: string): void;
  markMessagesAsRead(conversationID: string): void;
}

export type CurrentConversationProps = CurrentConversationOwnProps &
  CurrentConversationReduxProps;

export const CurrentConversation = ({
  otherUserProfile,
  userID,
  conversations,
  messages,
  onSendMessage,
  onSelectConversation,
  markMessagesAsRead,
  onUpdateMessages,
}: CurrentConversationProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { id: currentConversationID } = useParams<{ id: string }>();
  useEffect(() => {
    onSelectConversation(currentConversationID);
    markMessagesAsRead(currentConversationID);
  }, [messages, currentConversationID]);
  
  for (const [key] of conversations.entries()) {
    useConversationUpdateListener(key, async () => {
      await onUpdateMessages();
    });
  }

  const conversation = conversations.get(currentConversationID);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  const onChooseImageToUpload = async event => {
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      (async () => {
        setLoading(false);
        const result = await uploadImage(userID, file);
        if (result.ok()) {
          const fileUrl = result.ok();
          file.type === 'application/pdf'
            ? creteImageUploadRecord('file', file.name, fileUrl)
            : creteImageUploadRecord('image', 'Image', fileUrl);
          setLoading(true);
        } else {
          setLoading(true);
          alert('Failed to upload image, try again.');
          return;
        }
      })();
    }
  };

  const creteImageUploadRecord = (
    fileType: string,
    message: string,
    path: string
  ) => {
    onSendMessage(
      CreateMessageRecord({
        conversationID: currentConversationID,
        text: message,
        fromID: userID,
        toID: conversation?.userID,
        type: fileType,
        path: path,
      })
    );
  };

  const downloadFile = (filePath: string, fileName: string) => {
    if (!window.ActiveXObject) {
      var save = document.createElement('a');
      save.href = filePath;
      save.target = '_blank';
      save.download = fileName || 'unknown';

      var evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);

      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(filePath, '_blank');
      if (_window) {
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || filePath);
        _window.close();
      }
    }
  };

  const options = {
    lines: 13,
    length: 20,
    width: 5,
    radius: 30,
    scale: 0.5,
    corners: 1,
    color: '#000',
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: isMobile.matches ? '-450%' : '50%',
    left: isMobile.matches ? '50%': '70%',
    shadow: false,
    hwaccel: false,
    position: 'absolute',
  };

  return (
    <Chat>
      {!isDesktop() ? (
        <ChatHeader>
          <IconContainer onClick={() => history.goBack()}>
            <HeaderBackIcon />
          </IconContainer>
          <CenterSpacer>
            <Heading>{otherUserProfile?.name}</Heading>
          </CenterSpacer>
          <Avatar source={otherUserProfile?.imageURL} />
        </ChatHeader>
      ) : null}
      <SecurityContainer>
        <img
          class={'Security'}
          style={{ width: '35px', aspectRatio: 'auto' }}
          src={security}
          alt="security"
        />
        <SecurityText>
          To protect your privacy and payment, always communicate and pay
          through the Barkd website or app.
        </SecurityText>
      </SecurityContainer>
      <ChatConversationArea>
        {messages
          .filter(
            m => m.conversationID === currentConversationID && m.text !== ''
          )
          .mapEntries(([key, message]) => [
            key,
            {
              key,
              createdAt: message.createdAt,
              timestamp: message.createdAt,
              variant: (message.fromID === userID
                ? 'outgoing'
                : 'incoming') as EChatBubbleVariant,
              children: message.text,
              message: message.text,
              type: message.type,
              path: message.path,
            },
          ])
          .valueSeq()
          .sortBy(props => moment(props.createdAt).toDate())
          .map(props => (
            <ChatBubble
              {...props}
              onClick={() => {
                downloadFile(props.path, props.message);
              }}
            />
          ))
          .toArray()}
      </ChatConversationArea>
      <div className="container fixed-chat">
          <Loader loaded={isLoading} options={options} className={"spinner"} /> 
        <img
          className="openGallery"
          src={logo}
          alt="icon"
          onClick={() => inputRef.current.click()}
        />
        <input
          ref={inputRef}
          accept="image/*, .pdf"
          className="inputGallery"
          type="file"
          multiple
          onChange={onChooseImageToUpload}
        />
        <ChatInput
          placeholder="Start Typing..."
          inputMode="text"
          value={inputValue}
          onChangeValue={m => setInputValue(m)}
          onSend={() => {
            if (inputValue === '') {
              return;
            }
            onSendMessage(
              CreateMessageRecord({
                conversationID: currentConversationID,
                text: inputValue,
                fromID: userID,
                toID: conversation?.userID,
                type: 'text',
              })
            );
            sellerMessageEvent(
              currentConversationID,
              inputValue,
              userID,
              conversation?.userID
            );
            setInputValue('');
          }}
        />
      </div>
    </Chat>
  );
};




