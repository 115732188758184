"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const sellerPaymentStateType_1 = require("./sellerPaymentStateType");
exports.sellerPaymentStateReducers = {
    resetSellerPaymentState: (state) => sellerPaymentStateType_1.SellerPaymentStateRecord(),
    setSellerPayments: (state, { payload: { sellerPayments }, }) => state.set('sellerPayments', sellerPayments),
    setSellerPayment: (state, { payload: { id, sellerPayment }, }) => state.setIn(['sellerPayments', id], sellerPayment),
    mergeSellerPayments: (state, { payload: { sellerPayments }, }) => state.update('sellerPayments', p => p.merge(sellerPayments)),
};
exports.sellerPaymentStateReducer = reduxHelpers_1.makeReducer(sellerPaymentStateType_1.SellerPaymentStateRecord(), exports.sellerPaymentStateReducers);
