"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const sellerProfileStateTypes_1 = require("./sellerProfileStateTypes");
exports.sellerProfileStateReducers = {
    resetSellerProfileState: (state) => sellerProfileStateTypes_1.SellerProfileStateRecord(),
    setSellerProfiles: (state, { payload: { profiles }, }) => state.set('profiles', profiles),
    setSellerProfile: (state, { payload: { id, profile }, }) => state.setIn(['profiles', id], profile),
    setSellerBankAccounts: (state, { payload: { bankAccounts }, }) => state.set('bankAccounts', bankAccounts),
    setSellerBankAccount: (state, { payload: { id, bankAccount }, }) => state.setIn(['bankAccounts', id], bankAccount),
    mergeSellerProfiles: (state, { payload: { profiles }, }) => state.update('profiles', p => p.merge(profiles)),
};
exports.sellerProfileStateReducer = reduxHelpers_1.makeReducer(sellerProfileStateTypes_1.SellerProfileStateRecord(), exports.sellerProfileStateReducers);
