"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const applicationStateType_1 = require("./applicationStateType");
exports.applicationStateReducers = {
    setApplications: (state, { payload: { applications }, }) => state.set('applications', applications),
    setApplication: (state, { payload: { id, application }, }) => state.setIn(['applications', id], application),
    mergeApplications: (state, { payload: { applications }, }) => state.update('applications', a => a.merge(applications)),
};
exports.applicationStateReducer = reduxHelpers_1.makeReducer(applicationStateType_1.ApplicationStateRecord(), exports.applicationStateReducers);
