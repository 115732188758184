import { ICreateUnbornPetProfileProps } from '../CreateApplicantProfile';
import {
  fetchApplicationsByPetProfile,
  selectPetProfiles,
  selectUserID,
  fetchPetProfilesBySeller,
  createApplication,
  ApplicationCreateRecord,
  EApplicationStatus,
  selectCurrentSellerProfile
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => {
    if (userID) {
      dispatch(fetchPetProfilesBySeller(userID));
    }
  }
);

const selectLoadApplicationCounts = createSelector(
  selectPetProfiles,
  petProfiles => (dispatch: Dispatch) =>
    petProfiles.forEach((petProfile, petProfileID) => {
      dispatch(fetchApplicationsByPetProfile(petProfileID));
    })
);

const selectCreateApplication = createSelector(
  selectUserID,
  sellerProfileID => (dispatch: Dispatch) => (
    petProfileID: string,
    userID: string,
    deposit: string,
    status: EApplicationStatus,
    buyerName: string,
    buyerEmail: string,
    puppyName: string,
    createdByLink: boolean,
  ) =>
  sellerProfileID &&
    dispatch(
      createApplication(
        ApplicationCreateRecord({
          sellerProfileID,
          userID,
          petProfileID,
          deposit,
          status,
          buyerName,
          buyerEmail,
          puppyName,
          createdByLink
        })
      )
    )
);

export const selectCreatePetProfileProps = (dispatch: Dispatch) => (
  state: IState
): ICreateUnbornPetProfileProps => ({
  userID: selectUserID(state),
  petProfiles: selectPetProfiles(state),
  sellerProfile: selectCurrentSellerProfile(state),
  loadInitialData: () => dispatch(selectLoadInitialData(state)),
  loadApplicationCounts: () => dispatch(selectLoadApplicationCounts(state)),
  createApplication: dispatch(selectCreateApplication(state))
});
