import React from 'react';
import styled from 'styled-components';
import { Text, ITextProps, Avatar } from '@fido/web-components';
import {
  unit,
  trueBlack,
  transparent,
  trueWhite,
  gray,
  turq,
} from '@fido/styles';
import moment from 'moment';

const Container = styled.li<{ selected: boolean }>`
  position: relative;
  padding: ${unit * 1.5}px ${unit * 1}px;
  background-color: ${props => (props.selected ? trueBlack : transparent)};
  cursor: pointer;
  display: flex;
  flex-direction: row;

  &:hover,
  &:active {
    background-color: ${trueBlack};

    & > span {
      color: ${trueWhite};
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 55px;
    right: 5px;
    height: 1px;
    background-color: #333;
    top: 0;
  }

  &:last-of-type:after {
    content: '';
    position: absolute;
    left: 55px;
    right: 5px;
    height: 1px;
    bottom: 0;
    background-color: #333;
  }
`;

const ListItemText = styled(Text)<{ selected: boolean, showUnreadIndicator: boolean }>`
  color: ${props => (props.selected ? trueWhite : gray)};
  margin-left: 14px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .name {
    font-weight: bold;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: break-all;
    line-clamp: 1;
    max-height: 20px;
    white-space: nowrap;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: break-all;
    line-clamp: 1;
    max-height: 20px;
    max-width: 100%;
    white-space: nowrap;
    font-weight: ${props => (props.showUnreadIndicator ? 'bold' : '400')}
  }

  .date {
    position: absolute;
    top: ${unit * 1.5}px;
    right: ${unit * 4}px;
    font-size: 12px;
  }
`;

const Indicator = styled.div`
  background-color: ${turq};
  height: 20px;
  width: 20px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export interface IListItemProps {
  selected?: boolean;
  showUnreadIndicator?: boolean;
  avatarSource?: string;
  name?: string;
  description?: string;
  date?: string;
  numberOfMessages?: number;
  onClick?(): void;
}

export const MobileListItem = ({
  selected = false,
  avatarSource,
  onClick,
  showUnreadIndicator = false,
  name = '',
  description = '',
  numberOfMessages,
  date,
}: IListItemProps) => {
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const dt = moment(date).isSame(today, 'day')
    ? moment(date).format('h:mm A')
    : moment(date).isSame(yesterday, 'day')
    ? 'Yesterday'
    : moment(date).format('L');
  return (
    <Container selected={selected} onClick={onClick}>
      <div style={{ position: 'relative' }}>
        <Avatar source={avatarSource} />
        {showUnreadIndicator && (
          <Indicator>
            <div
              style={{
                textAlign: 'center',
                color: '#000',
                fontSize: 11,
                marginTop: 3,
              }}>
              {String(numberOfMessages)}
            </div>
          </Indicator>
        )}
      </div>
      <ListItemText weight="medium" selected={selected} showUnreadIndicator= {showUnreadIndicator}>
        <div className="name">{name}</div>
        <div className="description">{description}</div>
        {date && <div className="date">{dt}</div>}
      </ListItemText>
    </Container>
  );
};
