"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("../api");
const state_1 = require("../state");
exports.createMessage = (create, from) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.createMessage(create, from);
    result.map(({ id, message }) => {
        dispatch(state_1.setMessage({
            id,
            message,
        }));
    });
});
exports.fetchMessages = (userID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchMessages(userID);
    result.map(messages => {
        dispatch(state_1.setMessages({
            messages,
        }));
    });
});
exports.createConversationIfNoneExists = (create) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.createConversationIfNoneExists(create);
    result.map(({ id, conversation }) => {
        dispatch(state_1.setConversation({
            id,
            conversation,
        }));
    });
});
exports.fetchConversations = (userID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchConversations(userID);
    result.map(conversations => {
        dispatch(state_1.setConversations({
            conversations,
        }));
    });
});
exports.fetchSellerConversations = (userID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchSellerConversations(userID);
    result.map(conversations => {
        dispatch(state_1.setConversations({
            conversations,
        }));
    });
});
exports.updateMessageReadStatus = (messageID, readStatus) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.updateMessageReadStatus(messageID, readStatus);
    result.map(({ message }) => {
        dispatch(state_1.setMessage({
            id: messageID,
            message,
        }));
    });
});
