import {
  selectUserID,
  fetchUserProfile,
  fetchCurrentSellerProfile,
  selectUserProfile,
  selectSellerProfiles,
  selectCurrentSellerProfile,
  updateSellerProfile,
  fetchSellerProfiles,
  fetchCurrentSellerBankAccount,
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { ISellerProfileRecord } from '@fido/common';
import { IAdminProps } from '../BreederManagment';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => {
    dispatch(fetchUserProfile());
    dispatch(fetchCurrentSellerProfile());
    dispatch(fetchCurrentSellerBankAccount());
    dispatch(fetchSellerProfiles());
  }
);

const selectUpdateSellerProfile = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => (sellerProfile: ISellerProfileRecord) => {
    if (!userID) {
      return;
    }
    dispatch(updateSellerProfile(userID, sellerProfile));
  }
);

export const selectAdminProps = (dispatch: Dispatch) => (
  state: IState
): IAdminProps => ({
  userID: selectUserID(state),
  userProfile: selectUserProfile(state),
  sellerProfile: selectCurrentSellerProfile(state),
  sellerProfiles: selectSellerProfiles(state),
  loadInitialData: () => dispatch(selectLoadInitialData(state)),
  updateSellerProfile: dispatch(selectUpdateSellerProfile(state)),
});
