import {
  EPetSex,
  EPetSize,
  IPetProfileRecord,
  EPetProfileVariant,
  ISellerProfileRecord
} from '@fido/common';
import { uploadImage } from '@fido/state/lib/api';
import { List } from 'immutable';
import compact from 'lodash/compact';
import React, { useReducer, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { PageContainer } from '../../../common';
import {
  createPuppyProfileReducer,
  CreatePuppyProfileStateRecord,
  setBio,
  setBreed,
  setDetails,
  setDOB,
  setHypoallergenic,
  setImages,
  setName,
  setPrice,
  setReadyDate,
  setSize,
} from '../state';
import { CreateLitterProfileFirstStep } from './CreateLitterProfileFirstStep';
import { CreateLitterProfileSecondStep } from './CreateLitterProfileSecondStep';
import { CreateLitterProfileThirdStep } from './CreateLitterProfileThirdStep';

export interface ICreateLitterProfileProps {
  userID: string;
  sellerProfile?: ISellerProfileRecord;
  onClickSave(partialProfile: Partial<IPetProfileRecord>): void;
}

export const CreateLitterProfile = ({
  userID,
  sellerProfile,
  onClickSave,
}: ICreateLitterProfileProps) => {
  const [state, dispatch] = useReducer(
    createPuppyProfileReducer,
    CreatePuppyProfileStateRecord()
  );
  const { path } = useRouteMatch();
  const history = useHistory();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const onSave = async () => {
    setButtonDisable(true)
    const results = await Promise.all(
      compact(
        state.images
          .valueSeq()
          .map(file => file && uploadImage(userID, file))
          .toArray()
      )
    );
    const images = List(compact(results.map(result => result.ok())));
    onClickSave({
      breed: state.breed,
      name: state.name,
      price: parseFloat(state.price),
      size: state.size || EPetSize.medium,
      sex: state.sex || EPetSex.female,
      dob: state.dob,
      description: state.bio,
      images,
      readyDate: state.readyDate,
      details: state.details,
      hypoallergenic: state.hypoallergenic,
      variant: EPetProfileVariant.litter,
    });
  };
  return (
    <PageContainer>
      <Switch>
        <Route path={`${path}/step/1`}>
          <CreateLitterProfileFirstStep
            name={state.name}
            breed={state.breed}
            size={state.size}
            price={state.price}
            dob={state.dob}
            readyDate={state.readyDate}
            hypoallergenic={state.hypoallergenic}
            onChangeName={name => dispatch(setName(name))}
            onChangeBreed={breed => dispatch(setBreed(breed))}
            onChangeSize={size => dispatch(setSize(size))}
            onChangePrice={price => dispatch(setPrice(price))}
            onChangeDOB={dob => dispatch(setDOB(dob))}
            onChangeReadyDate={readyDate => dispatch(setReadyDate(readyDate))}
            onChangeHypoallergenic={hypoallergenic =>
              dispatch(setHypoallergenic(hypoallergenic))
            }
            onClickContinue={() => history.push(`${path}/step/2`)}
          />
        </Route>
        <Route path={`${path}/step/2`}>
          <CreateLitterProfileSecondStep
            bio={state.bio}
            details={state.details}
            onChangeDetails={details => dispatch(setDetails(details))}
            onChangeBio={bio => dispatch(setBio(bio))}
            onClickGoBack={() => history.push(`${path}/step/1`)}
            onClickContinue={() => history.push(`${path}/step/3`)}
          />
        </Route>
        <Route path={`${path}/step/3`}>
          <CreateLitterProfileThirdStep
            images={state.images}
            disableBtn={isButtonDisable}
            onSetImage={(key, file) =>
              dispatch(setImages(state.images.set(key, file)))
            }
            onClickGoBack={() => history.push(`${path}/step/2`)}
            onClickContinue={() => {
              if(sellerProfile && sellerProfile.paymentSettings.stripePaymentAccountID === ''){
                alert("Complete your account setup, before you create an application.") 
                 return;
                }
              onSave()
            }}
          />
        </Route>
        <Route
          render={props => (
            <Redirect
              to={{
                pathname: `${path}/step/1`,
                state: { from: props.location },
              }}
            />
          )}
        />
      </Switch>
    </PageContainer>
  );
};
