import React, { useState, useEffect } from 'react';
import './Payments.css';
import { firestore } from '@fido/common';
import styled from 'styled-components';
import { unit } from '@fido/styles';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { baseUrl, domainName } from '../../config';

const PAYMENT_URL = `${baseUrl}/onPaymentDone`;
const PageContents = styled.div`
  display: flex;
  flex: 1;
  padding: ${unit}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto;
`;

export default function Payments() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [deposit, setDeposit] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [puppyName, setPuppyName] = useState('');
  const [sellerAccountId, setSellerAccountId] = useState('')
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    var url = new URLSearchParams(window.location.search);
    var applicationId = url.get('applicationId');
    if (!applicationId) {
      return
    }
    localStorage.setItem('ApplicationId', applicationId);
    this.fetchApplication(applicationId);
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }
    if (query.get('canceled')) {
      setMessage(
        "Order canceled -- continue to shop when you're ready."
      );
    }
  }, []);

  // fetch application details with application Id 
  fetchApplication = async id => {
    const snapshot = await firestore
      .collection('applications')
      .doc(id)
      .get();
    const application = snapshot.data();
    if (!application) {
      return;
    }
    if (!application.deposit) {
      const snapshot = await firestore
        .collection('seller-profiles')
        .doc(application.sellerProfileID)
        .get();
      const data = snapshot.data();
      if (!data) {
        return;
      }
      setDeposit(data.depositAmount);
      setEmail(application.buyerEmail);
    } else {
      setDeposit(application.deposit);
      setEmail(application.buyerEmail);
    }
    setUserName(application.buyerName);
    setPuppyName(application.puppyName);
    fetchSellerProfile(application);
  };

  // Fetch seller profile to get the seller stripe account Id
  const fetchSellerProfile = async application => {
    const snapshot = await firestore
      .collection('seller-profiles')
      .doc(application.sellerProfileID)
      .get();
    const data = snapshot.data();
    if (!data) {
      return;
    }
    const stripeAccountId = data.paymentSettings.stripePaymentAccountID;
    setSellerAccountId(stripeAccountId)
  };

  // Calculate application fee
  calculateServiceFee = (depositAmount) => {
    return Math.min(depositAmount * 0.1, 100);
  }

  calculateTotalAmount = (depositAmount, ServiceFee) => {
    return Number(depositAmount) + Number(ServiceFee)
  }

  // On press checkout btn and returns token for card and pass token to Charges api
  onClickCheckout = async event => {

    event.preventDefault();
    if (!stripe || !elements || deposit === '' || sellerAccountId === '') {
      alert('Somthing went wrong, please try again.')
      return;
    }
    setLoading(true)
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setLoading(false)
      alert('Somthing went wrong, please try again.')
    } else {
      const totalAmount = calculateTotalAmount(deposit, calculateServiceFee(deposit))
      const params = {
        amount: totalAmount,
        token: result.token.id,
        petName: puppyName,
        applicationFee: calculateServiceFee(deposit),
        sellerPaymentAccountID: sellerAccountId
      };
      this.paymentProcess(params);
    }
  };

  // Charges api to do payment for vendor and application fee for platform owner 
  paymentProcess = async params => {
    try {
      await fetch(PAYMENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
        .then( async (res) => {
          // const result = await res.json()
          // const transactionId = result.body.Success.id;
          // localStorage.setItem('TransactionId', transactionId);
          setLoading(false)
          if (res.status === 200) {
            history.push(`/paymentSuccess/0`);
          } else {
            history.push('/paymentFailure');
          }
        })
        .catch((error) => {
          setLoading(false)
          history.push('/paymentFailure');
        });
    } catch (error) {
      setLoading(false)
      alert('Something went wrong, please try again.')
    }
  };

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          padding,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

  return message ? (
    <Message message={message} />
  ) : (
      <PageContents>
        <section>
          <div style={{ paddingTop: '20px' }}>
            <div>
              <div className="split left">
                <div className="centered">
                  <div className="PuppyName">{puppyName}</div>
                  <div className="ProductSummary-totalAmount">Deposit: $ {deposit}</div>
                  <div className="ProductSummary-totalAmount">Service Fee: $ {calculateServiceFee(deposit)}</div>
                  <div className="Total">Total: $ {calculateTotalAmount(deposit, calculateServiceFee(deposit))}</div>
                  <div className="ProductSummary-productImageContainer">
                    <div className="ProductImage-container">
                      <img
                        src={`${domainName}/android-icon-192x192.png`}
                        style={{ width: '300px', height: '300px', borderRadius: 15, marginTop: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="split right">
                <form onSubmit={onClickCheckout}>
                  <fieldset className="FormGroup">
                    <div className="FormRow">
                      <div className="centered">
                        <div className="head">
                          <h2>Pay with card</h2>
                        </div>
                        <label className="text">Email</label>
                        <input value={email} className="text-box" type="text" />
                        <label className="text">Phone number</label>
                        <input className="text-box" type="text" />
                        <label className="text">Card information</label>
                        <CardNumberElement {...createOptions(17)} className="text-box-1" />
                        <CardExpiryElement className="number1" />
                        <CardCvcElement className="number2" />
                        <button disabled={isLoading} className="submit">{isLoading ? 'Submitting...' : 'Submit'} </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </section>
      </PageContents>
    );
}
