import {
  IPetProfileRecord,
  IUserProfileRecord,
  calculatePercentMatchValue,
} from '@fido/common';
import { IApplicationRecord, IMessageRecord } from '@fido/state';
import { unit } from '@fido/styles';
import {
  Description,
  EditButton,
  Heading,
  ImageGrid,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRows,
  Text,
  ImageGridItem,
  Stat,
  Button,
  Confirmation,
  isDesktop,
} from '@fido/web-components';
import { Map } from 'immutable';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { PageContainer } from '../../../common';
import { formatApplicationStatus } from '../../../../utils';

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${unit * 3}px;
  position: relative;
  flex-grow: 1;
  @media (max-width: 768px) {
    padding-left: 5px;
    width:30%
  }
`;

const StyledText = styled(Text)`
  padding-top: ${unit * 2}px;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${unit * 4}px;
`;

const ApplicantsHeading = styled(Heading)`
  margin-bottom: ${unit * 3}px;
`;

const StyledEditButton = styled(EditButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const SoldButton = styled(Button)`
  width: 100%;
`;

const DeleteButton = styled(Button)`
  width: 100%;
`;

const DeleteAndSoldButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    padding-left: 5px;
    margin-top: 10px;
  }
  ${DeleteButton} {
    width: auto;
  }
  ${SoldButton} {
    flex-grow: 1;
    margin-left: ${unit}px;
  }
`;

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  column-gap: ${unit * 2}px;
  row-gap: ${unit * 2}px;
  flex: 1;
  align-items: flex-start;
`;

const StatsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${unit * 4}px;
`;
const MobileTableRow = styled(TableRow)`
  grid-template-columns: repeat(1, 1fr);
  border: #dfe3e8;
  outline: 0;
  margin-top: 1.5rem;
  width: 95%;
  margin-right: 1rem;
  margin-left: 10px;
  padding: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 0.3rem rgb(0 0 0 / 30%);
  background: #fff;
`;
const Line = styled.div`
  border-top: 1px solid #dfe3e8;
`;

export interface IViewPetProfileProps {
  profile: IPetProfileRecord;
  petProfileID: string;
  numberOfFavorites: number;
  numberOfApplicants: number;
  numberOfViews: number;
  numberOfMessages: number;
  messages: Map<string, IMessageRecord>;
  userProfiles: Map<string, IUserProfileRecord>;
  applications: Map<string, IApplicationRecord>;
  onPressDeleteProfile(): void;
  onPressMarkProfileAsSold(): void;
}

const makePluralForm = (
  value: number,
  singular: string,
  plural: string
): string => (value === 1 ? singular : plural);

export const ViewPetProfile = ({
  profile,
  messages,
  petProfileID,
  numberOfFavorites,
  numberOfApplicants,
  numberOfViews,
  numberOfMessages,
  userProfiles,
  applications,
  onPressDeleteProfile,
  onPressMarkProfileAsSold,
}: IViewPetProfileProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  if (isDesktop()) {
    return (
      <PageContainer>
        <Header>
          <ImageGrid>
            {profile.images
              .map(image => <ImageGridItem src={image} key={image} />)
              .toArray()}
          </ImageGrid>
          <HeaderTextContainer>
            <Heading>{profile.name}</Heading>
            <Description>{profile.breed}</Description>
            <StyledText>{profile.description}</StyledText>
            <StyledEditButton onClick={() => history.push(`${pathname}/edit`)} />
          </HeaderTextContainer>
          <StatsColumn>
            <StatContainer>
              <Stat
                size="small"
                label={makePluralForm(
                  numberOfApplicants,
                  'Applicant',
                  'Applicants'
                )}
                value={numberOfApplicants}
              />
              <Stat
                size="small"
                label={makePluralForm(numberOfViews,
                  'View',
                  'Views'
                )}
                value={numberOfViews}
              />
              <Stat
                size="small"
                label={makePluralForm(numberOfMessages, 'Message', 'Messages')}
                value={numberOfMessages}
              />
              <Stat
                size="small"
                label={makePluralForm(
                  numberOfFavorites,
                  'Favorite',
                  'Favorites'
                )}
                value={numberOfFavorites}
              />
            </StatContainer>
            <DeleteAndSoldButtonContainer>
              <Confirmation
                title="Really delete this pet profile?"
                body="This pet will no longer be listed on the Barkd mobile app."
                yesText="Yes"
                noText="No"
                yesClick={onPressDeleteProfile}>
                <DeleteButton size="large" variant="default">
                  🗑
                  </DeleteButton>
              </Confirmation>
              <Confirmation
                title="Really mark as sold?"
                body="This pet will be listed as sold on the Barkd mobile app."
                yesText="Yes"
                noText="No"
                yesClick={onPressMarkProfileAsSold}>
                <SoldButton size="large" variant="default">
                  Sold
                  </SoldButton>
              </Confirmation>
            </DeleteAndSoldButtonContainer>
          </StatsColumn>

        </Header>
        <TableContainer>
          <ApplicantsHeading>Applicants</ApplicantsHeading>
          <Table>
            <TableHeader>
              <TableHeaderColumn sortable>Name</TableHeaderColumn>
              <TableHeaderColumn>Match</TableHeaderColumn>
              <TableHeaderColumn>Date Applied</TableHeaderColumn>
              <TableHeaderColumn>Status</TableHeaderColumn>
              <TableHeaderColumn>Messages</TableHeaderColumn>
            </TableHeader>
            <TableRows>
              {applications
                .filter(app => app.petProfileID === petProfileID)
                .map((app, applicationID) => {
                  const applicantUserProfile = userProfiles.get(app.userID);
                  return (
                    <TableRow
                      key={applicationID}
                      onClick={() =>
                        history.push(`/app/applications/${applicationID}`)
                      }>
                      <TableCell highlighted>
                        {applicantUserProfile ?.name}
                      </TableCell>
                      <TableCell>
                        {applicantUserProfile
                          ? calculatePercentMatchValue(
                            applicantUserProfile,
                            profile
                          )
                          : '--'}
                      </TableCell>
                      <TableCell>
                        {moment(app.createdAt).format('MMM. DD, YYYY')}
                      </TableCell>
                      <TableCell>{formatApplicationStatus(app.status)}</TableCell>
                      <TableCell>
                        {messages.filter(msg => msg.fromID === app.userID).size ||
                          '--'}
                      </TableCell>
                    </TableRow>
                  );
                })
                .valueSeq()
                .toArray()}
            </TableRows>
          </Table>
        </TableContainer>
      </PageContainer>
    );
  }
  else {
    return (
      <PageContainer>
        <Header>
          <ImageGrid>
            {profile.images
              .map(image => <ImageGridItem src={image} key={image} />)
              .toArray()}
          </ImageGrid>
          <HeaderTextContainer>
            <Heading>{profile.name}</Heading>
            <Description>{profile.breed}</Description>
            <StyledText>{profile.description}</StyledText>
            <StyledEditButton onClick={() => history.push(`${pathname}/edit`)} />
            <DeleteAndSoldButtonContainer>
              <Confirmation
                title="Really delete this pet profile?"
                body="This pet will no longer be listed on the Barkd mobile app."
                yesText="Yes"
                noText="No"
                yesClick={onPressDeleteProfile}>
                <DeleteButton size="medium" variant="default">
                  🗑
                </DeleteButton>
              </Confirmation>
              <Confirmation
                title="Really mark as sold?"
                body="This pet will be listed as sold on the Barkd mobile app."
                yesText="Yes"
                noText="No"
                yesClick={onPressMarkProfileAsSold}>
                <SoldButton size="medium" variant="default" >
                  Sold
                </SoldButton>
              </Confirmation>
            </DeleteAndSoldButtonContainer>
          </HeaderTextContainer>
        </Header>
        <TableContainer>
          <ApplicantsHeading>Applicants</ApplicantsHeading>
          <Table>
            <TableRows>
              {applications
                .filter(app => app.petProfileID === petProfileID)
                .map((app, applicationID) => {
                  const applicantUserProfile = userProfiles.get(app.userID);
                  return (
                    < MobileTableRow
                      key={applicationID}
                      onClick={() =>
                        history.push(`/app/applications/${applicationID}`)
                      }>
                      <TableCell highlighted>
                        <TableHeaderColumn sortable>Name</TableHeaderColumn>
                        {applicantUserProfile?.name}
                      </TableCell><Line />
                      <TableCell>
                        <TableHeaderColumn>Match</TableHeaderColumn>
                        {applicantUserProfile
                          ? calculatePercentMatchValue(
                            applicantUserProfile,
                            profile
                          )
                          : '--'}
                      </TableCell><Line />
                      <TableCell>
                        <TableHeaderColumn>Date Applied</TableHeaderColumn>
                        {moment(app.createdAt).format('MMM. DD, YYYY')}
                      </TableCell><Line />
                      <TableCell> <TableHeaderColumn>Status</TableHeaderColumn>{formatApplicationStatus(app.status)}</TableCell><Line />
                      <TableCell>
                        <TableHeaderColumn>Messages</TableHeaderColumn>
                        {messages.filter(msg => msg.fromID === app.userID).size ||
                          '--'}
                      </TableCell><Line />
                    </ MobileTableRow>
                  );
                })
                .valueSeq()
                .toArray()}
            </TableRows>
          </Table>
        </TableContainer>
      </PageContainer>
    )
  }
};
