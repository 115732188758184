import React, { useEffect, useState } from 'react';
import {
  desktop,
  Heading,
  isDesktop,
  List,
} from '@fido/web-components';
import { PageContainer } from '../common';
import { Map } from 'immutable';
import {
  IConversationRecord,
  IMessageRecord,
  ICreateMessageRecord,
  EMessageReadStatus,
} from '@fido/state';
import { IUserProfileRecord } from '@fido/common';
import styled, { css } from 'styled-components';
import { softGray, unit } from '@fido/styles';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { CurrentConversation } from './components';
import { MobileListItem } from './components/MobileListItem';

const StyledPageContainer = styled(PageContainer)`
  padding: 0;
  display: flex;
  flex: 1;
  height: 100%;
`;

const ConversationsContainer = styled.div`
  ${desktop(css`
    border-right: 1px solid ${softGray};
    width: 275px;
    max-height: 100%; 
    overflow-y: auto;
  `)}
`;

const Header = styled.div`
  padding: ${unit * 3}px ${unit * 4}px;
`;

const MobileHeading = styled(Heading)`
  font-size: 30px;
  font-weight: lighter;
`;

const Contents = styled.div`
  display: flex;
  flex: 1;
  border-top: 1px solid ${softGray};
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export interface IMessagesProps {
  userID?: string;
  userProfiles: Map<string, IUserProfileRecord>;
  conversations: Map<string, IConversationRecord>;
  messages: Map<string, IMessageRecord>;
  loadInitialData(): void;
  onSendMessage(message: ICreateMessageRecord): void;
  markMessagesAsRead(conversationID: string): void;
}

export const Messages = ({
  userID,
  conversations,
  messages,
  userProfiles,
  loadInitialData,
  onSendMessage,
  markMessagesAsRead,
}: IMessagesProps) => {
  const history = useHistory();
  useEffect(() => {
    loadInitialData();
  },[]);
  const { path } = useRouteMatch();
  const [currentConversationID, setCurrentConversationID] = useState<
    string | null
  >(null);
  const mostRecentConversationEntry = conversations.entrySeq().get(0);
  const mostRecentConversationID = mostRecentConversationEntry
    ? mostRecentConversationEntry[0]
    : undefined;
    const currentConversation = mostRecentConversationID
    ? conversations.get(mostRecentConversationID)
    : null;

    const getLatestMessage = (id: string) => {
      const mostRecentMessage = messages
      .filter(msg => msg.conversationID === id)
      .sortBy(msg => msg.createdAt)
      .reverse()
      .first(undefined);
      return mostRecentMessage;
    }

  conversations = conversations.sort((x, y) => +new Date(y.updatedAt) - +new Date(x.updatedAt));
  
  if (isDesktop()) {
    return (
      <StyledPageContainer>
        <Header>
          <Heading>Messages</Heading>
        </Header>
        <Contents>
          <ConversationsContainer>
          <List>
              {conversations
                .mapEntries(([conversationID, conversation]) => {
                  const unreadMessages = messages.filter(
                    msg =>
                      msg.toID === userID &&
                      msg.readStatus === EMessageReadStatus.unread &&
                      msg.conversationID === conversationID
                  );
                  const mostRecentMessage = getLatestMessage(conversationID);
                  return [
                    conversationID,
                    <MobileListItem
                      key={conversationID}
                      avatarSource={
                        userProfiles.get(conversation.userID)?.imageURL
                      }
                      numberOfMessages={unreadMessages.size}
                      selected={conversationID === currentConversationID}
                      showUnreadIndicator={unreadMessages.size > 0}
                      onClick={() => {
                        setCurrentConversationID(conversationID);
                        history.push(`/app/messages/${conversationID}`);
                      }}
                      name={userProfiles.get(conversation.userID)?.name || '--'}
                      description={
                        mostRecentMessage?.type === 'image'
                          ? 'Attachment'
                          : mostRecentMessage?.text
                          ? mostRecentMessage?.text
                          : ''
                      }
                      date={mostRecentMessage?.createdAt}
                    />,
                  ];
                })
                .valueSeq()
                .toArray()}
            </List>
          </ConversationsContainer>
          <Switch>
            <Route path={`${path}/:id`}>
              {userID && currentConversation && (
                <CurrentConversation
                  otherUserProfile={userProfiles.get(
                    currentConversation.userID
                  )}
                  conversations={conversations}
                  onSendMessage={onSendMessage}
                  onSelectConversation={setCurrentConversationID}
                  markMessagesAsRead={markMessagesAsRead}
                />
              )}
            </Route>
            <Route
              render={props =>
                mostRecentConversationID && (
                  <Redirect
                    to={{
                      pathname: `/app/messages/${mostRecentConversationID}`,
                      state: { from: props.location },
                    }}
                  />
                )
              }
            />
          </Switch>
        </Contents>
      </StyledPageContainer>
    );
  } else {
    return (
      <StyledPageContainer>
        <Switch>
          <Route path={`${path}/:id`} exact>
            {userID && currentConversation && (
              <CurrentConversation
                otherUserProfile={userProfiles.get(currentConversation.userID)}
                conversations={conversations}
                onSendMessage={onSendMessage}
                onSelectConversation={setCurrentConversationID}
                markMessagesAsRead={markMessagesAsRead}
              />
            )}
          </Route>
          <Route>
            <Header>
              <MobileHeading>Messages</MobileHeading>
            </Header>
            <ConversationsContainer>
              <List>
                {conversations
                  .mapEntries(([conversationID, conversation]) => {
                    const unreadMessages = messages.filter(
                      msg =>
                        msg.toID === userID &&
                        msg.readStatus === EMessageReadStatus.unread &&
                        msg.conversationID === conversationID
                    );
                    const mostRecentMessage = messages
                      .filter(msg => msg.conversationID === conversationID)
                      .sortBy(msg => msg.createdAt)
                      .reverse()
                      .first(undefined);
                    return [
                      conversationID,
                      <MobileListItem
                        key={conversationID}
                        avatarSource={
                          userProfiles.get(conversation.userID)?.imageURL
                        }
                        numberOfMessages={unreadMessages.size}
                        selected={conversationID === currentConversationID}
                        showUnreadIndicator={unreadMessages.size > 0}
                        onClick={() => {
                          setCurrentConversationID(conversationID);
                          history.push(`/app/messages/${conversationID}`);
                        }}
                        name={
                          userProfiles.get(conversation.userID)?.name || '--'
                        }
                        description={
                          mostRecentMessage?.type === 'image'
                            ? 'Attachment'
                            : mostRecentMessage?.text
                            ? mostRecentMessage?.text
                            : ''
                        }
                        date={mostRecentMessage?.createdAt}
                      />,
                    ];
                  })
                  .valueSeq()
                  .toArray()}
              </List>
            </ConversationsContainer>
          </Route>
        </Switch>
      </StyledPageContainer>
    );
  }
};
