import React, { useEffect } from 'react';
import { PageContainer } from '../common';
import {
  Avatar,
  Button,
  Description,
  desktop,
  Heading,
  isDesktop,
  mobile,
  OutlineButton,
  Text,
} from '@fido/web-components';
import {
  EApplicationReadStatus,
  EApplicationStatus,
  IApplicationRecord,
} from '@fido/state';
import {
  EPetProfileVariant,
  IPetProfileRecord,
  IUserProfileLocationRecord,
  IUserProfileRecord,
  firebase,
  ISellerProfileRecord
} from '@fido/common';
import { Map } from 'immutable';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { unit } from '@fido/styles';
import moment from 'moment';

const analytics = firebase.analytics();

const addingApplicationStatusEvent = (
  eventName: string,
  profile: IPetProfileRecord,
  application: IApplicationRecord
) => {
  analytics.logEvent(eventName, {
    pet_name: profile.name,
    breed: profile.breed,
    price: profile.price,
    dob: profile.dob,
    buyerId: application.userID,
    sellerProfileID: application.sellerProfileID
  });
};

const ProfileSummaryContainer = styled.div`
  padding: ${unit * 3}px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit * 3}px;
`;

const ProfileSummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionButtonsContainer = styled.div`
  display: grid;
  column-gap: ${unit * 2}px;
  grid-template-columns: repeat(3, 1fr);

  ${mobile(css`
    & > button {
      padding-left: 0px;
      padding-right: 0px;
    }
  `)}

  ${desktop(css`
    grid-template-columns: repeat(3, minmax(max-content, 175px));

    & > button {
      max-width: 175px;
    }
  `)}
`;

const ApplicantProfileHeader = styled.div`
  padding: ${unit * 2}px 0;
  display: flex;
  flex-direction: column;
`;

const QuickFactsFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit * 2}px;
  row-gap: ${unit * 2}px;
  padding: ${unit * 2}px 0;
`;

const DetailsFields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${unit * 2}px;
  padding: ${unit * 2}px 0;
`;

export interface IApplicationProps {
  userProfiles: Map<string, IUserProfileRecord>;
  petProfiles: Map<string, IPetProfileRecord>;
  applications: Map<string, IApplicationRecord>;
  sellerProfile?: ISellerProfileRecord;
  loadInitialData(applictationID: string): void;
  onUpdateApplication(
    applictationID: string,
    application: IApplicationRecord
  ): void;
  markApplicationAsRead(applicationID: string): void;
}

export const Application = ({
  applications,
  petProfiles,
  userProfiles,
  sellerProfile,
  loadInitialData,
  onUpdateApplication,
  markApplicationAsRead,
}: IApplicationProps) => {
  const { id: applicationID } = useParams<{ id: string }>();
  useEffect(() => {
    loadInitialData(applicationID);
  }, [applicationID]);
  useEffect(() => {
    markApplicationAsRead(applicationID);
  }, [applicationID]);
  const application = applications.get(applicationID);
  const petProfile = application
    ? petProfiles.get(application.petProfileID)
    : undefined;
  const applicantProfile = application
    ? userProfiles.get(application.userID)
    : undefined;
  const onClickDecineApplication = () => {
    if (application) {
      onUpdateApplication(
        applicationID,
        application
          .set('status', EApplicationStatus.denied)
          .set('buyerReadStatus', EApplicationReadStatus.unread)
      );
      addingApplicationStatusEvent(
        'application_denied',
        petProfile,
        application
      );
    }
  };
  const onClickApproveApplication = () => {
    if (!sellerProfile || sellerProfile.depositAmount === 0) {
      alert('Add deposit amount in payment setting page before approving a puppy.')
      return;
    }
    if (application) {
      onUpdateApplication(
        applicationID,
        application
          .set('status', EApplicationStatus.approved)
          .set('buyerReadStatus', EApplicationReadStatus.unread)
      );
      addingApplicationStatusEvent(
        'application_approved',
        petProfile,
        application
      );
    }
  };
  const history = useHistory();
  const hasApprovedApplication =
    petProfile?.variant === EPetProfileVariant.individual &&
    applications
      .filter(app => app?.petProfileID === application?.petProfileID)
      .filter(app => app.status === EApplicationStatus.approved).size > 0;
  return (
    <PageContainer>
      <Heading>{`Applicant for ${petProfile? petProfile?.name : 'General Waitlist'}`}</Heading>
      <ProfileSummaryContainer>
        <ProfileSummaryColumn>
          <Avatar
            source={applicantProfile?.imageURL}
            fallback={
              applicantProfile
                ? formatApplicantProfileNameForAvatar(applicantProfile)
                : '--'
            }
          />
          <ApplicantProfileHeader>
            <Heading>{applicantProfile?.name}</Heading>
            <Description>
              {applicantProfile
                ? `${applicantProfile.location.city}, ${applicantProfile.location.state}`
                : ''}
            </Description>
          </ApplicantProfileHeader>
          <Text>{applicantProfile?.bio}</Text>
          {!isDesktop() && (
            <>
              <Heading>Quick Facts</Heading>
              <QuickFactsFields>
                {applicantProfile?.yardOrPark && <Text>Yard of Park</Text>}
                {applicantProfile?.kids ? (
                  <Text>Kids</Text>
                ) : (
                  <Text>No Kids</Text>
                )}
                {applicantProfile?.otherDogs ? (
                  <Text>Other Dogs</Text>
                ) : (
                  <Text>No Other Dogs</Text>
                )}
                {applicantProfile?.otherPets ? (
                  <Text>Other Pets</Text>
                ) : (
                  <Text>No Other Pets</Text>
                )}
                {applicantProfile?.busyLifestyle && <Text>Busy Lifestyle</Text>}
                {applicantProfile?.activeLifestyle && (
                  <Text>Active Lifestyle</Text>
                )}
              </QuickFactsFields>
              <Heading>Details</Heading>
              <DetailsFields>
                <Text>
                  {application &&
                    `Applied ${formatDate(application.createdAt)}`}
                </Text>
                <Text>
                  {applicantProfile &&
                    `Member since ${formatDate(applicantProfile.createdAt)}`}
                </Text>
                <Text>
                  {applicantProfile &&
                    formatLocation(applicantProfile.location)}
                </Text>
              </DetailsFields>
            </>
          )}
        </ProfileSummaryColumn>
        {isDesktop() && (
          <ProfileSummaryColumn>
            <Heading>Quick Facts</Heading>
            <QuickFactsFields>
              {applicantProfile?.yardOrPark && <Text>Yard of Park</Text>}
              {applicantProfile?.kids ? (
                <Text>Kids</Text>
              ) : (
                <Text>No Kids</Text>
              )}
              {applicantProfile?.otherDogs ? (
                <Text>Other Dogs</Text>
              ) : (
                <Text>No Other Dogs</Text>
              )}
              {applicantProfile?.otherPets ? (
                <Text>Other Pets</Text>
              ) : (
                <Text>No Other Pets</Text>
              )}
              {applicantProfile?.busyLifestyle && <Text>Busy Lifestyle</Text>}
              {applicantProfile?.activeLifestyle && (
                <Text>Active Lifestyle</Text>
              )}
            </QuickFactsFields>
            <Heading>Details</Heading>
            <DetailsFields>
              <Text>
                {application && `Applied ${formatDate(application.createdAt)}`}
              </Text>
              <Text>
                {applicantProfile &&
                  `Member since ${formatDate(applicantProfile.createdAt)}`}
              </Text>
              <Text>
                {applicantProfile && formatLocation(applicantProfile.location)}
              </Text>
            </DetailsFields>
          </ProfileSummaryColumn>
        )}
      </ProfileSummaryContainer>
      <ActionButtonsContainer>
        <OutlineButton
          size="large"
          onClick={() =>
            application &&
            history.push(
              `/app/messages/${application.userID}-${application.sellerProfileID}`
            )
          }>
          Message
        </OutlineButton>
        <Button
          disabled={application?.status === EApplicationStatus.denied}
          size="large"
          variant="default"
          onClick={onClickDecineApplication}>
          {application?.status === EApplicationStatus.approved
            ? 'Decline'
            : application?.status === EApplicationStatus.denied
            ? 'Declined'
            : 'Decline'}
        </Button>
        <Button
          disabled={
            hasApprovedApplication ||
            application?.status === EApplicationStatus.approved
          }
          size="large"
          variant="primary"
          onClick={onClickApproveApplication}>
          {application?.status === EApplicationStatus.approved
            ? 'Approved'
            : 'Approve'}
        </Button>
      </ActionButtonsContainer>
    </PageContainer>
  );
};

const formatDate = (timestamp: string) =>
  moment(timestamp).format('MMMM DD, YYYY');

const formatApplicantProfileNameForAvatar = (profile: IUserProfileRecord) => {
  const nameParts = profile.name.split(' ');
  if (nameParts.length <= 1) {
    return '';
  }
  const firstName = nameParts[0][0] || '';
  const lastName = nameParts[1][0] || '';
  return `${firstName.toLocaleUpperCase()}${lastName.toLocaleUpperCase()}`;
};

const formatLocation = (location: IUserProfileLocationRecord): string =>
  location.city && location.state ? `${location.city}, ${location.state}` : '';
