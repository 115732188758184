import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  Heading,
  HorizontalMenu,
  HorizontalMenuItem,
} from '@fido/web-components';
import {
  createStripeTokenWithPlaid,
  IUserProfileLocationRecord,
  IRoleRecord,
  createRole
} from '@fido/common';
import styled from 'styled-components';
import { unit } from '@fido/styles';
import {
  IUserProfileRecord,
  ISellerProfileRecord,
  ISellerBankAccountRecord,
} from '@fido/common';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { ProfileSettings, PaymentSettings } from './components';
import { AddPaymentMethodModal } from './components/AddPaymentMethodModal';
import moment from 'moment';

const Container = styled.div`
  padding: ${unit * 4}px;
`;

const StyledHorizontalMenu = styled(HorizontalMenu)`
  padding: ${unit * 3}px 0;
`;

export interface IAccountProps {
  userID?: string;
  role?: IRoleRecord;
  userProfile?: IUserProfileRecord;
  sellerProfile?: ISellerProfileRecord;
  sellerBankAccount?: ISellerBankAccountRecord;
  loadInitialData(): void;
  updateSellerProfile(sellerProfile: ISellerProfileRecord): void;
  updateLocation(location: IUserProfileLocationRecord): void;
  updatePaymentDetails(stripeToken: string): void;
  updateRole(role: IRoleRecord): void;
}

export const Account = ({
  role,
  userID,
  userProfile,
  sellerProfile,
  sellerBankAccount,
  loadInitialData,
  updateLocation,
  updateSellerProfile,
  updatePaymentDetails,
  updateRole,
}: IAccountProps) => {
  useEffect(() => {
    loadInitialData();
  }, []);
  const { pathname } = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const isActiveRoute = (routePath: string) =>
    !!matchPath(pathname, { path: routePath });
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const stripe = useStripe();
  
  const onUpdateRole = async() => {
    if (userID && role && role.isSubscribedTo.length > 0) {
      let roles: string[] = []
      roles = role.isSubscribedTo
      if (!role.isSubscribedTo.includes('Breeder')) {
        roles.push('Breeder')
      }
      updateRole(
        role
        .set('createdAt', role.createdAt)
        .set('isSubscribedTo', roles)
        .set('currentRole', 'Breeder')
        .set('userID', role .userID)
      )
    } else {
      if (userID) {
        const roles = ['Breeder'];
        let cRole = {
          isSubscribedTo: roles,
          userID: userID,
          currentRole: 'Breeder',
          createdAt: moment().format(),
        } as IRoleRecord;
        await createRole(userID, cRole);
      }
    }
  }
  return (
    <Container>
      <AddPaymentMethodModal
        visible={showPaymentMethodModal}
        onClickSave={async paymentDetails => {
          setShowPaymentMethodModal(false);
          const stripeResponse = await stripe.createToken(
            'bank_account',
            {
              country: 'US',
              currency: 'USD',
              routing_number: paymentDetails.routingNumber,
              account_number: paymentDetails.accountNumber,
            },
            {
              stripe_account:
                sellerProfile?.paymentSettings.stripePaymentAccountID,
            }
          );
          const { token, error } = stripeResponse;
          if (error) {
            alert(`Unable to add bank account: ${error.message}`);
            return;
          }
          updatePaymentDetails(token.id);
        }}
        onClickCancel={() => setShowPaymentMethodModal(false)}
      />
      <Heading>Settings</Heading>
      <StyledHorizontalMenu>
        <HorizontalMenuItem
          active={isActiveRoute(`${path}/profile`)}
          onClick={() => history.push(`${path}/profile`)}>
          My Profile
        </HorizontalMenuItem>
        <HorizontalMenuItem
          active={isActiveRoute(`${path}/payment`)}
          onClick={() => history.push(`${path}/payment`)}>
          Payment Information
        </HorizontalMenuItem>
      </StyledHorizontalMenu>
      <Switch>
        <Route path={`${path}/profile`}>
          {sellerProfile && userProfile && userID && (
            <ProfileSettings
              userID={userID}
              userProfile={userProfile}
              sellerProfile={sellerProfile}
              onPressGenerateStripeUrl = {(sProfile) => {
                updateSellerProfile(sProfile);
              }}
              onPressSave={(sProfile, location) => {
                onUpdateRole();
                updateSellerProfile(sProfile);
                updateLocation(location);
              }}
            />
          )}
        </Route>
        <Route path={`${path}/payment`}>
          {sellerProfile && (
            <PaymentSettings
              sellerId={userID}
              sellerBankAccount={sellerBankAccount}
              sellerProfile={sellerProfile}
              onClickSave={updateSellerProfile}
              onClickAddPaymentMethod={() => setShowPaymentMethodModal(true)}
              onClickUpdatePaymentMethod={() => setShowPaymentMethodModal(true)}
              onLinkPlaidAccount={async (plaidPublicToken, plaidAccountID) => {
                const result = await createStripeTokenWithPlaid(
                  plaidPublicToken,
                  plaidAccountID
                );
                if (result.isOk()) {
                  const { stripeToken: stripeBankAccountToken } = result.ok()!;
                  updatePaymentDetails(stripeBankAccountToken);
                }
              }}
            />
          )}
        </Route>
        <Route
          render={props => (
            <Redirect
              to={{
                pathname: `${path}/profile`,
                state: { from: props.location },
              }}
            />
          )}
        />
      </Switch>
    </Container>
  );
};
