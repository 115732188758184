import React, { useState, useReducer, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { unit } from '@fido/styles';
import { uploadImage } from '@fido/state/lib/api';
import {
  Text,
  Heading,
  Avatar,
  EditButton,
  InputFormField,
  Button,
  ImageUploadGridItem,
  TextAreaFormField,
  convertImageURIToSupportedFileFormatLossy,
  desktop,
  isDesktop
} from '@fido/web-components';
import {
  ISellerProfileRecord,
  IUserProfileRecord,
  IUserProfileLocationRecord,
} from '@fido/common';
import {
  profileSettingsReducer,
  ProfileSettingsStateRecord,
  setName,
  setDescription,
  setHealthDescription,
  setHealthGuaranteeDescription,
  setDeliveryDescription,
  setIncludedWithPuppyDescription,
  setLocation,
  setAvatar,
  setCellNumber,
  setSmsEnable,
  IProfileSettingsState,
  setBreeds,
  setMinValue,
  setMaxValue,
  setAddress,
  setLongitude,
  setLatitude,
  setImages,
  setGeneralWaitlistEnabled,
  setSellerID
} from './profileSettingsState';
import { gray, warmPink } from '@fido/styles';
import { breeds } from '../../../../utils';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import compact from 'lodash/compact';
import { List } from 'immutable';
import './dropdown.css';
import './styles.css';
import ImageViewer from 'react-simple-image-viewer';
import Carousel from "react-multi-carousel";
//import "react-multi-carousel/lib/styles.css";
import close from './Close.png';
import { baseUrl, domainName } from '../../../../config'

var Loader = require('react-loader');

const uploadImageFile = async (
  userID: string,
  image: File | null
): Promise<string | null> => {
  if (image) {
    const results = await uploadImage(userID, image);
    return results.ok();
  } else {
    return Promise.resolve(null);
  }
};

const components = {
  DropdownIndicator: null,
};

const Container = styled.div``;

const DetailsContainer = styled.div`
  ${desktop(css`
    display: flex;
    flex: 1;
    flex-direction: column;
  `)}
`;

const AvatarAndEditButton = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  padding-bottom: ${unit * 3}px;
  justify-content: space-between;
`;

const AvatarWrapper = styled.div`
  width: 48px;
`;

const StyledInputFormField = styled(InputFormField)`
  display: block;
  margin-top: 10px;
  padding-bottom: ${unit}px;
`;

const SelectOptions = styled(Select)`
  padding: ${unit}px 0;
  max-width: 345px;
`
const SearchAddress = styled(AsyncSelect)`
  padding: ${unit}px 0;
  max-width: 345px;
`
const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${unit * 3}px;
`;

const HeaderProfileSection = styled(ProfileSection)`
  min-height: 65px;
`;

const StyledTextAreaFormField = styled(TextAreaFormField)`
  padding-bottom: ${unit * 2}px;
  display: block;
  textarea {
    display: block;
    min-height: 100px;
  }
`;

const DescriptionText = styled(Text)`
  padding-top: ${unit * 2}px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit * 2}px;
  padding-top: ${unit * 2}px;
  max-width: 500px;
`;

const SmsText = styled(Text)`
  padding-bottom: ${unit * 3}px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormColumn = styled.div`
  padding: ${unit * 3}px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const PriceRangeColumn = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 345px;
`;

const GridContainer = styled.div<{ h: number }>`
  height: ${props => props.h}px;
  flex-direction: row;
  max-width: 545px;
  padding-top: 10px;
`;

const ControlsContainer = styled.div`
  padding-top: 5px;
  padding-bottom: ${unit * 4}px;
`;

const ToText = styled(Text)`
  padding: 10px; 
  padding-top: 18px;
`
const PriceDescriptionText = styled(Text)`
  padding-top: 5px; 
  max-width: 345px;
`;

const PriceInputFormField = styled(InputFormField)`
  display: block;
  padding-bottom: ${unit}px;
  padding-top: 10px;
`;

const UploadText = styled.div`
  font-size: 12px;
  padding-top: 25px;
  padding-left: 10px;
  font-weight: 400;
  color: #000000;
  justify-content: center;
  align-items: center;
  font-family: 'Red Hat Text', sans-serif;
`
const ImageButton = styled.button`
  background-color: #4FD3DB;
  border-color: #4FD3DB;
  border-width: 0px;
  color: #FFFFFF;
  font-weight: medium;
  font-size: 16px;
  padding: 15px 15px;
  border-radius: 5px;
  margin: 10px 0px;
  max-width: 345px;
  max-height: 50px;
  outline:none;
`;

const StripeButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit * 1}px;
  padding-top: ${unit * 2}px;
  max-width: 400px;
`;

const StripeButton = styled.button<{ active: boolean }>`
  background-color: ${props => (props.active ? '#4FD3DB' : 'gray')};
  border-color: ${props => (props.active ? '#4FD3DB' : 'gray')};
  border-width: 0px;
  color: #FFFFFF;
  font-weight: medium;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  margin: 0px 0px;
  max-width: 200px;
  max-height: 50px;
  outline:none;
`;

const isMobile = window.matchMedia(
  "only screen and (max-width: 479px)");

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};

const options = {
  lines: 13,
  length: 20,
  width: 5,
  radius: 30,
  scale: 0.4,
  corners: 1,
  color: '#000',
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 2e9,
  top: isMobile.matches ? '-450%' : '50%',
  left: isMobile.matches ? '50%' : '60%',
  shadow: false,
  hwaccel: false,
  position: 'absolute',
}

const customStyles = (hasError) => ({
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    background: "#FAFAFA",
    borderRadius: state.isFocused ? "5px 5px 5px 5px" : 5,
    borderColor: hasError ? warmPink : gray,
    boxShadow: hasError ? "0px 0px 2px 1px #DB4F6D" : null,
    "&:hover": {
      borderColor: state.isFocused ? gray : gray
    },
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    hyphens: "auto",
    marginTop: -5,
    textAlign: "left",
    wordWrap: "break-word",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
  })
});

const validateForm = (profileState: IProfileSettingsState) => {
  return !profileState.name ||
    !profileState.description ||
    !profileState.cellNumber ? false : true;
};

export interface IProfileSettingsProps {
  userID: string;
  sellerProfile: ISellerProfileRecord;
  userProfile: IUserProfileRecord;
  onPressGenerateStripeUrl(sellerProfile: ISellerProfileRecord): void;
  onPressSave(
    sellerProfile: ISellerProfileRecord,
    location: IUserProfileLocationRecord
  ): void;
}

export const ProfileSettings = ({
  userID,
  sellerProfile,
  userProfile,
  onPressSave,
  onPressGenerateStripeUrl,
}: IProfileSettingsProps) => {
  const [state, dispatch] = useReducer(
    profileSettingsReducer,
    ProfileSettingsStateRecord()
  );
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [hideArrows, setHideArrows] = useState(true)

  const reset = async () => {
    const avatar = await convertImageURIToSupportedFileFormatLossy(
      sellerProfile.avatar
    );
    dispatch(setSellerID(sellerProfile.sellerID));
    dispatch(setName(sellerProfile.name));
    dispatch(setLocation(userProfile.location));
    dispatch(setCellNumber(sellerProfile.cellNumber.substring(2)));
    dispatch(setSmsEnable(sellerProfile.enableSms));
    dispatch(setDescription(sellerProfile.description));
    dispatch(setHealthDescription(sellerProfile.healthDescription));
    dispatch(
      setHealthGuaranteeDescription(sellerProfile.healthGuaranteeDescription)
    );
    dispatch(setDeliveryDescription(sellerProfile.deliveryDescription));
    dispatch(setAddress(sellerProfile.address))
    dispatch(setLatitude(sellerProfile.latitude))
    dispatch(setLongitude(sellerProfile.longitude))
    dispatch(setMinValue(sellerProfile.minValue))
    dispatch(setMaxValue(sellerProfile.maxValue))
    dispatch(setBreeds(sellerProfile.breeds))
    dispatch(setImages(sellerProfile.images))
    dispatch(
      setIncludedWithPuppyDescription(
        sellerProfile.includedWithPuppyDescription
      )
    );
    dispatch(setAvatar(avatar || null));
    dispatch(setGeneralWaitlistEnabled(sellerProfile.generalWaitlistEnabled))
  };

  useEffect(() => {
    reset();
  }, [sellerProfile]);

  const onCancel = () => {
    reset();
    setEditing(false);
  };

  const onSave = async () => {
    if (!validateForm(state) || !state.address.value) {
      return;
    }
    setSubmitting(true);
    const avatarURI = await uploadImageFile(userID, state.avatar);
    let updatedProfile = sellerProfile
      .set('sellerID', state.sellerID)
      .set('name', state.name)
      .set('description', state.description)
      .set('includedWithPuppyDescription', state.includedWithPuppyDescription)
      .set('healthDescription', state.healthDescription)
      .set('healthGuaranteeDescription', state.healthGuaranteeDescription)
      .set('deliveryDescription', state.deliveryDescription)
      .set('cellNumber', formatCellNumber(state.cellNumber))
      .set('enableSms', state.enableSms)
      .set('address', state.address)
      .set('latitude', state.latitude)
      .set('longitude', state.longitude)
      .set('minValue', state.minValue)
      .set('maxValue', state.maxValue)
      .set('breeds', state.breeds)
      .set('images', state.images)
      .set('generalWaitlistEnabled', state.generalWaitlistEnabled)
    if (avatarURI) {
      updatedProfile = updatedProfile.set('avatar', avatarURI);
    }
    onPressSave(updatedProfile, state.location);
    setEditing(false);
    setSubmitting(false);
  };

  const updateSellerProfile = async (imgs: List<string>) => {
    if (!validateForm(state)) {
      return;
    }
    const avatarURI = await uploadImageFile(userID, state.avatar);
    let updatedProfile = sellerProfile
      .set('sellerID', state.sellerID)
      .set('name', state.name)
      .set('description', state.description)
      .set('includedWithPuppyDescription', state.includedWithPuppyDescription)
      .set('healthDescription', state.healthDescription)
      .set('healthGuaranteeDescription', state.healthGuaranteeDescription)
      .set('deliveryDescription', state.deliveryDescription)
      .set('cellNumber', formatCellNumber(state.cellNumber))
      .set('enableSms', state.enableSms)
      .set('address', state.address)
      .set('latitude', state.latitude)
      .set('longitude', state.longitude)
      .set('minValue', state.minValue)
      .set('maxValue', state.maxValue)
      .set('breeds', state.breeds)
      .set('images', imgs)
      .set('generalWaitlistEnabled', state.generalWaitlistEnabled)
    if (avatarURI) {
      updatedProfile = updatedProfile.set('avatar', avatarURI);
    }
    onPressSave(updatedProfile, state.location);
  }

  const formateSellerProfile = () => {
    return sellerProfile
      .set('sellerID', userID)
      .set('name', state.name)
      .set('description', state.description)
      .set('includedWithPuppyDescription', state.includedWithPuppyDescription)
      .set('healthDescription', state.healthDescription)
      .set('healthGuaranteeDescription', state.healthGuaranteeDescription)
      .set('deliveryDescription', state.deliveryDescription)
      .set('cellNumber', formatCellNumber(state.cellNumber))
      .set('enableSms', state.enableSms)
      .set('address', state.address)
      .set('latitude', state.latitude)
      .set('longitude', state.longitude)
      .set('minValue', state.minValue)
      .set('maxValue', state.maxValue)
      .set('breeds', state.breeds)
      .set('images', state.images)
      .set('generalWaitlistEnabled', state.generalWaitlistEnabled)
  }

  const loadOptions = (inputValue: any) => {
    const params = {
      text: inputValue,
    };
    try {
      return fetch(`${baseUrl}/fetchLocationAddress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }).then(res => res.json())
        .then(responseAsJson => {
          return responseAsJson.body.result.predictions.map((item: { terms: { value: any; }[]; description: any; }) => {
            const state =
              item.terms.length > 0
                ? item.terms[item.terms.length - 2].value
                : '';
            const city =
              item.terms.length > 0
                ? item.terms[item.terms.length - 3].value
                : '';
            return {
              value: item.description,
              label: item.description,
              state: state,
              city: city,
              placeId: item?.place_id,
            };
          })
        });
    }
    catch (e) {
      alert('Something went wrong, try again.')
    }
  };

  const handleChange = async (address: any) => {
    if (!address) {
      dispatch(setAddress(''))
      return
    }
    dispatch(setAddress(address))
    saveAddressCoords(address)
    dispatch(setLocation(state.location.set('city', address.city)))
    dispatch(setLocation(state.location.set('state', address.state)))
  }

  const saveAddressCoords = (address) => {
    if (!address) { return }
    const params = {
      text: address.value,
      placeId: address.placeId
    };
    try {
      return fetch(`${baseUrl}/fetchCoordsForAddress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }).then(res => res.json())
        .then(responseAsJson => {
          const location = responseAsJson.body.result.result.geometry.location;
          dispatch(setLatitude(location.lat))
          dispatch(setLongitude(location.lng))
        });
    }
    catch (e) {
      alert('Something went wrong, try again.')
    }
  }

  const onChooseImagesToUpload = async event => {
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      (async () => {
        setLoading(false);
        const result = await uploadImage(userID, file);
        if (result.ok()) {
          const images = List(compact([result].map(result => result.ok())));
          const imgs = images ? state.images.concat(images) : state.images
          dispatch(setImages(imgs))
          updateSellerProfile(imgs)
          setLoading(true);
        } else {
          setLoading(true);
          alert('Failed to upload image, try again.');
          return;
        }
      })();
    }
  };

  const onSelect = (key) => {
    setCurrentImage(key)
    setIsViewerOpen(true);
    setHideArrows(false)
  }

  const deleteProfilePicture = (index: number) => {
    let imgs = state.images
    imgs = imgs.splice(index, 1)
    dispatch(setImages(imgs))
    updateSellerProfile(imgs)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false);
    setHideArrows(true);
  };


  const onChangeGeneralWaitlist = (event: any) => {
    dispatch(setGeneralWaitlistEnabled(event.target.checked))
  }

  const onGenerateStripeUrl = () => {

    const isAccountSetupDone = sellerProfile.paymentSettings.stripePaymentAccountID
      ? true
      : false;

    if (!isAccountSetupDone) {
      alert('Complete your bank account settings, before you generate a payment link.')
      return;
    }

    if (sellerProfile.depositAmount === 0) {
      alert('Add reservation fee, before you generate a payment link.')
      return;
    }

    const profile = formateSellerProfile()
    onPressGenerateStripeUrl(profile)
  }

  const onCopyStripeUrl = async () => {
    const stripeUrl = `${domainName}/checkout?Id=${state.sellerID}`
    await navigator.clipboard.writeText(stripeUrl);
    alert('Copied to clipboard.')
  }

  const list = state.images.toArray();

  if (isDesktop()) {
    return (
      <Container>
        <Loader loaded={isLoading} options={options} className={'spinner'} />
        <AvatarAndEditButton>
          <AvatarWrapper>
            {editing ? (
              <ImageUploadGridItem
                value={state.avatar}
                onChangeValue={file => dispatch(setAvatar(file))}
              />
            ) : (
              <Avatar
                fallback={formatSellerProfileNameForAvatar(sellerProfile)}
                source={sellerProfile.avatar}
              />
            )}
          </AvatarWrapper>
          {!editing ? <EditButton onClick={() => setEditing(true)} /> : null}
        </AvatarAndEditButton>
        {editing ? (
          <FormContainer>
            <FormColumn>
              <StyledInputFormField
                label="Business Name"
                value={state.name}
                valid={!!state.name}
                onChangeValue={name => dispatch(setName(name))}
              />
              <Heading style={{ marginTop: 10 }}>Enter Address</Heading>
              <SearchAddress
                styles={customStyles(state.address ? false : true)}
                components={components}
                className="dropdown"
                placeholder="Enter address"
                cacheOptions
                defaultOptions
                value={state.address}
                getOptionLabel={(e: { label: any }) => e.label}
                getOptionValue={(e: { value: any }) => e.value}
                loadOptions={loadOptions}
                onChange={handleChange}
                isClearable
                isSearchable
              />
              <StyledInputFormField
                label="Cell Number"
                value={state.cellNumber}
                valid={!!state.cellNumber}
                prefix={true}
                onChangeValue={cell => dispatch(setCellNumber(cell))}
              />
              <SmsText>
                <input
                  name="remember"
                  type="checkbox"
                  checked={state.enableSms}
                  onChange={check =>
                    dispatch(setSmsEnable(check.target.checked))
                  }
                />
                <Text color={gray} weight={'regular'}>
                  Receive SMS notification.
                </Text>
              </SmsText>
              <StyledTextAreaFormField
                label="Description"
                value={state.description}
                valid={!!state.description}
                onChangeValue={description =>
                  dispatch(setDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Included with the puppy"
                value={state.includedWithPuppyDescription}
                onChangeValue={description =>
                  dispatch(setIncludedWithPuppyDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Health"
                value={state.healthDescription}
                onChangeValue={description =>
                  dispatch(setHealthDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Certifications &amp; Awards"
                value={state.healthGuaranteeDescription}
                onChangeValue={description =>
                  dispatch(setHealthGuaranteeDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Pickup &amp; Delivery"
                value={state.deliveryDescription}
                onChangeValue={description =>
                  dispatch(setDeliveryDescription(description))
                }
              />
              <ButtonContainer>
                <Button size="large" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  disabled={submitting}
                  onClick={onSave}>
                  {submitting ? 'Saving...' : 'Save'}
                </Button>
              </ButtonContainer>
            </FormColumn>
            <FormColumn>
              <Heading style={{ marginTop: 10 }}>Breed(s)</Heading>
              <SelectOptions
                styles={customStyles(state.breeds ? false : true)}
                className="dropdown"
                placeholder="Select from dropdown"
                value={
                  state.breeds.size > 0
                    ? state.breeds.toArray()
                    : state.breeds.size === 0
                      ? null
                      : state.breeds
                }
                onChange={(option: List<string>) => dispatch(setBreeds(option))}
                options={breeds.map(val => ({ value: val, label: val }))}
                isSearchable
                isMulti>
                {breeds.map((e, key) => {
                  return (
                    <option key={key} value={e}>
                      {e}
                    </option>
                  );
                })}
              </SelectOptions>
              <Heading style={{ marginTop: 10 }}>Price Range</Heading>
              <PriceDescriptionText color={gray} weight={'regular'}>
                This provides the buyer a typical price range for your dogs.
              </PriceDescriptionText>
              <PriceRangeColumn>
                <PriceInputFormField
                  valid={!!state.minValue}
                  value={state.minValue}
                  placeholder="Min"
                  onChangeValue={vallue => dispatch(setMinValue(vallue))}
                />
                <ToText color={gray} weight={'regular'}>
                  to
                </ToText>
                <PriceInputFormField
                  valid={!!state.maxValue}
                  value={state.maxValue}
                  placeholder="Max"
                  onChangeValue={value => dispatch(setMaxValue(value))}
                />
              </PriceRangeColumn>
              <Heading style={{ marginTop: 10 }}>Featured Pictures</Heading>
              <PriceDescriptionText color={gray} weight={'regular'}>
                This are the pictures that will be shown on your public breeder
                listing.
              </PriceDescriptionText>
              <PriceRangeColumn>
                <ImageButton
                  onClick={() => inputRef.current?.click()}
                  disabled={state.images.size >= 10 ? true : false}>
                  Add Picture
                </ImageButton>
                <UploadText>
                  {state.images.size >= 10
                    ? `10 pictures uploaded.`
                    : `Upload up to 10 pictures.`}
                </UploadText>
              </PriceRangeColumn>
              <input
                type="file"
                id="file"
                accept="image/*"
                ref={inputRef}
                style={{ display: 'none' }}
                multiple={false}
                onChange={onChooseImagesToUpload}
              />
              <GridContainer h={state.images.size > 0 ? 120 : 10}>
                <Carousel
                  ssr={true}
                  keyBoardControl={false}
                  arrows={hideArrows}
                  responsive={responsive}>
                  {list.map((url, index) => {
                    return (
                      <div key={index}>
                        <img
                          className="overlay"
                          onClick={() => deleteProfilePicture(index)}
                          src={close}
                          alt="close"
                        />
                        <img
                          onClick={() => onSelect(index)}
                          src={url}
                          alt="Logo"
                          className={`menu-item`}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </GridContainer>
              {isViewerOpen && (
                <ImageViewer
                  src={list}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                />
              )}
              <Heading style={{ marginTop: 10 }}>General Waitlist</Heading>
              <Container>
                <ControlsContainer>
                  <input
                    name="remember"
                    type="checkbox"
                    defaultChecked={state.generalWaitlistEnabled}
                    onChange={onChangeGeneralWaitlist}
                  />
                  <Text color={gray} weight={'regular'}>
                    Check this box to allow people to apply for your general
                    waitlist. You can change this status at any time.
                  </Text>
                </ControlsContainer>
              </Container>
              <Heading style={{ marginTop: 10 }}>Generate Payment Link</Heading>
              <StripeButtonContainer>
                <StripeButton
                  active={state.sellerID && sellerProfile.depositAmount === 0 ? false : true}
                  onClick={onGenerateStripeUrl}
                  disabled={state.sellerID ? true : false}>
                  Payment Link
                </StripeButton>
                {state.sellerID ? (
                  <StripeButton
                    active={state.sellerID ? true : false}
                    disabled={state.sellerID ? false : true}
                    onClick={onCopyStripeUrl}>
                    Copy Payment Link
                  </StripeButton>
                ) : null}
              </StripeButtonContainer>
            </FormColumn>
          </FormContainer>
        ) : (
          <DetailsContainer>
            <HeaderProfileSection>
              <Heading>{sellerProfile.name}</Heading>
              <DescriptionText>{sellerProfile.description}</DescriptionText>
            </HeaderProfileSection>
            <ProfileSection>
              <Heading>Location</Heading>
              <DescriptionText>{sellerProfile.address.value}</DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Cell number</Heading>
              <DescriptionText>{sellerProfile.cellNumber}</DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Included with the puppy</Heading>
              <DescriptionText>
                {sellerProfile.includedWithPuppyDescription}
              </DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Health</Heading>
              <DescriptionText>
                {sellerProfile.healthDescription}
              </DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Certifications &amp; Awards</Heading>
              <DescriptionText>
                {sellerProfile.healthGuaranteeDescription}
              </DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Pickup &amp; Delivery</Heading>
              <DescriptionText>
                {sellerProfile.deliveryDescription}
              </DescriptionText>
            </ProfileSection>
            <HeaderProfileSection>
              <Heading>Breed(s)</Heading>
              <DescriptionText>
                {sellerProfile.breeds
                  .map(item => {
                    return item.value;
                  })
                  .join(', ')}
                .
              </DescriptionText>
            </HeaderProfileSection>
            <HeaderProfileSection>
              <Heading>Price Range</Heading>
              <DescriptionText>{`Min : $${sellerProfile.minValue} - Max : $${sellerProfile.maxValue}`}</DescriptionText>
            </HeaderProfileSection>
            <ProfileSection>
              <Heading>Generate Payment Link</Heading>
              <StripeButtonContainer>
                <StripeButton
                  active={state.sellerID ? false : true}
                  onClick={onGenerateStripeUrl}
                  disabled={state.sellerID ? true : false}>
                  Payment Link
                </StripeButton>
                {state.sellerID ? (
                  <StripeButton
                    active={state.sellerID ? true : false}
                    disabled={state.sellerID ? false : true}
                    onClick={onCopyStripeUrl}>
                    Copy Payment Link
                  </StripeButton>
                ) : null}
              </StripeButtonContainer>
            </ProfileSection>
            <HeaderProfileSection>
              <Heading>Featured Pictures</Heading>
              {sellerProfile.images.size > 0 ? (
                <GridContainer h={state.images.size > 0 ? 120 : 10}>
                  <Carousel
                    ssr={true}
                    keyBoardControl={true}
                    responsive={responsive}>
                    {sellerProfile.images.map((url, index) => {
                      return (
                        <div key={index}>
                          <img src={url} alt="Logo" className={`menu-item`} />
                        </div>
                      );
                    })}
                  </Carousel>
                </GridContainer>
              ) : null}
            </HeaderProfileSection>
          </DetailsContainer>
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        <Loader loaded={isLoading} options={options} className={'spinner'} />
        <AvatarAndEditButton>
          <AvatarWrapper>
            {editing ? (
              <ImageUploadGridItem
                value={state.avatar}
                onChangeValue={file => dispatch(setAvatar(file))}
              />
            ) : (
              <Avatar
                fallback={formatSellerProfileNameForAvatar(sellerProfile)}
                source={sellerProfile.avatar}
              />
            )}
          </AvatarWrapper>
          {!editing ? <EditButton onClick={() => setEditing(true)} /> : null}
        </AvatarAndEditButton>
        {editing ? (
          <FormContainer>
            <FormColumn>
              <StyledInputFormField
                label="Business Name"
                value={state.name}
                valid={!!state.name}
                onChangeValue={name => dispatch(setName(name))}
              />
              <Heading style={{ marginTop: 10 }}>Enter Address</Heading>
              <SearchAddress
                styles={customStyles(state.address ? false : true)}
                components={components}
                className="dropdown"
                placeholder="Enter address"
                cacheOptions
                defaultOptions
                value={
                  state.address?.value ? state.address?.value : state.address
                }
                getOptionLabel={(e: { label: any }) => e.label}
                getOptionValue={(e: { value: any }) => e.value}
                loadOptions={loadOptions}
                onChange={handleChange}
                isClearable
                isSearchable
              />
              <StyledInputFormField
                label="Cell Number"
                value={state.cellNumber}
                valid={!!state.cellNumber}
                prefix={true}
                onChangeValue={cell => dispatch(setCellNumber(cell))}
              />
              <SmsText>
                <input
                  name="remember"
                  type="checkbox"
                  checked={state.enableSms}
                  onChange={check =>
                    dispatch(setSmsEnable(check.target.checked))
                  }
                />
                <Text color={gray} weight={'regular'}>
                  Receive SMS notification.
                </Text>
              </SmsText>
              <StyledTextAreaFormField
                label="Description"
                value={state.description}
                valid={!!state.description}
                onChangeValue={description =>
                  dispatch(setDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Included with the puppy"
                value={state.includedWithPuppyDescription}
                onChangeValue={description =>
                  dispatch(setIncludedWithPuppyDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Health"
                value={state.healthDescription}
                onChangeValue={description =>
                  dispatch(setHealthDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Certifications &amp; Awards"
                value={state.healthGuaranteeDescription}
                onChangeValue={description =>
                  dispatch(setHealthGuaranteeDescription(description))
                }
              />
              <StyledTextAreaFormField
                label="Pickup &amp; Delivery"
                value={state.deliveryDescription}
                onChangeValue={description =>
                  dispatch(setDeliveryDescription(description))
                }
              />
              <Heading style={{ marginTop: 10 }}>Breed(s)</Heading>
              <SelectOptions
                styles={customStyles(state.breeds ? false : true)}
                className="dropdown"
                placeholder="Select from dropdown"
                value={
                  state.breeds.size > 0
                    ? state.breeds.toArray()
                    : state.breeds.size === 0
                      ? null
                      : state.breeds
                }
                onChange={(option: List<string>) => dispatch(setBreeds(option))}
                options={breeds.map(val => ({ value: val, label: val }))}
                isSearchable
                isMulti>
                {breeds.map((e, key) => {
                  return (
                    <option key={key} value={e}>
                      {e}
                    </option>
                  );
                })}
              </SelectOptions>
              <Heading style={{ marginTop: 10 }}>Price Range</Heading>
              <PriceDescriptionText color={gray} weight={'regular'}>
                This provides the buyer a typical price range for your dogs.
              </PriceDescriptionText>
              <PriceRangeColumn>
                <PriceInputFormField
                  valid={!!state.minValue}
                  value={state.minValue}
                  placeholder="Min"
                  onChangeValue={value => dispatch(setMinValue(value))}
                />
                <ToText color={gray} weight={'regular'}>
                  to
                </ToText>
                <PriceInputFormField
                  valid={!!state.maxValue}
                  value={state.maxValue}
                  placeholder="Max"
                  onChangeValue={value => dispatch(setMaxValue(value))}
                />
              </PriceRangeColumn>
              <Heading style={{ marginTop: 10 }}>Featured Pictures</Heading>
              <PriceDescriptionText color={gray} weight={'regular'}>
                This are the pictures that will be shown on your public breeder
                listing.
              </PriceDescriptionText>
              <PriceRangeColumn>
                <ImageButton
                  onClick={() => inputRef.current?.click()}
                  disabled={state.images.size >= 10 ? true : false}>
                  Add Picture
                </ImageButton>
                <UploadText>
                  {state.images.size >= 10
                    ? `10 pictures uploaded.`
                    : `Upload up to 10 pictures.`}
                </UploadText>
              </PriceRangeColumn>
              <input
                type="file"
                id="file"
                accept="image/*"
                ref={inputRef}
                style={{ display: 'none' }}
                multiple={false}
                onChange={onChooseImagesToUpload}
              />
              <GridContainer h={state.images.size > 0 ? 120 : 10}>
                <Carousel
                  ssr={true}
                  keyBoardControl={false}
                  arrows={hideArrows}
                  responsive={responsive}>
                  {list.map((url, index) => {
                    return (
                      <div key={index}>
                        <img
                          className="overlayMobile"
                          onClick={() => deleteProfilePicture(index)}
                          src={close}
                          alt="close"
                        />
                        <img
                          onClick={() => onSelect(index)}
                          src={url}
                          alt="Logo"
                          className={`menu-item`}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </GridContainer>
              {isViewerOpen && (
                <ImageViewer
                  src={list}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                />
              )}
              <Heading style={{ marginTop: 10 }}>General Waitlist</Heading>
              <Container>
                <ControlsContainer>
                  <input
                    name="remember"
                    type="checkbox"
                    defaultChecked={state.generalWaitlistEnabled}
                    onChange={onChangeGeneralWaitlist}
                  />
                  <Text color={gray} weight={'regular'}>
                    Check this box to allow people to apply for your general
                    waitlist. You can change this status at any time.
                  </Text>
                </ControlsContainer>
              </Container>
              <ButtonContainer>
                <Button size="large" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  disabled={submitting}
                  onClick={onSave}>
                  {submitting ? 'Saving...' : 'Save'}
                </Button>
              </ButtonContainer>
            </FormColumn>
          </FormContainer>
        ) : (
          <DetailsContainer>
            <HeaderProfileSection>
              <Heading>{sellerProfile.name}</Heading>
              <DescriptionText>{sellerProfile.description}</DescriptionText>
            </HeaderProfileSection >
            <ProfileSection>
              <Heading>Location</Heading>
              <DescriptionText>{sellerProfile.address.value}</DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Cell number</Heading>
              <DescriptionText>{sellerProfile.cellNumber}</DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Included with the puppy</Heading>
              <DescriptionText>
                {sellerProfile.includedWithPuppyDescription}
              </DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Health</Heading>
              <DescriptionText>
                {sellerProfile.healthDescription}
              </DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Certifications &amp; Awards</Heading>
              <DescriptionText>
                {sellerProfile.healthGuaranteeDescription}
              </DescriptionText>
            </ProfileSection>
            <ProfileSection>
              <Heading>Pickup &amp; Delivery</Heading>
              <DescriptionText>
                {sellerProfile.deliveryDescription}
              </DescriptionText>
            </ProfileSection>
            <HeaderProfileSection>
              <Heading>Breed(s)</Heading>
              <DescriptionText>
                {sellerProfile.breeds
                  .map(item => {
                    return item?.value;
                  })
                  .join(', ')}
                .
              </DescriptionText>
            </HeaderProfileSection>
            <HeaderProfileSection>
              <Heading>Price Range</Heading>
              <DescriptionText>{`Min : $${sellerProfile.minValue} - Max : $${sellerProfile.maxValue}`}</DescriptionText>
            </HeaderProfileSection>
            <HeaderProfileSection>
              <Heading>Featured Pictures</Heading>
              <GridContainer h={state.images.size > 0 ? 120 : 10}>
                <Carousel
                  ssr={true}
                  keyBoardControl={true}
                  responsive={responsive}>
                  {sellerProfile.images.map((url, index) => {
                    return (
                      <div key={index}>
                        <img src={url} alt="Logo" className={`menu-item`} />
                      </div>
                    );
                  })}
                </Carousel>
              </GridContainer>
            </HeaderProfileSection>
          </DetailsContainer >
        )}
      </Container >
    );
  }

};

const formatSellerProfileNameForAvatar = (
  sellerProfile: ISellerProfileRecord
) => {
  const nameParts = sellerProfile.name.split(' ');
  if (nameParts.length <= 1) {
    return '';
  }
  const firstName = nameParts[0][0];
  const lastName = nameParts[1][0];
  return `${firstName.toLocaleUpperCase()}${lastName.toLocaleUpperCase()}`;
};

const formatCellNumber = (cellNumber: string) => {
  const cellNum = cellNumber.substr(0, 2);
  const number = cellNum === '+1' ? cellNumber : '+1'.concat(cellNumber)
  return number;
}


