"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.acceptedImageUploadTypes = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'heic',
    'heif',
];
