"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
var EFilterCost;
(function (EFilterCost) {
    EFilterCost["low"] = "low";
    EFilterCost["medium"] = "medium";
    EFilterCost["high"] = "high";
    EFilterCost["any"] = "any";
})(EFilterCost = exports.EFilterCost || (exports.EFilterCost = {}));
var EFilterSize;
(function (EFilterSize) {
    EFilterSize["small"] = "small";
    EFilterSize["medium"] = "medium";
    EFilterSize["large"] = "large";
    EFilterSize["extraLarge"] = "extraLarge";
    EFilterSize["any"] = "any";
})(EFilterSize = exports.EFilterSize || (exports.EFilterSize = {}));
var ESortVariant;
(function (ESortVariant) {
    ESortVariant["sortByClosestFirst"] = "sortByClosestFirst";
    ESortVariant["sortByDate"] = "sortByDate";
    ESortVariant["sortByPrice"] = "sortByPrice";
    ESortVariant["sortByLocation"] = "sortByLocation";
})(ESortVariant = exports.ESortVariant || (exports.ESortVariant = {}));
exports.FilterRecord = immutable_1.Record({
    hypoallergenic: false,
    empoweredBreeder: false,
    cost: EFilterCost.any,
    minMaxValue: [0, 10000],
    size: EFilterSize.any,
    breed: [],
    cuddleCompanion: false,
    highEnergy: false,
    kidFriendly: false,
    adaptsToApartments: false,
    sortBy: ESortVariant.sortByDate,
    location: {},
});
