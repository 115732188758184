import { EPetSex, EPetSize } from '@fido/common';
import { Map } from 'immutable';
import { breeds } from './breeds';

const displayNameForSize = (size: EPetSize) => {
  switch (size) {
    case EPetSize.small:
      return 'Small';
    case EPetSize.medium:
      return 'Medium';
    case EPetSize.large:
      return 'Large';
    case EPetSize.extraLarge:
      return 'Extra large';
  }
};

const displayNameForSex = (sex: EPetSex) => {
  switch (sex) {
    case EPetSex.male:
      return 'Male';
    case EPetSex.female:
      return 'Female';
  }
};

export const petSizeSelectOptions = Map(
  [EPetSize.small, EPetSize.medium, EPetSize.large, EPetSize.extraLarge].map(
    s => [
      s,
      {
        label: displayNameForSize(s),
      },
    ]
  )
);

export const petSexSelectOptions = Map(
  [EPetSex.female, EPetSex.male].map(s => [
    s,
    {
      label: displayNameForSex(s),
    },
  ])
);

export const petBreedSelectOptions = Map(
  breeds.map(b => [
    b,
    {
      label: b,
    },
  ])
);
