"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trueBlack = '#000000';
exports.trueWhite = '#ffffff';
exports.transparent = 'transparent';
// Fido theme - primary colors
exports.turq = '#4FD3DB';
exports.seaBlue = '#30668E';
exports.warmPink = '#DB4F6D';
exports.softGray = '#E3E3E3';
// Fido theme - secondary colors
exports.teal = '#01647E';
exports.deepSea = '#112A30';
exports.navy = '#081018';
exports.offWhite = '#FAFAFA';
exports.gray = '#8A9197';
// Fido theme - input colors
exports.inputBackground = '#0A141E';
exports.inputBorder = '#213549';
// Fido theme - review star color
exports.orange = '#F8A654';
// Fido theme - chat colors
exports.chatBubbleBlue = '#1480FF';
exports.chatBubbleGray = '#EAEAEA';
// Fido theme - social colors
exports.facebookBlue = '#4052AB';
exports.twitterBlue = '#3287E1';
