import {
  ISellerBankAccountRecord,
  ISellerProfileRecord,
  fetchSellerProfile,
  SellerPaymentSettingsRecord,
  updateSellerProfile
} from '@fido/common';
import { unit, teal } from '@fido/styles';
import {
  Button,
  Heading,
  InputFormField,
  Text,
  Description,
} from '@fido/web-components';
import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import {
  paymentSettingsReducer,
  PaymentSettingsStateRecord,
  setDepositAmount,
  setPaymentPreference,
} from './paymentSettingsState';
import { baseUrl, stripeKey, domainName } from '../../../../config';

var Loader = require('react-loader');

const Container = styled.div``;
var isMobile = window.matchMedia("only screen and (max-width: 479px)");

const BankInformationHeading = styled.div`
  font-family: 'Red Hat Text',sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 7px;
`;

const AddPaymentMethodButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${unit * 3}px 0;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit * 2}px;
  padding-top: ${unit * 2}px;
  max-width: 500px;
`;

const DepositDescription = styled(Description)`
  margin-top: ${unit * 3}px;
  white-space: normal;
`;

export interface IPaymentSettingsProps {
  sellerId?: string;
  sellerProfile: ISellerProfileRecord;
  sellerBankAccount?: ISellerBankAccountRecord;
  onClickSave(sellerProfile: ISellerProfileRecord): void;
  onClickAddPaymentMethod(): void;
  onClickUpdatePaymentMethod(): void;
  onLinkPlaidAccount(plaidPublicToken: string, plaidAccountID: string): void;
}

export const PaymentSettings = ({
  sellerId,
  sellerProfile,
  sellerBankAccount,
  onClickSave,
  onLinkPlaidAccount,
}: IPaymentSettingsProps) => {
  const [isLoading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(
    paymentSettingsReducer,
    PaymentSettingsStateRecord()
  );
  useEffect(() => {
    async function fetchData() {
      var url = new URLSearchParams(window.location.search);
      var code = url.get('code');
      if (code) {
        setLoading(false)
        createStripeAccount(code)
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(setDepositAmount(sellerProfile.depositAmount.toString()));
    dispatch(setPaymentPreference(sellerProfile.paymentPreference));
  }, [sellerProfile]);

  const [depositAmountSaveButtonLabel, setDepositAmountSaveButtonLabel] = useState("Save");

  const [isAccountAdded, setAccountAdded] = useState(sellerProfile.paymentSettings.stripePaymentAccountID ? true : false)

  const onAddAccount = () => {
    const url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domainName}/app/account/payment/app/account/payment&client_id=${stripeKey}`;
    window.open(url, "_self");
  }

  const createStripeAccount = async (code: string) => {
    const params = {
      authorizationCode: code
    };
    try {
      await fetch(`${baseUrl}/onCreateStripeAccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((jsonResponse) => {
          const accountId = jsonResponse.body.result.stripe_user_id
          if (accountId) {
            updateSellerPaymentSettings(accountId)
          }
        })
        .catch((error) => {
          console.log(error);
        })
    } catch (error) {
    }
  }

  const updateSellerPaymentSettings = async (accountId: string) => {
    if (!sellerId) {
      alert('Something went wrong. Try again.')
      return;
    }
    const fetchResult = await fetchSellerProfile(sellerId);
    const sellerProfile = fetchResult.ok();
    if (!sellerProfile) {
      alert('Something went wrong. Try again.')
      return;
    }
    const paymentSettings = SellerPaymentSettingsRecord({
      stripePaymentAccountID: accountId,
    });
    const updateResult = await updateSellerProfile(
      sellerId,
      sellerProfile.set("paymentSettings", paymentSettings)
    );
    const result = updateResult.ok();
    setLoading(true)
    if (result) {
      setAccountAdded(true)
      alert('Successfully Stripe Account Created.')
      return;
    }
    updateResult.mapError(() => {
      alert('Something went wrong. Try again.')
    });

  }

  const options = {
    lines: 13,
    length: 20,
    width: 5,
    radius: 30,
    scale: 0.5,
    corners: 1,
    color: '#000',
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: isMobile.matches ? '-450%' : '50%',
    left: isMobile.matches ? '50%' : '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute',
  };

  return (
    <Container>
      <Loader loaded={isLoading} options={options} className={"spinner"} />
      <>
        <BankInformationHeading>Bank Information</BankInformationHeading>
        <Text>
          Connect your prefered bank account. Deposits will be securely placed
          in your account once payment is received from buyer.
        </Text>
        <AddPaymentMethodButtonContainer>
          <Button
            variant="primary"
            size="large"
            disabled={isAccountAdded ? true : false}
            onClick={() => {
              onAddAccount();
            }}>
            {isAccountAdded ? `Account Added` : `Add Account`}
          </Button>
        </AddPaymentMethodButtonContainer>
      </>
      <InputFormField
        label="Reservation Fee"
        value={state.depositAmount}
        onChangeValue={value => {
          if (value === sellerProfile.depositAmount.toString()) {
            setDepositAmountSaveButtonLabel('Save');
          } else {
            setDepositAmountSaveButtonLabel('Save New Amount');
          }
          dispatch(setDepositAmount(value));
        }}
      />
      <ButtonContainer>
        <Button
          variant="primary"
          size="large"
          onClick={() => {
            onClickSave(
              sellerProfile
                .set('paymentPreference', state.paymentPreference)
                .set('depositAmount', parseFloat(state.depositAmount))
            );
            setDepositAmountSaveButtonLabel('Saved!');
          }}>
          {depositAmountSaveButtonLabel}
        </Button>
      </ButtonContainer>
      <DepositDescription>
        All Barkd deposits will be securely processed using Stripe’s payment
        processor to move funds to your account. Barkd charges our buyers a
        small service fee that allows us to maintain our platform and services.
      </DepositDescription>
      <DepositDescription>
        At Barkd, the privacy and security of your information are top
        priorities.
      </DepositDescription>
    </Container>
  );
};

const StripeTermsContainer = styled(Description)`
  padding-top: ${unit * 3}px;

  & > a {
    color: ${teal};
  }
`;

const StripeTerms = () => (
  <StripeTermsContainer>
    By registering your account, you agree to our{' '}
    <a href="https://stripe.com/legal">Services Agreement</a> and the{' '}
    <a target="_blank" href="https://stripe.com/connect-account/legal">
      Stripe Connected Account Agreement
    </a>
    .
  </StripeTermsContainer>
);
