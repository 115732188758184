import React from 'react';
import styled, { css } from 'styled-components';
import { unit, trueWhite } from '@fido/styles';
import { desktop } from '@fido/web-components';

const Container = styled.div`
  height: 100%;

  ${desktop(css`
    padding: ${unit * 4}px;
  `)}
`;

const Contents = styled.div`
  border-radius: ${unit * 2}px;
  padding: ${unit}px;
  background-color: ${trueWhite};
  display: flex;
  flex: 1;
  flex-direction: column;

  ${desktop(css`
    padding: ${unit * 4}px;
  `)}
`;

export interface IPageContainerProps {
  children?: React.HTMLProps<HTMLDivElement>['children'];
  className?: string;
  style?: React.HTMLProps<HTMLDivElement>['style'];
}

export const PageContainer = ({
  children,
  className,
  style,
}: IPageContainerProps) => (
  <Container>
    <Contents className={className} style={style}>
      {children}
    </Contents>
  </Container>
);
