"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@fido/common");
const api_1 = require("../api");
const state_1 = require("../state");
exports.fetchPetProfiles = (createdAfter) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loading,
        }));
        const result = yield common_1.fetchPetProfiles(createdAfter);
        result.map(profiles => dispatch(state_1.mergePetProfiles({
            profiles,
        })));
    }
    finally {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loaded,
        }));
    }
});
exports.fetchPetProfilesBySeller = (sellerProfileID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loading,
        }));
        const result = yield common_1.fetchPetProfilesBySeller(sellerProfileID);
        result.map(profiles => dispatch(state_1.mergePetProfiles({
            profiles,
        })));
    }
    finally {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loaded,
        }));
    }
});
exports.fetchPetProfileByID = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loading,
        }));
        const result = yield common_1.fetchPetProfileByID(id);
        result.map(profile => profile &&
            dispatch(state_1.setPetProfile({
                id,
                profile,
            })));
    }
    finally {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loaded,
        }));
    }
});
exports.deletePetProfileByID = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loading,
        }));
        const result = yield common_1.deletePetProfile(id);
        result.map(profile => profile &&
            dispatch(state_1.deletePetProfile({
                id,
            })));
    }
    finally {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loaded,
        }));
    }
});
exports.markPetProfileAsSold = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loading,
        }));
        const result = yield common_1.markPetProfileAsSold(id);
        result.map(profile => profile &&
            dispatch(state_1.deletePetProfile({
                id,
            })));
    }
    finally {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loaded,
        }));
    }
});
exports.deleteFavoriteProfileAsSold = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    yield common_1.removeSoldPetFromFavorites(id);
});
exports.reportProfile = (props) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    yield api_1.reportProfile(props);
});
exports.updatePetProfileViews = (petProfileID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loading,
        }));
        yield common_1.updatePetProfileViews(petProfileID);
    }
    finally {
        dispatch(state_1.setPetProfilesLoadingStatus({
            loadingStatus: state_1.EPetProfilesLoadingStatus.loaded,
        }));
    }
});
exports.updatePetProfile = (id, profile) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.updatePetProfile(id, profile);
    result.map(() => dispatch(state_1.setPetProfile({
        id,
        profile,
    })));
});
exports.createPetProfile = (profile) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.createPetProfile(profile);
    result.map(({ id }) => dispatch(state_1.setPetProfile({
        id,
        profile,
    })));
});
