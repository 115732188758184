import { ApplicationShell, EApplicationRoute } from '@fido/web-components';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  matchPath,
} from 'react-router-dom';
import { BreederManagment } from '../breeder-managment';
import { Account } from '../account';
import { Applications } from '../applications';
import { Messages } from '../messages';
import { Login } from '../login';
import { PetProfile } from '../petProfile';
import { PetProfiles } from '../petProfiles';
import { CreatePetProfile } from '../createPetProfile';
import { CreateApplicantProfile } from '../createApplicantProfile';
import { Application } from '../application';
import { ISellerProfileRecord } from '@fido/common';
import { AuthLoadingStatus } from '@fido/state';
import { CreatePayments } from '../payments';
import { CheckoutPage } from '../checkout';
import { PaymentSuccess } from '../payments/PaymentSuccess';
import { PaymentFailure } from '../payments/PaymentFailure';

export interface IRoutesProps {
  userId?: string,
  isLoggedIn: boolean;
  showMessagesBadge: boolean;
  showApplicationsBadge: boolean;
  authLoadingStatus: AuthLoadingStatus;
  sellerProfile?: ISellerProfileRecord;
  loadInitialState(): Promise<void>;
  loadInitialLoggedInData(): Promise<void>;
  logout(): void;
}

export type IProtectedRouteProps = {
  isLoggedIn: boolean;
  authLoadingStatus: AuthLoadingStatus;
} & RouteProps;

const ProtectedRoute = ({
  isLoggedIn,
  authLoadingStatus,
  children,
  ...etcRouteProps
}: IProtectedRouteProps) => (
  <Route
    {...etcRouteProps}
    render={props => {
      if (authLoadingStatus !== AuthLoadingStatus.loaded) {
        return <div />;
      }
      return isLoggedIn ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const routeForPathname = (pathname: string) => {
  const isActiveRoute = (routePath: string) =>
    !!matchPath(pathname, { path: routePath });
  if (isActiveRoute('/app/account')) {
    return EApplicationRoute.account;
  }
  if (isActiveRoute('/app/applications')) {
    return EApplicationRoute.inbox;
  }
  if (isActiveRoute('/app/create-application')) {
    return EApplicationRoute.applicantProfile;
  }
  if (isActiveRoute('/app/breeder-managment')) {
    return EApplicationRoute.adminPanel;
  }
  if (isActiveRoute('/app/pet-profiles')) {
    return EApplicationRoute.petProfiles;
  }
  return EApplicationRoute.petProfiles;
};

const pathnameForRoute = (route: EApplicationRoute) => {
  switch (route) {
    case EApplicationRoute.account:
      return '/app/account';
    case EApplicationRoute.inbox:
      return '/app/applications';
    case EApplicationRoute.applicantProfile:
      return '/app/create-application';
    case EApplicationRoute.adminPanel:
      return '/app/breeder-managment';
    case EApplicationRoute.petProfiles:
      return '/app/pet-profiles';
    case EApplicationRoute.createPetProfile:
      return '/app/pet-profiles/create';
  }
};

interface ILoggedInRoutesProps {
  userId?: string,
  sellerProfile?: ISellerProfileRecord;
  showMessagesBadge?: boolean;
  showApplicationsBadge?: boolean;
  onClickLogout(): void;
}

const LoggedInRoutes = ({
  userId,
  sellerProfile,
  showMessagesBadge = false,
  showApplicationsBadge = false,
  onClickLogout,
}: ILoggedInRoutesProps) => {
  const [accountMenuExpanded, setAccountMenuExpanded] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  return (
    <ApplicationShell
      userId = {userId}
      route={routeForPathname(pathname)}
      sellerProfile={sellerProfile}
      accountMenuLabel={sellerProfile?.name}
      accountMenuExpanded={accountMenuExpanded}
      showMessagesBadge={showMessagesBadge}
      showApplicationsBadge={showApplicationsBadge}
      onShowAccountMenu={() => setAccountMenuExpanded(true)}
      onHideAccountMenu={() => setAccountMenuExpanded(false)}
      onChangeRoute={route => history.push(pathnameForRoute(route))}
      onClickMessages={() => history.push(`/app/messages`)}
      onClickApplications={() => history.push(`/app/applications`)}
      onClickLogo={() => history.push(`/app`)}
      onClickMyProfile={() => history.push('/app/account/profile')}
      onClickPaymentSettings={() => history.push('/app/account/payment')}
      onClickLogout={onClickLogout}
      onClickContactSupport={() => window.open('mailto:support@barkd.com')}>
      <Switch>
        <Route path={`${path}/messages`}>
          <Messages />
        </Route>
        <Route path={`${path}/applications/:id`}>
          <Application />
        </Route>
        <Route path={`${path}/applications`}>
          <Applications />
        </Route>
        <Route path={`${path}/pet-profiles/create`}>
          <CreatePetProfile />
        </Route>
        <Route path={`${path}/pet-profiles/:id`}>
          <PetProfile />
        </Route>
        <Route path={`${path}/pet-profiles`}>
          <PetProfiles />
        </Route>
        <Route path={`${path}/create-application`}>
          <CreateApplicantProfile />
        </Route>
        <Route path={`${path}/account`}>
          <Account />
        </Route>
        <Route path={`${path}/breeder-managment`}>
          <BreederManagment />
        </Route>
        <Route
          render={props => (
            <Redirect
              to={{
                pathname: `${path}/pet-profiles`,
                state: { from: props.location },
              }}
            />
          )}
        />
      </Switch>
    </ApplicationShell>
  );
};

export const Routes = ({
  userId,
  isLoggedIn,
  showMessagesBadge,
  showApplicationsBadge,
  authLoadingStatus,
  sellerProfile,
  logout,
  loadInitialState,
  loadInitialLoggedInData,
}: IRoutesProps) => {
  useEffect(() => {
    loadInitialState();
  }, []);
  useEffect(() => {
    loadInitialLoggedInData();
  }, [isLoggedIn]);
  !isLoggedIn ? localStorage.setItem('Path', window.location.pathname) : '';
  return (
    <Router>
      <Switch>
        <ProtectedRoute
          authLoadingStatus={authLoadingStatus}
          isLoggedIn={isLoggedIn}
          path="/app">
          <LoggedInRoutes
            userId={userId}
            showMessagesBadge={showMessagesBadge}
            showApplicationsBadge={showApplicationsBadge}
            sellerProfile={sellerProfile}
            onClickLogout={logout}
          />
        </ProtectedRoute>
        <Route path="/paymentSuccess/:isPublicUrl">
          <PaymentSuccess />
        </Route>
        <Route path="/paymentFailure">
          <PaymentFailure />
        </Route>
        <Route path="/">
          <Route
            render={() =>
              window.location.pathname === '/payments' ? (
                <CreatePayments />
              ) : window.location.pathname === '/checkout' ? (
                <CheckoutPage />
              ) : (
                <Login />
              )
            }
          />
        </Route>
      </Switch>
    </Router>
  );
};
