"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const d3_color_1 = require("d3-color");
exports.opacity = (colorSpecifier, opacity) => {
    const clr = d3_color_1.color(colorSpecifier);
    clr.opacity = opacity;
    return clr.toString();
};
exports.brighter = (colorSpecifier, k) => {
    const clr = d3_color_1.color(colorSpecifier);
    return clr.brighter(k).toString();
};
exports.darker = (colorSpecifier, k) => {
    const clr = d3_color_1.color(colorSpecifier);
    return clr.darker(k).toString();
};
exports.colorToRGBA = (colorSpecifier, alpha = 1) => {
    const clr = d3_color_1.color(colorSpecifier);
    if (!clr) {
        return '';
    }
    clr.opacity = alpha;
    return clr.toString();
};
