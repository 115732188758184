"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
exports.RoleRecord = (partial) => {
    var _a, _b, _c, _d, _e, _f;
    return immutable_1.Record({
        userID: ((_a = partial) === null || _a === void 0 ? void 0 : _a.userID) || '',
        currentRole: ((_b = partial) === null || _b === void 0 ? void 0 : _b.currentRole) || '',
        isSubscribedTo: ((_c = partial) === null || _c === void 0 ? void 0 : _c.isSubscribedTo) || [],
        createdAt: ((_d = partial) === null || _d === void 0 ? void 0 : _d.createdAt) || '',
        isBreederApplicationSubmitted: ((_e = partial) === null || _e === void 0 ? void 0 : _e.isBreederApplicationSubmitted) || undefined,
        isBreederApproved: ((_f = partial) === null || _f === void 0 ? void 0 : _f.isBreederApproved) || undefined
    })();
};
