export const breeds = [
  'Afador',
  'Affenpinscher',
  'Afghan Hound',
  'Airedale Terrier',
  'Akbash',
  'Akita',
  'Alaskan Klee Kai',
  'Alaskan Malamute',
  'American Bulldog',
  'American Corgi',
  'American English Coonhound',
  'American Eskimo Dog',
  'American Foxhound',
  'American Leopard Hound',
  'American Pit Bull Terrier',
  'American Pugabull',
  'American Staffordshire Terrier',
  'American Water Spaniel',
  'Anatolian Shepherd Dog',
  'Appenzeller Sennenhunde',
  'Auggie',
  'Aussiedoodle',
  'Aussiepom',
  'Australian Cattle Dog',
  'Australian Kelpie',
  'Australian Labradoodle',
  'Australian Retriever',
  'Australian Shepherd',
  'Australian Shepherd Husky',
  'Australian Shepherd Lab Mix',
  'Australian Shepherd Pit Bull Mix',
  'Australian Terrier',
  'Azawakh',
  'Barbet',
  'Basenji',
  'Bassador',
  'Basset Fauve de Bretagne',
  'Basset Hound',
  'Basset Retriever',
  'Bavarian Mountain Scent Hound',
  'Beabull',
  'Beagle',
  'Beaglier',
  'Bearded Collie',
  'Bedlington Terrier',
  'Belgian Malinois',
  'Belgian Sheepdog',
  'Belgian Tervuren',
  'Berger Picard',
  'Bernedoodle',
  'Bernese Mountain Dog',
  'Bichon Frise',
  'Biewer Terrier',
  'Black and Tan Coonhound',
  'Black Mouth Cur',
  'Black Russian Terrier',
  'Bloodhound',
  'Blue Lacy',
  'Bluetick Coonhound',
  'Bocker',
  'Boerboel',
  'Boglen Terrier',
  'Bolognese',
  'Borador',
  'Border Collie',
  'Border Sheepdog',
  'Border Terrier',
  'Bordoodle',
  'Borzoi',
  'BoShih',
  'Bossie',
  'Boston Boxer',
  'Boston Terrier',
  'Bouvier des Flandres',
  'Boxador',
  'Boxer',
  'Boxerdoodle',
  'Boxmatian',
  'Boxweiler',
  'Boykin Spaniel',
  'Bracco Italiano',
  'Braque du Bourbonnais',
  'Briard',
  'Brittany',
  'Broholmer',
  'Brussels Griffon',
  'Bugg',
  'Bull Terrier',
  'Bullador',
  'Bullboxer Pit',
  'Bulldog',
  'Bullmastiff',
  'Bullmatian',
  'Cairn Terrier',
  'Canaan Dog',
  'Cane Corso',
  'Cardigan Welsh Corgi',
  'Catahoula Bulldog',
  'Catahoula Leopard Dog',
  'Caucasian Shepherd Dog',
  'Cav-a-Jack',
  'Cavachon',
  'Cavador',
  'Cavalier King Charles Spaniel',
  'Cavapoo',
  'Cesky Terrier',
  'Chabrador',
  'Cheagle',
  'Chesapeake Bay Retriever',
  'Chi Chi',
  'Chi-Poo',
  'Chigi',
  'Chihuahua',
  'Chilier',
  'Chinese Crested',
  'Chinese Shar-Pei',
  'Chinook',
  'Chion',
  'Chipin',
  'Chiweenie',
  'Chorkie',
  'Chow Chow',
  'Chow Shepherd',
  'Chug',
  'Chusky',
  'Cirneco dell’Etna',
  'Clumber Spaniel',
  'Cockalier',
  'Cockapoo',
  'Cocker Spaniel',
  'Collie',
  'Corgi Inu',
  'Corgidor',
  'Corman Shepherd',
  'Coton de Tulear',
  'Curly-Coated Retriever',
  'Dachsador',
  'Dachshund',
  'Dalmatian',
  'Dandie Dinmont Terrier',
  'Daniff',
  'Deutscher Wachtelhund',
  'Doberdor',
  'Doberman Pinscher',
  'Doberdane',
  'Docker',
  'Dogo Argentino',
  'Dogue de Bordeaux',
  'Dorgi',
  'Dorkie',
  'Doxiepoo',
  'Doxle',
  'Drentsche Patrijshond',
  'Drever',
  'Dutch Shepherd',
  'English Bulldog',
  'English Cocker Spaniel',
  'English Foxhound',
  'English Setter',
  'English Springer Spaniel',
  'English Toy Spaniel',
  'Entlebucher Mountain Dog',
  'Estrela Mountain Dog',
  'Eurasier',
  'Field Spaniel',
  'Finnish Lapphund',
  'Finnish Spitz',
  'Flat-Coated Retriever',
  'Fox Terrier',
  'French Bulldog',
  'French Spaniel',
  'Frenchton',
  'Frengle',
  'German Pinscher',
  'German Shepherd Dog',
  'German Sheprador',
  'German Shorthaired Pointer',
  'German Spitz',
  'German Wirehaired Pointer',
  'Giant Schnauzer',
  'Glen of Imaal Terrier',
  'Goberian',
  'Goldador',
  'Golden Cocker Retriever',
  'Golden Mountain Dog',
  'Golden Retriever',
  'Golden Retriever Corgi',
  'Golden Shepherd',
  'Goldendoodle',
  'Gollie',
  'Gordon Setter',
  'Great Dane',
  'Great Pyrenees',
  'Greater Swiss Mountain Dog',
  'Greyhound',
  'Hamiltonstovare',
  'Hanoverian Scenthound',
  'Harrier',
  'Havanese',
  'Hokkaido',
  'Horgi',
  'Huskita',
  'Huskydoodle',
  'Ibizan Hound',
  'Icelandic Sheepdog',
  'Irish Doodle',
  'Irish Red and White Setter',
  'Irish Setter',
  'Irish Terrier',
  'Irish Water Spaniel',
  'Irish Wolfhound',
  'Italian Greyhound',
  'Jack-A-Poo',
  'Jack Chi',
  'Jack Russell Terrier',
  'Japanese Chin',
  'Japanese Spitz',
  'Korean Jindo Dog',
  'Karelian Bear Dog',
  'Keeshond',
  'Kerry Blue Terrier',
  'Komondor',
  'Kooikerhondje',
  'Kuvasz',
  'Kyi-Leo',
  'Lab Pointer',
  'Labernese',
  'Labmaraner',
  'Labrabull',
  'Labradane',
  'Labradoodle',
  'Labrador Retriever',
  'Labrastaff',
  'Labsky',
  'Lagotto Romagnolo',
  'Lakeland Terrier',
  'Lancashire Heeler',
  'Leonberger',
  'Lhasa Apso',
  'Lhasapoo',
  'Lowchen',
  'Maltese',
  'Maltese Shih Tzu',
  'Maltipoo',
  'Manchester Terrier',
  'Maremma Sheepdog',
  'Mastador',
  'Mastiff',
  'Miniature American Shepherd',
  'Miniature Australian Shepherd',
  'Miniature Goldendoodle',
  'Medium Goldendoodle',
  'Miniature Pinscher',
  'Miniature Poodle',
  'Miniature Schnauzer',
  'Morkie',
  'Mudi',
  'Mutt',
  'Neapolitan Mastiff',
  'Newfoundland',
  'Norfolk Terrier',
  'Norwegian Buhund',
  'Norwegian Elkhound',
  'Norwegian Lundehund',
  'Norwich Terrier',
  'Nova Scotia Duck Tolling Retriever',
  'Old English Bulldogge',
  'Old English Sheepdog',
  'Otterhound',
  'Papillon',
  'Papipoo',
  'Peekapoo',
  'Pekingese',
  'Pembroke Welsh Corgi',
  'Petit Basset Griffon Vendeen',
  'Pharaoh Hound',
  'Pitsky',
  'Plott',
  'Pocket Beagle',
  'Pointer',
  'Polish Lowland Sheepdog',
  'Pomapoo',
  'Pomchi',
  'Pomeagle',
  'Pomeranian',
  'Pomsky',
  'Poochon',
  'Poodle',
  'Portidoodle',
  'Portuguese Podengo Pequeno',
  'Portuguese Water Dog',
  'Pug',
  'Pugalier',
  'Puggle',
  'Puli',
  'Pyrenean Shepherd',
  'Rat Terrier',
  'Redbone Coonhound',
  'Rhodesian Ridgeback',
  'Rottador',
  'Rottle',
  'Rottweiler',
  'Saint Berdoodle',
  'Saint Bernard',
  'Saluki',
  'Samoyed',
  'Samusky',
  'Schipperke',
  'Schnoodle',
  'Schapendoes',
  'Scottish Deerhound',
  'Scottish Terrier',
  'Sealyham Terrier',
  'Sheepadoodle',
  'Shepsky',
  'Shetland Sheepdog',
  'Shiba Inu',
  'Shichon',
  'Shih-Poo',
  'Shih Tzu',
  'Shiranian',
  'Shollie',
  'Shorkie',
  'Siberian Husky',
  'Silken Windhound',
  'Silky Terrier',
  'Skye Terrier',
  'Sloughi',
  'Small Munsterlander Pointer',
  'Soft Coated Wheaten Terrier',
  'Spanish Mastiff',
  'Spanish Water Dog',
  'Springador',
  'Stabyhoun',
  'Staffordshire Bull Terrier',
  'Standard Schnauzer',
  'Sussex Spaniel',
  'Swedish Vallhund',
  'Terripoo',
  'Tibetan Mastiff',
  'Tibetan Spaniel',
  'Tibetan Terrier',
  'Toy Fox Terrier',
  'Toy Australian Shepherd',
  'Toy Poodle',
  'Treeing Tennessee Brindle',
  'Treeing Walker Coonhound',
  'Valley Bulldog',
  'Vizsla',
  'Weimaraner',
  'Welsh Springer Spaniel',
  'Welsh Terrier',
  'West Highland White Terrier',
  'Westiepoo',
  'Whippet',
  'Whoodle',
  'Wirehaired Pointing Griffon',
  'Xoloitzcuintli',
  'Yorkipoo',
  'Yorkshire Terrier',
];