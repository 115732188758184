import { unit } from '@fido/styles';
import {
  Button,
  InputFormField,
  Heading,
  Modal,
} from '@fido/web-components';
import noop from 'lodash/noop';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  & > span:last-child {
    margin-top: ${unit * 2}px;
  }
`;

const Form = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${unit * 3}px 0;
  & > div {
    padding: ${unit * 2}px 0;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit}px;
`;


export interface IUpdateEmailModalProps {
  visible?: boolean;
  email?: string;
  onClickCancel?(): void;
  onClickSave?(currentEmail: string, updateEmail: string): void;
}

export const UpdateEmailModal = ({
  visible = false,
  email ='',
  onClickCancel = noop,
  onClickSave = noop,
}: IUpdateEmailModalProps) => {

  const [updatedEmail, setUpdatedEmail] =  useState('')
  return (
    <Modal visible={visible}>
      <ModalContents>
        <Header>
          <Heading>Update Email Address</Heading>
        </Header>
        <Form>
          <InputFormField
            label="Current email address"
            value={email}
          />
          <InputFormField
            label="Enter new email address"
            value={updatedEmail}
            onChangeValue={email => setUpdatedEmail(email)}
          />
        </Form>
        <ButtonsContainer>
          <Button size="large" variant="default" onClick={onClickCancel}>
            Cancel
          </Button>
          <Button
            size="large"
            variant="primary"
            onClick={() => onClickSave(
              email, 
              updatedEmail
            )}>
            Save
          </Button>
        </ButtonsContainer>
      </ModalContents>
    </Modal>
  );
};
