import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableRows,
  Heading,
  TableHeader,
  isDesktop,
  TableHeaderColumn,
} from '@fido/web-components';
import styled from 'styled-components';
import { unit, twitterBlue } from '@fido/styles';
import {
  IUserProfileRecord,
  ISellerProfileRecord,
  firestore,
  fetchRole,
  updateRole
} from '@fido/common';
import {
  IRoleRecord
} from '@fido/state'
import { Map } from 'immutable';
import { PageContainer } from '../common';
import { UpdateEmailModal } from './components/UpdateEmailModal';
import { baseUrl } from '../../config'
var Loader = require('react-loader');

var isMobile = window.matchMedia("only screen and (max-width: 479px)");

const TableContainer = styled.div``;
const ApplicantsHeading = styled(Heading)`
  margin-bottom: ${unit * 3}px;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
const ItemContainer = styled.div`
  margin: 10px 0px
`;
const Cell = styled.label`
  font-size: 17px;
  display: inline-block;
  padding: 0px 10px;
`;
const CheckBoxContainer = styled.label`
  margin: 0px;
`;
const CheckBox = styled.input`
  margin-right: 3px;
`;
const SaveButton = styled.button`
  height: 25px;
  width: 100px;
  margin-top: 5px;
  margin-left: 10px;
`;
const Row = styled(TableRow)`
  max-height: 150px;
  padding: ${unit * 1}px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 5px;
  border-radius: 0px;
  border-bottom: 0.5px solid #D3D3D3;
`;
const MobileTableRow = styled(TableRow)`
  grid-template-columns: repeat(1, 1fr);
  border: #dfe3e8;
  outline: 0;
  margin-top: 1.5rem;
  width: 95%;
  margin-right: 1rem;
  margin-left: 10px;
  padding: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 0.3rem rgb(0 0 0 / 30%);
  background: #fff;
`;
const Line = styled.div`
  border-top: 1px solid #dfe3e8;
`;
const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const OverLayView = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255,255,255,0.5);
`;
const ModalView = styled.div`
  position: absolute;
  background: white;
  border: 1px solid black;
  padding: 10px;
  width: calc(100vh - 2rem);
  max-height: 70vh;
  overflow: auto;
`;
const MobileModalView = styled.div`
position: absolute;
background: white;
border: 1px solid black;
padding: 10px;
width: 80%;
max-height: 70vh;
overflow: auto;
`;
const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;
const LabelStyle = styled.p`
  font-weight: bold;
`;

interface Profile {
  id: string;
  empoweredBreederEnabled: boolean;
  serviceDogsEnabled: boolean;
  hideBreeder: boolean;
};

export interface IAdminProps {
  userID?: string;
  userProfile?: IUserProfileRecord;
  sellerProfile?: ISellerProfileRecord;
  sellerProfiles: Map<string, ISellerProfileRecord>;
  loadInitialData(): void;
  updateSellerProfile(sellerProfile: ISellerProfileRecord): void;
}

export const BreederManagment = ({
  userID,
  userProfile,
  sellerProfile,
  sellerProfiles,
  loadInitialData,
  updateSellerProfile,
}: IAdminProps) => {

  let profiles: Profile[] = []
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState('');
  const [usrID, setUsrID] = useState('');
  const [showEmailUpdateModal, setShowEmailUpdateModal] = useState(false);

  const [info, setInfo] = useState(null);
  const [userRole, setUserRole] = useState<Partial<IRoleRecord>>({});
  const [sellerID, setSellerID] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    getCurrentUserRole()
  }, [sellerID]);

  const getCurrentUserRole = async () => {
    const res = await fetchRole(sellerID);

    await res.mapAsync(async role => {

      let cRole = {
        isSubscribedTo: role.isSubscribedTo,
        userID: role.userID,
        currentRole: role.currentRole,
        createdAt: role.createdAt,
        isBreederApplicationSubmitted: role.isBreederApplicationSubmitted,
        isBreederApproved: role.isBreederApproved,
      } as IRoleRecord;

      setUserRole(cRole);
    })
  }

  const onClickSave = async () => {
    if (profiles.length === 0) {
      return;
    }
    setDisable(true)
    profiles.map(async (profile) => {
      try {
        await firestore
          .collection('seller-profiles')
          .doc(profile.id)
          .update({
            empoweredBreederEnabled: profile.empoweredBreederEnabled,
            serviceDogsEnabled: profile.serviceDogsEnabled,
            hideBreeder: profile.hideBreeder
          });
      } catch (error) {
        setDisable(false)
      }
    })
    profiles = []
    setDisable(false)
    loadInitialData();
  }

  const onChangeEmpoweredBreeder = (
    event: any,
    id: string,
    sProfile: ISellerProfileRecord) => {
    const target = event.target;
    let profile = profiles.find(x => x.id === id);
    if (profile) {
      profile.empoweredBreederEnabled =
        target.type === 'checkbox' ?
          target.checked :
          target.value
      profile.serviceDogsEnabled = profile.serviceDogsEnabled ? profile.serviceDogsEnabled : false,
        profile.hideBreeder = profile.hideBreeder ? profile.hideBreeder : false
    } else {
      profiles.push({
        id: id,
        empoweredBreederEnabled:
          target.type === 'checkbox' ?
            target.checked : target.value,
        serviceDogsEnabled:
          sProfile && sProfile.serviceDogsEnabled ?
            sProfile.serviceDogsEnabled :
            false,
        hideBreeder:
          sProfile && sProfile.hideBreeder ?
            sProfile.hideBreeder : false
      })
    }
  }

  const onChangeServices = (
    event: any,
    id: string,
    sProfile: ISellerProfileRecord) => {

    const target = event.target;
    let profile = profiles.find(x => x.id === id);
    if (profile) {
      profile.empoweredBreederEnabled = profile.empoweredBreederEnabled ? profile.empoweredBreederEnabled : false
      profile.serviceDogsEnabled = target.type === 'checkbox' ?
        target.checked :
        target.value,
        profile.hideBreeder = profile.hideBreeder ? profile.hideBreeder : false
    } else {
      profiles.push({
        id: id,
        empoweredBreederEnabled:
          sProfile && sProfile.empoweredBreederEnabled ?
            sProfile.empoweredBreederEnabled : false,
        serviceDogsEnabled: target.type === 'checkbox' ?
          target.checked :
          target.value,
        hideBreeder:
          sProfile && sProfile.hideBreeder ?
            sProfile.hideBreeder : false
      })
    }
  }

  const onHideBreeder = (
    event: any,
    id: string,
    sProfile: ISellerProfileRecord) => {

    const target = event.target;
    let profile = profiles.find(x => x.id === id);
    if (profile) {
      profile.empoweredBreederEnabled = profile.empoweredBreederEnabled ? profile.empoweredBreederEnabled : false
      profile.serviceDogsEnabled = profile.serviceDogsEnabled ? profile.serviceDogsEnabled : false
      profile.hideBreeder = target.type === 'checkbox' ?
        target.checked :
        target.value
    } else {
      profiles.push({
        id: id,
        empoweredBreederEnabled:
          sProfile && sProfile.empoweredBreederEnabled ?
            sProfile.empoweredBreederEnabled : false,
        serviceDogsEnabled:
          sProfile && sProfile.serviceDogsEnabled ?
            sProfile.serviceDogsEnabled : false,
        hideBreeder:
          target.type === 'checkbox' ?
            target.checked :
            target.value,
      })
    }
  }

  const fetchUsrEmail = async (usrID: string) => {
    setLoading(false)
    setUsrID(usrID)
    const params = {
      uid: usrID,
    };
    try {
      await fetch(
        `${baseUrl}/fetchEmailAddress`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      )
        .then(response => response.json())
        .then(json => {
          setEmail(json.body.user.email);
          setShowEmailUpdateModal(true);
          setLoading(true)
        })
        .catch(error => console.error(error));
    } catch (error) { }
  };

  const updateUsrEmail = async (email: string) => {
    const params = {
      email: email,
      uid: usrID
    };
    try {
      await fetch(
        `${baseUrl}/updateEmailAddress`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      )
        .then(response => response.json())
        .then(json => {
          alert(json.return.message)
          setShowEmailUpdateModal(false);
        })
        .catch(error => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  const filteredSellerProfiles = sellerProfiles
    .filter((sellerProfile, sellerID) => sellerID !== userID).sortBy(item => item.createdAt).reverse();

  const options = {
    lines: 13,
    length: 20,
    width: 5,
    radius: 30,
    scale: 0.5,
    corners: 1,
    color: twitterBlue,
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: isMobile.matches ? '-450%' : '50%',
    left: isMobile.matches ? '50%' : '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute',
  };

  const updateBreederRole = async (userRole: IRoleRecord) => {
    let cRole = {
      isSubscribedTo: !userRole.isBreederApproved ? ["Breeder", "Buyer"] : ["Buyer"],
      userID: userRole.userID,
      currentRole: !userRole.isBreederApproved ? "Breeder" : "Buyer",
      createdAt: userRole.createdAt,
      isBreederApplicationSubmitted: !userRole.isBreederApproved,
      isBreederApproved: !userRole.isBreederApproved
    } as IRoleRecord;

    setUserRole(cRole);
    setShowModal(false);

    updateRole(userRole.userID, cRole)
  }

  if (isDesktop()) {
    return (
      <PageContainer>
        <Loader loaded={loading} options={options} className={"spinner"} />
        <Container>
          <UpdateEmailModal
            visible={showEmailUpdateModal}
            email={email}
            onClickSave={async (currentEmail, updateEmail) => {
              updateUsrEmail(updateEmail);
            }}
            onClickCancel={() => setShowEmailUpdateModal(false)}
          />
          <ApplicantsHeading>Breeder Management</ApplicantsHeading>
          <SaveButton disabled={disable} onClick={onClickSave}>
            {disable ? 'Saving..' : 'Save'}
          </SaveButton>
        </Container>

        {showModal ?
          <ModalContainer>
            <OverLayView />
            <ModalView>
              <ModalContents>
                <Heading style={{ textAlign: 'center' }} >Breeder Details</Heading>
                {info ?
                  <>
                    <ItemContainer>
                      <LabelStyle>Name</LabelStyle>
                      <Cell>{info?.name}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Website</LabelStyle>
                      <Cell>{info?.website}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Address</LabelStyle>
                      <Cell>{info?.address.label}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Cell Number</LabelStyle>
                      <Cell>{info?.cellNumber}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Breeder</LabelStyle>
                      <div style={{ flexDirection: "row" }} >
                        {info?.breeds.map((item, index) => (
                          <Cell>{item.label}</Cell>
                        ))
                        }
                      </div>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Min Price</LabelStyle>
                      <Cell>{info?.minValue}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Max Price</LabelStyle>
                      <Cell>{info?.maxValue}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Description</LabelStyle>
                      <Cell>{info?.description}</Cell>
                    </ItemContainer>

                    <Button onClick={() => updateBreederRole(userRole)} >
                      {userRole && userRole.isBreederApproved ? "Reject Application" : "Approve Application"}
                    </Button>

                  </>
                  : null
                }
                <Button onClick={() => setShowModal(false)} style={{ marginTop: 10 }} >
                  Cancel
                </Button>
              </ModalContents>

            </ModalView>
          </ModalContainer>
          : null
        }

        <Table>
          <TableHeader>
            <TableHeaderColumn>Names</TableHeaderColumn>
            <TableHeaderColumn>Empowered Breeders</TableHeaderColumn>
            <TableHeaderColumn>Service Dogs</TableHeaderColumn>
            <TableHeaderColumn>Hide/Show</TableHeaderColumn>
            <TableHeaderColumn>Change Email</TableHeaderColumn>
            <TableHeaderColumn>Details</TableHeaderColumn>
          </TableHeader>
          <TableRows>
            {filteredSellerProfiles
              .map((sProfile, sellerID) => {
                return (
                  <Row key={sellerID}>
                    <Cell>
                      {sProfile && sProfile.name ? sProfile.name : '---'}
                    </Cell>
                    <Cell style={{ marginLeft: 20 }}>
                      <CheckBoxContainer>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={
                            sProfile && sProfile.empoweredBreederEnabled
                              ? sProfile.empoweredBreederEnabled
                              : false
                          }
                          onChange={() =>
                            onChangeEmpoweredBreeder(event, sellerID, sProfile)
                          }
                        />
                        Empowered Breeder
                      </CheckBoxContainer>
                    </Cell>
                    <Cell>
                      <CheckBoxContainer>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={
                            sProfile && sProfile.serviceDogsEnabled
                              ? sProfile.serviceDogsEnabled
                              : false
                          }
                          onChange={() =>
                            onChangeServices(event, sellerID, sProfile)
                          }
                        />
                        Service Dogs
                      </CheckBoxContainer>
                    </Cell>
                    <Cell>
                      <CheckBoxContainer>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={
                            sProfile && sProfile.hideBreeder
                              ? sProfile.hideBreeder
                              : false
                          }
                          onChange={() =>
                            onHideBreeder(event, sellerID, sProfile)
                          }
                        />
                        Hide Breeder
                      </CheckBoxContainer>
                    </Cell>
                    <Cell>
                      <SaveButton onClick={() => fetchUsrEmail(sellerID)}>
                        Change Email
                      </SaveButton>
                    </Cell>
                    <Cell>
                      <SaveButton onClick={() => {
                        setInfo(sProfile)
                        setSellerID(sellerID)
                        setShowModal(true)
                      }}
                      >
                        Details
                      </SaveButton>
                    </Cell>
                  </Row>
                );
              })
              .valueSeq()
              .toArray()}
          </TableRows>
        </Table>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer>
        <Container>
          <ApplicantsHeading>Breeder Management</ApplicantsHeading>
          <SaveButton disabled={disable} onClick={onClickSave}>
            {disable ? 'Saving..' : 'Save'}
          </SaveButton>
        </Container>

        {showModal ?
          <ModalContainer>
            <OverLayView />
            <ModalView>
              <ModalContents>
                <Heading style={{ textAlign: 'center' }} >Breeder Details</Heading>
                {info ?
                  <>
                    <ItemContainer>
                      <LabelStyle>Name</LabelStyle>
                      <Cell>{info?.name}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Address</LabelStyle>
                      <Cell>{info?.address.label}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Cell Number</LabelStyle>
                      <Cell>{info?.cellNumber}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Min Price</LabelStyle>
                      <Cell>{info?.minValue}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Max Price</LabelStyle>
                      <Cell>{info?.maxValue}</Cell>
                    </ItemContainer>
                    <ItemContainer>
                      <LabelStyle>Description</LabelStyle>
                      <Cell>{info?.description}</Cell>
                    </ItemContainer>

                    <Button onClick={() => updateBreederRole(userRole)} >
                      {userRole && userRole.isBreederApproved ? "Reject" : "Approve"}
                    </Button>

                  </>
                  : null
                }
                <Button onClick={() => setShowModal(false)} style={{ marginTop: 10 }} >
                  Cancel
                </Button>
              </ModalContents>

            </ModalView>
          </ModalContainer>
          : null
        }

        <TableContainer>
          <Table>
            <TableRows>
              {filteredSellerProfiles.map((sProfile, sellerID) => {
                return (
                  <MobileTableRow key={sellerID}>
                    <TableCell>
                      <TableHeaderColumn>Name</TableHeaderColumn>
                      {sProfile && sProfile.name ? sProfile.name : '---'}
                    </TableCell>
                    <Line />
                    <TableCell>
                      <CheckBoxContainer>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={
                            sProfile && sProfile.empoweredBreederEnabled
                              ? sProfile.empoweredBreederEnabled
                              : false
                          }
                          onChange={() =>
                            onChangeEmpoweredBreeder(event, sellerID, sProfile)
                          }
                        />
                        Empowered Breeder
                      </CheckBoxContainer>
                    </TableCell>
                    <Line />
                    <TableCell>
                      <CheckBoxContainer>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={
                            sProfile && sProfile.serviceDogsEnabled
                              ? sProfile.serviceDogsEnabled
                              : false
                          }
                          onChange={() =>
                            onChangeServices(event, sellerID, sProfile)
                          }
                        />
                        Service Dogs
                      </CheckBoxContainer>
                    </TableCell>
                    <Line />
                    <TableCell>
                      <CheckBoxContainer>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={
                            sProfile && sProfile.hideBreeder
                              ? sProfile.hideBreeder
                              : false
                          }
                          onChange={() =>
                            onHideBreeder(event, sellerID, sProfile)
                          }
                        />
                        Hide Breeder
                      </CheckBoxContainer>
                    </TableCell>
                    <Line />
                    <TableCell>
                      <CheckBoxContainer>
                        <SaveButton onClick={() => fetchUsrEmail(sellerID)}>
                          Change Email
                        </SaveButton>
                      </CheckBoxContainer>
                    </TableCell>
                    <Line />
                    <TableCell>
                      <CheckBoxContainer>
                        <SaveButton onClick={() => {
                          setInfo(sProfile)
                          setSellerID(sellerID)
                          setShowModal(true)
                        }}>
                          Details
                        </SaveButton>
                      </CheckBoxContainer>
                    </TableCell>
                  </MobileTableRow>
                );
              })
                .valueSeq()
                .toArray()}
            </TableRows>
          </Table>
        </TableContainer>
      </PageContainer>
    )
  }
};
