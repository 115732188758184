import { unit } from '@fido/styles';
import {
  Button,
  InputFormField,
  Heading,
  Modal,
  Text,
} from '@fido/web-components';
import noop from 'lodash/noop';
import React, { useReducer } from 'react';
import styled from 'styled-components';
import {
  paymentMethodModalReducer,
  PaymentMethodModalStateRecord,
  setAccountNumber,
  setRoutingNumber,
  setSSNLast4,
} from './paymentMethodModalState';

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > span:last-child {
    margin-top: ${unit * 2}px;
  }
`;

const Form = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${unit * 2}px 0;

  & > div {
    padding: ${unit * 2}px 0;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit}px;
`;

export interface IAddPaymentMethodModalProps {
  visible?: boolean;
  onClickCancel?(): void;
  onClickSave?(paymentMethodDetails: {
    ssnLast4: string;
    routingNumber: string;
    accountNumber: string;
  }): void;
}

export const AddPaymentMethodModal = ({
  visible = false,
  onClickCancel = noop,
  onClickSave = noop,
}: IAddPaymentMethodModalProps) => {
  const [state, dispatch] = useReducer(
    paymentMethodModalReducer,
    PaymentMethodModalStateRecord()
  );
  return (
    <Modal visible={visible}>
      <ModalContents>
        <Header>
          <Heading>Add Payment Method</Heading>
          <Text>Fill out the form below to collect payments.</Text>
        </Header>
        <Form>
          <InputFormField
            label="SSN (last 4 digits)"
            value={state.ssnLast4}
            onChangeValue={ssn => dispatch(setSSNLast4(ssn))}
          />
          <InputFormField
            label="Routing Number"
            value={state.routingNumber}
            onChangeValue={routingNumber =>
              dispatch(setRoutingNumber(routingNumber))
            }
          />
          <InputFormField
            label="Account Number"
            value={state.accountNumber}
            onChangeValue={accountNumber =>
              dispatch(setAccountNumber(accountNumber))
            }
          />
        </Form>
        <ButtonsContainer>
          <Button size="large" variant="default" onClick={onClickCancel}>
            Cancel
          </Button>
          <Button
            size="large"
            variant="primary"
            onClick={() => onClickSave(state)}>
            Save
          </Button>
        </ButtonsContainer>
      </ModalContents>
    </Modal>
  );
};
