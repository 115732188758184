"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
var RoleStatus;
(function (RoleStatus) {
    RoleStatus["Breeder"] = "Breeder";
    RoleStatus["Buyer"] = "Buyer";
})(RoleStatus = exports.RoleStatus || (exports.RoleStatus = {}));
exports.RoleRecord = immutable_1.Record({
    userID: '',
    currentRole: '',
    createdAt: '',
    isSubscribedTo: [],
    isBreederApplicationSubmitted: undefined,
    isBreederApproved: undefined,
});
exports.RolesStateRecord = (partial) => {
    var _a;
    return immutable_1.Record({
        roles: immutable_1.Map(((_a = partial) === null || _a === void 0 ? void 0 : _a.roles) || []),
    })();
};
