import {
  fetchApplicationsByPetProfile,
  fetchUserProfileByID,
  selectApplications,
  selectPetProfiles,
  selectUserID,
  selectUserProfiles,
  fetchPetProfilesBySeller,
  updateApplication,
  IApplicationRecord,
  updateApplicationSellerReadStatus,
  EApplicationReadStatus,
  selectCurrentSellerProfile
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { IApplicationProps } from '../Application';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const createPetProfilesBySellerSelector = (sellerID: string) =>
  createSelector(selectPetProfiles, profiles =>
    profiles.filter(profile => profile.sellerProfileID === sellerID)
  );

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch, getState: () => IState) => async () => {
    if (!userID) {
      return;
    }
    await dispatch(fetchPetProfilesBySeller(userID));
    const petProfiles = createPetProfilesBySellerSelector(userID)(getState());
    await Promise.all(
      petProfiles
        .map((p, id) => dispatch(fetchApplicationsByPetProfile(id)))
        .valueSeq()
        .toArray()
    );
    const applications = selectApplications(getState());
    applications.forEach(app => dispatch(fetchUserProfileByID(app.userID)));
  }
);

const selectOnUpdateApplication = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => (
    applicationID: string,
    application: IApplicationRecord
  ) => {
    dispatch(updateApplication(applicationID, application));
  }
);

export const selectApplicationProps = (dispatch: Dispatch) => (
  state: IState
): IApplicationProps => ({
  applications: selectApplications(state),
  loadInitialData: dispatch(selectLoadInitialData(state)),
  petProfiles: selectPetProfiles(state),
  userProfiles: selectUserProfiles(state),
  sellerProfile: selectCurrentSellerProfile(state),
  onUpdateApplication: dispatch(selectOnUpdateApplication(state)),
  markApplicationAsRead: (applicationID: string) =>
    dispatch(
      updateApplicationSellerReadStatus(
        applicationID,
        EApplicationReadStatus.read
      )
    ),
});
