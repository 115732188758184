"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("../api");
const common_1 = require("@fido/common");
const state_1 = require("../state");
exports.fetchRole = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const userID = state_1.selectUserID(getState());
    if (!userID) {
        return;
    }
    dispatch(exports.fetchRoleByID(userID));
});
exports.fetchRoleByID = (userID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchRole(userID);
    result.map(role => dispatch(state_1.setRole({
        id: userID,
        role,
    })));
});
exports.updateRole = (userID, role) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.updateRole(userID, role);
    result.map(() => dispatch(state_1.setRole({
        id: userID,
        role,
    })));
});
exports.createRole = (role) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.createRole(role);
    result.map(({ id }) => dispatch(state_1.setRole({
        id,
        role,
    })));
});
