import { createPetProfile, selectUserID, selectCurrentSellerProfile } from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { ICreatePetProfileProps } from '../CreatePetProfile';
import {
  PetProfileRecord,
  IPetProfileRecord,
} from '@fido/common';
import moment from 'moment';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectOnSavePetProfile = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => (
    partialProfile: Partial<IPetProfileRecord>
  ) => {
    if (!userID) {
      return;
    }
    dispatch(
      createPetProfile(
        PetProfileRecord({
          dob: partialProfile.dob,
          readyDate: partialProfile.readyDate,
          sex: partialProfile.sex,
          description: partialProfile.description,
          createdAt: moment().format(),
          breed: partialProfile.breed,
          name: partialProfile.name,
          price: partialProfile.price,
          sellerProfileID: userID,
          images: partialProfile.images,
          details: partialProfile.details,
          size: partialProfile.size,
          hypoallergenic: partialProfile.hypoallergenic,
          variant: partialProfile.variant,
          sizeOfLitter: partialProfile.sizeOfLitter,
          born: partialProfile.born,
          goHomeDate: partialProfile.goHomeDate,
        })
      )
    );
  }
);

export const selectCreatePetProfileProps = (dispatch: Dispatch) => (
  state: IState
): ICreatePetProfileProps => ({
  userID: selectUserID(state),
  sellerProfile: selectCurrentSellerProfile(state),
  onSavePetProfile: dispatch(selectOnSavePetProfile(state)),
});
