import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export interface IStripeProviderProps {
  children?: React.ReactElement;
}

export const StripeProvider = ({ children }: IStripeProviderProps) => {
  const stripe = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  return <Elements stripe={stripe}>{children}</Elements>;
};
