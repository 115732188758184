"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@fido/common");
const immutable_1 = require("immutable");
const state_1 = require("../state");
exports.fetchReviews = (petProfileID) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield common_1.firestore
            .collection('reviews')
            .where('petProfileID', '==', petProfileID)
            .orderBy('createdAt', 'asc')
            .get();
        const entries = result.docs.map((doc) => {
            const data = doc.data();
            return [
                doc.id,
                state_1.ReviewRecord({
                    userID: data.userID,
                    petProfileID: data.petProfileID,
                    sellerProfileID: data.sellerProfileID,
                    rating: data.rating,
                    title: data.title,
                    body: data.body,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt,
                }),
            ];
        });
        return new common_1.Ok(immutable_1.Map(entries));
    }
    catch (error) {
        return new common_1.Err(error);
    }
});
