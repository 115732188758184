"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
exports.ReviewRecord = immutable_1.Record({
    petProfileID: '',
    userID: '',
    sellerProfileID: '',
    title: '',
    body: '',
    rating: 0,
    createdAt: '',
    updatedAt: '',
});
exports.ReviewStateRecord = (partial) => {
    var _a;
    return immutable_1.Record({
        reviews: immutable_1.Map(((_a = partial) === null || _a === void 0 ? void 0 : _a.reviews) || []),
    })();
};
