import React, { useState } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import {
  LoginShell,
  CreateWithEmailForm,
  LoginWithEmailForm,
  SelectLoginTypeForm,
} from './components';
import { sendPasswordResetEmail } from '@fido/state/lib/api';

export interface ILoginProps {
  onSubmitLoginWithFacebook(): void;
  onSubmitLoginWithEmailAndPassword(email: string, password: string): void;
  onSubmitCreateUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<void>;
}

export const Login = ({
  onSubmitLoginWithFacebook,
  onSubmitLoginWithEmailAndPassword,
  onSubmitCreateUserWithEmailAndPassword,
}: ILoginProps) => {
  const history = useHistory();
  const resetPassword = async (email: string) => {
    if (!email) {
      alert('In order to reset your password, enter your email address in the field below.');
      return;
    }
    await sendPasswordResetEmail(email);
    alert('A password reset email has been sent.');
  };
  return (
    <LoginShell>
      <Switch>
        <Route path="/createWithEmail">
          <CreateWithEmailForm
            onSubmit={async (email, password) => {
              await onSubmitCreateUserWithEmailAndPassword(email, password);
              history.push('/app');
            }}
          />
        </Route>
        <Route path="/loginWithEmail">
          <LoginShell>
            <LoginWithEmailForm
              onSubmit={async (email, password) => {
                onSubmitLoginWithEmailAndPassword(email, password);
                history.push('/app');
                const path = localStorage.getItem('Path') || '';
                if (path !== '' && path !== '/') {
                  history.push(path);
                  localStorage.removeItem("Path")
                }
              }}
              onClickResetPassword={(email: string) => {
                resetPassword(email);
              }}
            />
          </LoginShell>
        </Route>
        <Route path="/">
          <SelectLoginTypeForm
            onSelectLoginWithFacebook={onSubmitLoginWithFacebook}
            onSelectLoginWithEmail={() => history.push('/loginWithEmail')}
            onSelectCreateWithEmail={() => history.push('/createWithEmail')}
          />
        </Route>
      </Switch>
    </LoginShell>
  );
};
