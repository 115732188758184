import { EPetSex, EPetSize } from '@fido/common';
import { unit } from '@fido/styles';
import {
  Button,
  DateFormField,
  Heading,
  InputFormField,
  SelectFormField,
  SwitchFormField,
  isDesktop
} from '@fido/web-components';
import every from 'lodash/every';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  petBreedSelectOptions,
  petSexSelectOptions,
  petSizeSelectOptions,
} from '../../../../utils';

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormColumn = styled.div`
  padding: ${unit * 3}px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const StyledInputFormField = styled(InputFormField)`
  padding: ${unit}px 0;
  max-width: 100%;
`;

const StyledSelectFormField = styled(SelectFormField)`
  padding: ${unit}px 0;
  max-width: 100%;
`;

const StyledDateFormField = styled(DateFormField)`
  padding: ${unit}px 0;
  max-width: 100%;
`;

const StyledSwitchFormField = styled(SwitchFormField)`
  padding: ${unit}px 0;
  max-width: 100%;
`;

const validateName = (name: string): boolean => !!name;

const validateSize = (size?: EPetSize): boolean => !!size;

//const validatePrice = (price: string): boolean => !!price;
const validatePrice = (price: string): boolean => !!price && new RegExp(/^[0-9]+$/).test(price);

const validateDOB = (dob: string): boolean => !!dob;

const validateReadyDate = (readyDate: string): boolean => !!readyDate;

const validateBreed = (breed: string): boolean => !!breed;

const validateSex = (sex?: EPetSex): boolean => !!sex;

const validateForm = (fields: {
  name: string;
  breed: string;
  size?: EPetSize;
  sex?: EPetSex;
  price: string;
  dob: string;
  readyDate: string;
}) =>
  every([
    validateName(fields.name),
    validateSize(fields.size),
    validatePrice(fields.price),
    validateDOB(fields.dob),
    validateReadyDate(fields.readyDate),
    validateBreed(fields.breed),
    validateSex(fields.sex),
  ]);

export interface ICreatePuppyProfileFirstStepProps {
  name: string;
  breed: string;
  size?: EPetSize;
  sex?: EPetSex;
  price: string;
  dob: string;
  readyDate: string;
  hypoallergenic: boolean;
  onChangeName(name: string): void;
  onChangeBreed(breed: string): void;
  onChangeSize(size?: EPetSize): void;
  onChangePrice(price: string): void;
  onChangeDOB(dob: string): void;
  onChangeReadyDate(readyDate: string): void;
  onChangeSex(sex?: EPetSex): void;
  onChangeHypoallergenic(hypoallergenic: boolean): void;
  onClickContinue(): void;
}

export const CreatePuppyProfileFirstStep = ({
  name,
  breed,
  size,
  price,
  dob,
  readyDate,
  sex,
  hypoallergenic,
  onChangeName,
  onChangeBreed,
  onChangeSize,
  onChangePrice,
  onChangeDOB,
  onChangeReadyDate,
  onChangeSex,
  onChangeHypoallergenic,
  onClickContinue,
}: ICreatePuppyProfileFirstStepProps) => {
  const [showValidStates, setShowValidStates] = useState(false);
  const valid = validateForm({
    name,
    breed,
    size,
    price,
    dob,
    sex,
    readyDate,
  });

  const continueToNextScreen = () => {
    setShowValidStates(true);
    if (valid) {
      onClickContinue();
    }
  };
  
  const Price  = price.replace(/^\D+/g, '');
  
  return (
    <>
      <Heading>Step 1: Basics</Heading>
      <FormContainer>
        <FormColumn>
          <StyledInputFormField
            label="Name"
            value={name}
            valid={showValidStates ? validateName(name) : true}
            onChangeValue={onChangeName}
            labelValid
          />
          <StyledSelectFormField
            label="Breed"
            placeholder="Pick One..."
            selected={breed}
            labelValid
            options={petBreedSelectOptions}
            onSelectOption={onChangeBreed}
            valid={showValidStates ? validateBreed(breed) : true}
          />
          <StyledSelectFormField
            label="Size"
            placeholder="Pick One..."
            selected={size}
            labelValid
            options={petSizeSelectOptions}
            onSelectOption={key => onChangeSize(key as EPetSize)}
            valid={showValidStates ? validateSize(size) : true}
          />
          <StyledSelectFormField
            label="Gender"
            placeholder="Pick One..."
            selected={sex}
            labelValid
            options={petSexSelectOptions}
            onSelectOption={key => onChangeSex(key as EPetSex)}
            valid={showValidStates ? validateSex(sex) : true}
          />
          <StyledInputFormField
            label="Price"
            type="number"
            value={Price}
            labelValid
            valid={showValidStates ? validatePrice(price) : true}
            onChangeValue={onChangePrice}
          />
          <StyledDateFormField
            label="Birth Date"
            value={dob}
            labelValid
            valid={showValidStates ? validateDOB(dob) : true}
            onChangeValue={onChangeDOB}
          />
          <StyledDateFormField
            label="Ready Date"
            value={readyDate}
            labelValid
            valid={showValidStates ? validateReadyDate(readyDate) : true}
            onChangeValue={onChangeReadyDate}
          />
          {!isDesktop() && (
            <FormColumn>
              <StyledSwitchFormField
                label="Hypoallergenic"
                offLabel="No"
                onLabel="Yes"
                description="Hypoallergenic dogs typically do not shed their fur"
                selected={hypoallergenic}
                onClick={() => onChangeHypoallergenic(!hypoallergenic)}
              />
            </FormColumn>
          )}
        </FormColumn>
        {isDesktop() && (
          <FormColumn>
            <StyledSwitchFormField
              label="Hypoallergenic"
              offLabel="No"
              onLabel="Yes"
              description="Hypoallergenic dogs typically do not shed their fur"
              selected={hypoallergenic}
              onClick={() => onChangeHypoallergenic(!hypoallergenic)}
            />
          </FormColumn>
        )}
      </FormContainer>
      <Button variant="primary" size="large" onClick={continueToNextScreen}>
        Continue
      </Button>
    </>
  );
};
