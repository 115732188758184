"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const state_1 = require("../state");
const userProfiles_1 = require("../state/userProfiles");
const common_1 = require("@fido/common");
exports.fetchUserProfileByID = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.fetchUserProfile(id);
    result.map(userProfile => dispatch(userProfiles_1.setUserProfile({
        id,
        userProfile,
    })));
});
exports.fetchUserProfile = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const userID = state_1.selectUserID(getState());
    if (!userID) {
        return;
    }
    dispatch(exports.fetchUserProfileByID(userID));
});
exports.updateUserProfile = (id, profile) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.updateUserProfile(id, profile);
    result.map(userProfile => dispatch(userProfiles_1.setUserProfile({
        id,
        userProfile,
    })));
});
