import React, { useState, useEffect } from 'react';
import './Checkout.css';
import styled from 'styled-components';
import { unit } from '@fido/styles';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
  firestore,
  UserProfileLocationRecord,
  fetchUserProfile as apiFetchUserProfile,
  updateUserProfile as apiUpdateUserProfile,
} from '@fido/common';
import moment from 'moment';
import {
  EApplicationStatus,
  EApplicationReadStatus
} from '@fido/state';

import { baseUrl, domainName } from '../../config'

const PAYMENT_URL =
  `${baseUrl}/onPaymentDone`;

const PageContents = styled.div`
  display: flex;
  flex: 1;
  padding: ${unit}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto;
`;

const SearchAddress = styled(AsyncSelect)`
  padding: ${unit}px 0;
  max-width: 900px;
`;

export default function Checkout() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  
  const [deposit, setDeposit] = useState('');
  const [breederName, setBreederName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [puppyName, setPuppyName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [sellerAccountId, setSellerAccountId] = useState('');

  useEffect(() => {
    var url = new URLSearchParams(window.location.search);
    var sellerID = url.get('Id');
    if (!sellerID) {
      return;
    }
    localStorage.setItem('SellerID', sellerID);
    this.fetchSellerProfileByID(sellerID);
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }
    if (query.get('canceled')) {
      setMessage("Order canceled -- continue to shop when you're ready.");
    }
  }, []);

  fetchSellerProfileByID = async sellerID => {
    const snapshot = await firestore
      .collection('seller-profiles')
      .doc(sellerID)
      .get();
    const data = snapshot.data();
    if (!data) {
      return;
    }
    const stripeAccountId = data.paymentSettings.stripePaymentAccountID;
    setSellerAccountId(stripeAccountId);
    setDeposit(data.depositAmount);
    setBreederName(data.name);
  };
  
  createAUser = async (email, password) => {
    const params = {
      email: email,
      password: password,
    };
    try {
      await fetch(
        `${baseUrl}/createUser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      )
        .then(response => response.json())
        .then(json => {
          if (json.response.code === 200) {
            const userId = json.response.uid;
            if (userId !== '') {
              this.createUserProfile(userId);
            }
          }
          if (json.response.code === 500) {
            const error = json.response.error;
            if (error.code === 'auth/email-already-exists') {
              this.fetchUserDetails(email);
            }
            if (error.code === 'auth/invalid-email') {
              alert(error.message);
              setLoading(false)
            }
          }
        })
        .catch(() => {
          alert('Something went wrong, please try again.');
          setLoading(false)
        });
    } catch (error) {
      alert('Something went wrong, please try again.');
      setLoading(false)
    }
  };

  fetchUserDetails = async email => {
    const params = {
      emailAdress: email,
    };
    try {
      await fetch(
        `${baseUrl}/fetchUserDetails`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      )
        .then(response => response.json())
        .then(json => {
          const uid = json.body.buyerUser.uid;
          if (uid !== '') {
            this.checkUserProfileExist(uid);
          } else {
            alert('UID does not existing');
            setLoading(false)
          }
        })
        .catch(error => {
          console.log(error)
          setLoading(false)
        });
    } catch (error) {}
  };

  checkUserProfileExist = async usrId => {
    const result = await apiFetchUserProfile(usrId);
    result.mapError(error => {
      alert('Unable to fetch user profile');
      setLoading(false)
    });
    await result.mapAsync(async profile => {
      profile.name === ''
        ? this.createUserProfile(usrId)
        : this.createApplicant('GENERALWAITLIST', usrId);
    });
  };

  createUserProfile = buyerUsrId => {
    const location = UserProfileLocationRecord({
      city: '',
      state: ''
    });
    const profile = {
      imageURL: '',
      name: userName,
      location: location,
      address: address,
      cellNumber: phoneNumber,
      enableSms: false,
      bio: '',
      kids: false,
      otherPets: true,
      otherDogs: false,
      yardOrPark: false,
      apartment: false,
      busyLifestyle: false,
      activeLifestyle: false,
      socialButterfly: false,
      solitary: false,
      neatFreak: false,
      createdAt: moment().format(),
    };
    this.createProfile(buyerUsrId, profile);
  };

  createProfile = async (
    buyerUsrId, profile) => {
    const result = await apiUpdateUserProfile(buyerUsrId, profile);
    result.mapError(error => {
      alert('Unable to create profile');
      setLoading(false)
    });
    await result.mapAsync(async profile => {
      this.createApplicant('GENERALWAITLIST', buyerUsrId);
    });
  };

  createApplicant = async (
    petProfileId, buyerUsrId) => {
    const sellerProfileID = localStorage.getItem('SellerID') || '';
    let application = {
      userID: buyerUsrId,
      deposit: deposit,
      petProfileID: petProfileId,
      buyerName: userName,
      buyerEmail: email,
      status: EApplicationStatus.depositPending,
      sellerProfileID: sellerProfileID,
      puppyName: puppyName,
    };
    const createdAt = moment().format();
    const result = await firestore.collection('applications').add({
      createdAt,
      userID: application.userID,
      deposit: application.deposit,
      petProfileID: application.petProfileID,
      sellerProfileID: application.sellerProfileID,
      status: application.status,
      buyerName: application.buyerName,
      buyerEmail: application.buyerEmail,
      buyerReadStatus: EApplicationReadStatus.unread,
      sellerReadStatus: EApplicationReadStatus.unread,
      puppyName: application.puppyName,
      createdByLink: true
    });

    if (result) {
      console.log(result.id);
      localStorage.setItem('ApplicationId', result.id);
      createPayment()
    }
  };

  createPayment = async () => {
    if (!stripe || !elements || deposit === '' || sellerAccountId === '') {
      alert('Stripe | element | deposit | seller ID');
      setLoading(false)
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      alert('Unable to generate Stripe Token');
      setLoading(false);
    } else {
      const totalAmount = calculateTotalAmount(deposit, calculateServiceFee(deposit))
      const params = {
        amount: totalAmount,
        token: result.token.id,
        petName: puppyName,
        applicationFee: this.calculateServiceFee(deposit),
        sellerPaymentAccountID: sellerAccountId,
      };
      this.paymentProcess(params);
    }
  }

  // On press checkout btn and returns token for card and pass token to Charges api
  onClickCheckout = (event) => {
    event.preventDefault();
    if (!userName) {
      alert("Name is required.")
      return;
    } 
    if (!email) {
      alert("Email is required.")
      return;
    }
    if(!phoneNumber) {
      alert("Phone number is required.")
      return;
    }
    if (!address) {
      alert("Address is required.")
      return;
    }
    setLoading(true);
    this.createAUser(email, 'Abcd@1234');
  };

  // Charges api to do payment for vendor and application fee for platform owner
  paymentProcess = async params => {
    try {
      await fetch(PAYMENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
        .then(async (res) => {
          // const result = await res.json()
          // const transactionId = result.body.Success.id;
          // localStorage.setItem('TransactionId', transactionId);
          setLoading(false);
          if (res.status === 200) {
            history.push(`/paymentSuccess/1`);
          } else {
            history.push('/paymentFailure');
          }
        })
        .catch(() => {
          setLoading(false);
          history.push('/paymentFailure');
        });
    } catch (error) {
      alert('Payment Failed');
      setLoading(false);
    }
  };

  // Calculate application fee
  calculateServiceFee = depositAmount => {
    return Math.min(depositAmount * 0.1, 100);
  };

  calculateTotalAmount = (
    depositAmount, ServiceFee) => {
    return Number(depositAmount) + Number(ServiceFee);
  };

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          padding,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

  loadOptions = inputValue => {
    const params = {
      text: inputValue,
    };
    try {
      return fetch(
        `${baseUrl}/fetchLocationAddress`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      )
        .then(res => res.json())
        .then(responseAsJson => {
          return responseAsJson.body.result.predictions.map(
            (item, description) => {
              const state =
                item.terms.length > 0
                  ? item.terms[item.terms.length - 2].value
                  : '';
              const city =
                item.terms.length > 0
                  ? item.terms[item.terms.length - 3].value
                  : '';
              return {
                value: item.description,
                label: item.description,
                state: state,
                city: city,
                placeId: item?.place_id,
              };
            }
          );
        });
    } catch (e) {
      alert('Something went wrong, try again.');
      setLoading(false)
    }
  };

  onChangeAddress = address => {
    console.log(address);
    if (!address) {
      setAddress('');
      return;
    }
    setAddress(address);
  };

  components = {
    DropdownIndicator: null,
  };

  return message ? (
    <Message message={message} />
  ) : (
    <PageContents>
      <section>
        <div style={{ paddingTop: '20px' }}>
          <div>
            <div className="split left">
              <div className="centered">
                <div className="PuppyName">{breederName}</div>
                <div className="ProductSummary-totalAmount">
                  Deposit: $ {deposit}
                </div>
                <div className="ProductSummary-totalAmount">
                  Service Fee: $ {this.calculateServiceFee(deposit)}
                </div>
                <div className="Total">
                  Total: $
                  {this.calculateTotalAmount(
                    deposit,
                    this.calculateServiceFee(deposit)
                  )}
                </div>
                <div className="ProductSummary-productImageContainer">
                  <div className="ProductImage-container">
                    <img
                      src={`${domainName}/android-icon-192x192.png`}
                      style={{
                        width: '300px',
                        height: '300px',
                        borderRadius: 15,
                        marginTop: 10,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="split right">
              <form onSubmit={this.onClickCheckout}>
                <fieldset className="FormGroup">
                  <div className="FormRow">
                    <div className="centered">
                      <div className="head">
                        <h2>Pay with card</h2>
                      </div>
                      <label className="text">Name</label>
                      <input
                        id = "Name"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        className="text-box"
                        type="text"
                      />
                      <label className="text">Email</label>
                      <input
                        id = "Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className="text-box"
                        type="text"
                      />
                      <label className="text">Phone number</label>
                      <input
                        id = "Phone Number"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        className="text-box"
                        type="text"
                      />
                      <label className="text">Address</label>
                      <div style={{ width: '100%', marginBottom: 8 }}>
                        <SearchAddress
                          components={this.components}
                          className="dropdown"
                          placeholder="Enter address"
                          cacheOptions
                          defaultOptions
                          value={address}
                          getOptionLabel={e => e.label}
                          getOptionValue={e => e.value}
                          loadOptions={loadOptions}
                          onChange={onChangeAddress}
                          isClearable
                          isSearchable
                        />
                      </div>
                      <label className="text">Card information</label>
                      <CardNumberElement
                        {...this.createOptions(17)}
                        id = "CardNumber"
                        className="text-box-1"
                      />
                      <CardExpiryElement  id = "CardExpiryDate" className="number1" />
                      <CardCvcElement  id = "CardCvc" className="number2" />
                      <button disabled={isLoading} className="submit">
                        {isLoading ? 'Submitting...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </section>
    </PageContents>
  );
}
