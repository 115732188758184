"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const petProfileStateTypes_1 = require("./petProfileStateTypes");
exports.petProfileStateReducers = {
    resetPetProfileState: (state) => petProfileStateTypes_1.PetProfileStateRecord(),
    setPetProfiles: (state, { payload: { profiles }, }) => state.set('profiles', profiles),
    setPetProfile: (state, { payload: { id, profile }, }) => state.setIn(['profiles', id], profile),
    setPetProfilesLoadingStatus: (state, { payload: { loadingStatus }, }) => state.set('profilesLoadingStatus', loadingStatus),
    mergePetProfiles: (state, { payload: { profiles }, }) => state.update('profiles', p => p.merge(profiles)),
    deletePetProfile: (state, { payload: { id } }) => state.deleteIn(['profiles', id]),
};
exports.petProfileStateReducer = reduxHelpers_1.makeReducer(petProfileStateTypes_1.PetProfileStateRecord(), exports.petProfileStateReducers);
