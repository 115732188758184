import {
  fetchApplicationsByPetProfile,
  fetchPetProfileByID,
  fetchUserProfileByID,
  selectApplications,
  selectPetProfiles,
  selectUserID,
  selectUserProfiles,
  updatePetProfile,
  selectFavorites,
  fetchFavoritesByPetProfile,
  selectMessages,
  deletePetProfileByID,
  markPetProfileAsSold,
  fetchMessages,
  deleteFavoriteProfileAsSold,
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { IPetProfileProps } from '../PetProfile';
import { IPetProfileRecord } from '@fido/common';
import { RouteComponentProps } from 'react-router-dom';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectRouteComponentProps = (state: IState, props: RouteComponentProps) =>
  props;

const createApplicationsByPetProfileSelector = (petProfileID: string) =>
  createSelector(selectApplications, applications =>
    applications.filter(app => app.petProfileID === petProfileID)
  );

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch, getState: () => IState) => async (
    petProfileID: string
  ) => {
    if (userID) dispatch(fetchMessages(userID));
    dispatch(fetchPetProfileByID(petProfileID));
    dispatch(fetchFavoritesByPetProfile(petProfileID));
    await dispatch(fetchApplicationsByPetProfile(petProfileID));
    const applications = createApplicationsByPetProfileSelector(petProfileID)(
      getState()
    );
    applications.forEach(app => dispatch(fetchUserProfileByID(app.userID)));
  }
);

const selectOnSavePetProfile = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch, getState: () => IState) => async (
    petProfileID: string,
    petProfile: IPetProfileRecord
  ) => {
    dispatch(updatePetProfile(petProfileID, petProfile));
  }
);

const selectNumberOfFavorites = createSelector(
  selectRouteComponentProps,
  selectFavorites,
  (props, favorites) => {
    const { id: petProfileID } = props.match.params as { id: string };
    return favorites.filter(f => f.petProfileID === petProfileID).size;
  }
);

const selectNumberOfApplicants = createSelector(
  selectRouteComponentProps,
  selectApplications,
  (props, applications) => {
    const { id: petProfileID } = props.match.params as { id: string };
    return applications.filter(f => f.petProfileID === petProfileID).size;
  }
);

const selectNumberOfViews = createSelector(
  selectRouteComponentProps,
  selectPetProfiles,
  (props, petProfiles) => {
    const { id: petProfileID } = props.match.params as { id: string };
    const petProfile = petProfiles.get(petProfileID);
    return petProfile?.views || 0;
  }
);

const selectNumberOfMessages = createSelector(
  selectRouteComponentProps,
  selectApplications,
  selectMessages,
  (props, applications, messages) => {
    const { id: petProfileID } = props.match.params as { id: string };
    const applicationsUserIDs = applications
      .filter(f => f.petProfileID === petProfileID)
      .map(a => a.userID);
    return messages.filter(msg => applicationsUserIDs.includes(msg.fromID))
      .size;
  } 
);

export const selectPetProfileProps = (
  dispatch: ThunkDispatch<IState, null, AnyAction>
) => (state: IState, props: RouteComponentProps): IPetProfileProps => ({
  userID: selectUserID(state),
  applications: selectApplications(state),
  loadInitialData: dispatch(selectLoadInitialData(state)),
  profiles: selectPetProfiles(state),
  messages: selectMessages(state),
  userProfiles: selectUserProfiles(state),
  numberOfFavorites: selectNumberOfFavorites(state, props),
  numberOfApplicants: selectNumberOfApplicants(state, props),
  numberOfViews: selectNumberOfViews(state, props),
  numberOfMessages: selectNumberOfMessages(state, props),
  onSavePetProfile: dispatch(selectOnSavePetProfile(state)),
  onDeletePetProfile: id => dispatch(deletePetProfileByID(id)),
  onMarkPetProfileAsSold: id => dispatch(markPetProfileAsSold(id)),
  onDeleteFavoriteProfile: id => dispatch(deleteFavoriteProfileAsSold(id)),
});
