"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const messageStateTypes_1 = require("./messageStateTypes");
exports.messageStateReducers = {
    resetMessageState: (state) => messageStateTypes_1.MessageStateRecord(),
    setMessages: (state, { payload: { messages } }) => state.set('messages', messages),
    setMessage: (state, { payload: { id, message }, }) => state.setIn(['messages', id], messageStateTypes_1.MessageRecord(message)),
    setConversations: (state, { payload: { conversations }, }) => state.set('conversations', conversations),
    setConversation: (state, { payload: { id, conversation }, }) => state.setIn(['conversations', id], conversation),
};
exports.messageStateReducer = reduxHelpers_1.makeReducer(messageStateTypes_1.MessageStateRecord(), exports.messageStateReducers);
