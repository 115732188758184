"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const state_1 = require("../state");
const api_1 = require("../api");
exports.createFavorite = (create) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.createFavorite(create);
    result.map(({ id, favorite }) => {
        dispatch(state_1.setFavorite({
            id,
            favorite,
        }));
    });
});
exports.deleteFavorite = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.deleteFavorite(id);
    result.map(() => {
        dispatch(state_1.removeFavorite({
            id,
        }));
    });
});
exports.fetchFavorites = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const userID = state_1.selectUserID(getState());
    if (!userID) {
        return;
    }
    const result = yield api_1.fetchFavorites(userID);
    result.map(favorites => {
        dispatch(state_1.setFavorites({
            favorites,
        }));
    });
});
exports.fetchFavoritesByPetProfile = (petProfileID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchFavoritesByPetProfile(petProfileID);
    result.map(favorites => {
        dispatch(state_1.setFavorites({
            favorites,
        }));
    });
});
