"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
exports.useClickOutside = (onClickOutside) => {
    const domNode = react_1.useRef();
    const handleClickOutside = (e) => {
        if (domNode && domNode.current.contains(e.target)) {
            return;
        }
        onClickOutside();
    };
    react_1.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return domNode;
};
