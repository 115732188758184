import {
  applicationStateReducer,
  authStateReducer,
  favoriteStateReducer,
  IState as IFidoSharedState,
  messageStateReducer,
  paymentStateReducer,
  petProfileStateReducer,
  userProfileStateReducer,
  filterStateReducer,
  rolesStateReducer,
  reviewStateReducer
} from '@fido/state';
import { sellerProfileStateReducer } from '@fido/state/lib/state/sellerProfile';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import * as storage from 'redux-storage';
import immutableMerger from 'redux-storage-merger-immutablejs';
import thunkMiddleware from 'redux-thunk';

export type IState = IFidoSharedState & {};

const isProduction = process.env.NODE_ENV === 'production';

const loggerMiddleware = createLogger({
  collapsed: (getState, action) => !action.error,
});

const reducer = combineReducers<IState>({
  auth: authStateReducer,
  roles: rolesStateReducer,
  petProfile: petProfileStateReducer,
  userProfile: userProfileStateReducer,
  favorites: favoriteStateReducer,
  applications: applicationStateReducer,
  payments: paymentStateReducer,
  sellerProfile: sellerProfileStateReducer,
  messages: messageStateReducer,
  filters: filterStateReducer,
  reviews: reviewStateReducer,
});

const rootReducer = storage.reducer(reducer, immutableMerger);

const middleware = isProduction
  ? applyMiddleware(thunkMiddleware)
  : applyMiddleware(thunkMiddleware, loggerMiddleware);

export const createReduxStore = () => {
  return createStore(rootReducer, middleware);
};
