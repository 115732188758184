const baseUrl = 'https://us-central1-barkd-app.cloudfunctions.net';
const stripeKey = 'ca_HV13lp7byAf4qS7aw35hiFOcMppEp3iN';
const googleKey = 'AIzaSyCv7wp5cWQCjEPze-lGJclZKE5CgInWHyE';
const domainName = 'https://app.barkd.com';

export { baseUrl, stripeKey, googleKey, domainName }

//------Website Name----//
// dev - https://barkd-dev.bosframework.com/
// pro - https://app.barkd.com


//------Stripe keys----//
//dev - ca_HV13zMImirDwGn9wvj8vXjxErMr0A5T7
//pro - ca_HV13lp7byAf4qS7aw35hiFOcMppEp3iN

//------Google maps and geocoding ----//
//dev - AIzaSyBLt6NPf2O7nnPGGKAudc-BbQXNbcertlY
//prod - AIzaSyCv7wp5cWQCjEPze-lGJclZKE5CgInWHyE