import React, { useEffect } from 'react';
import { Logo, ELogoTextStyle, ELogoSize } from '@fido/web-components';
import { PageContainer } from '../common';
import styled from 'styled-components';
import { unit } from '@fido/styles';
import { firestore } from '@fido/common';
import { EApplicationStatus } from '@fido/state';
import './Status.css';
import {
  sendPaymentSuccessEmailToBuyer,
  sendPaymentConfirmationEmailToBreeder,
} from './paymentEmails';

const PageContents = styled.div`
  display: flex;
  flex: 1;
  padding: ${unit * 3}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const LogoContainer = styled.div`
  margin-bottom: ${unit * 7}px;
`;

export const PaymentSuccess = () => {
  useEffect(() => {
    updateApplicationStatus();
    udpatePaymentStatus();
  }, []);

  const updateApplicationStatus = async () => {
    const applicationId = localStorage.getItem('ApplicationId') || '';
    if (applicationId === '') {
      return;
    }
    try {
      await firestore
        .collection('applications')
        .doc(applicationId)
        .update({ status: EApplicationStatus.depositReceived });
      paymentSuccessEmail();
    } catch (error) {
      console.log(error);
    }
  };

  const udpatePaymentStatus = async () => {
    const applicationId = localStorage.getItem('ApplicationId') || '';
    if (applicationId === '') {
      return;
    }
    await firestore
      .collection('seller-payments')
      .where('appID', '==', applicationId)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach((doc: any) => {
          updateRecored(doc.id);
        });
      });
  };

  const updateRecored = async (paymentRecoredId: string) => {
    // const transactionId = localStorage.getItem('TransactionId') || '';
    try {
      await firestore
        .collection('seller-payments')
        .doc(paymentRecoredId)
        .update({
          paymentStatus: 'Paid',
          paymentType: 'Card',
          // transactionID: transactionId,
        });
    } catch (error) {
      console.log(error);
    }
    // localStorage.clear();
  }

  const paymentSuccessEmail = () => {
    const applicationId = localStorage.getItem('ApplicationId') || '';
    if (applicationId === '') {
      return;
    }
    var url = window.location.href;
    if (url) {
      var paths = url.split('/');
      sendPaymentSuccessEmailToBuyer(
        applicationId, paths[paths.length - 1]);
      sendPaymentConfirmationEmailToBreeder(
        applicationId,
        paths[paths.length - 1]
      );
    }
  };

  return (
    <PageContainer>
      <PageContents>
        <LogoContainer>
          <Logo textStyle={ELogoTextStyle.dark} size={ELogoSize.large} />
        </LogoContainer>
        <div className="card">
          <div className="Card-A">
            <div className="c-container">
              <div className="o-circle c-container__circle o-circle__sign--success">
                <div className="o-circle__sign"></div>
              </div>
            </div>
          </div>
          <div className="header-sucess">Payment success</div>
          <div className="header-sub-s">
            Your payment was successful <br />
            Thank you for your interest!
          </div>
        </div>
      </PageContents>
    </PageContainer>
  );
};
