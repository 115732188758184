import React, { useEffect, useState } from 'react';
import { Logo, ELogoTextStyle, ELogoSize } from '@fido/web-components';
import { PageContainer } from '../common';
import styled from 'styled-components';
import { unit } from '@fido/styles';
import { EApplicationStatus } from '@fido/state';
import './Status.css';
import { firestore } from '@fido/common';
import {
  sendPaymentFailureEmailToBuyer,
  sendPaymentFailureConfirmationEmailToBreeder,
} from './paymentEmails';

const PageContents = styled.div`
  display: flex;
  flex: 1;
  padding: ${unit * 3}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const LogoContainer = styled.div`
  margin-bottom: ${unit * 7}px;
`;

export const PaymentFailure = () => {
  useEffect(() => {
    updateApplicationStatus();
  }, []);

  const updateApplicationStatus = async () => {
    const applicationId = localStorage.getItem('ApplicationId') || '';
    if (applicationId === '') {
      return;
    }
    try {
      await firestore
        .collection('applications')
        .doc(applicationId)
        .update({ status: EApplicationStatus.paymentFailed });
      paymentFailureEmail();
    } catch (error) {
      console.log(error);
    }
  };

  const paymentFailureEmail = () => {
    const applicationId = localStorage.getItem('ApplicationId') || '';
    if (applicationId === '') {
      return;
    }
    sendPaymentFailureEmailToBuyer(applicationId);
    sendPaymentFailureConfirmationEmailToBreeder(applicationId);
  };

  return (
    <PageContainer>
      <PageContents>
        <LogoContainer>
          <Logo textStyle={ELogoTextStyle.dark} size={ELogoSize.large} />
        </LogoContainer>
        <div className="card">
          <div className="Card-A">
            <div className="c-container">
              <div className="o-circle c-container__circle o-circle__sign--failure">
                <div className="o-circle__sign"></div>
              </div>
            </div>
          </div>
          <div className="header-failure">Payment failure</div>
          <div className="header-sub-F">Your payment was failure.</div>
        </div>
      </PageContents>
    </PageContainer>
  );
};
