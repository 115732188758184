import {baseUrl} from '../../config'

const BUYER_PAYMENT_SUCCESS = `${baseUrl}/onBuyerPaymentSuccess`;
const BREEDER_PAYMENT_SUCCESS = `${baseUrl}/onBreederPaymentConfirmation`;
const BUYER_PAYMENT_FAILURE = `${baseUrl}/onBuyerPaymentFailure`;
const BREEDER_PAYMENT_FAILURE = `${baseUrl}/onBreederPaymentFailureConfirmation`
const PAYMENT_EMAIL_TO_BUYER = `${baseUrl}/paymentSuccessEmailToBuyer`;
const PAYMET_EMAIL_TO_BREEDER = `${baseUrl}/paymentSuccessEmailToBreeder`;

export const sendPaymentSuccessEmailToBuyer = async (
  applicationId: string, publicUrl: string) => {
  const params = {
    applicationId: applicationId,
  };
  const paymentUrl = publicUrl === "1" ? PAYMENT_EMAIL_TO_BUYER : BUYER_PAYMENT_SUCCESS
  try {
    await fetch(paymentUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(res => {
        if (res.status === 200) {
          console.log('Success');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }

}
export const sendPaymentConfirmationEmailToBreeder = async (
  applicationId: string, publicUrl: string) => {
  const params = {
    applicationId: applicationId,
  };
  const paymentUrl = publicUrl === "1" ? PAYMET_EMAIL_TO_BREEDER : BREEDER_PAYMENT_SUCCESS
  try {
    await fetch(paymentUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(res => {
        if (res.status === 200) {
          console.log('Success');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }

}
export const sendPaymentFailureEmailToBuyer = async (
  applicationId: string) => {
  const params = {
    applicationId: applicationId,
  };
  try {
    await fetch(BUYER_PAYMENT_FAILURE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(res => {
        if (res.status === 200) {
          console.log('Success');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
}
export const sendPaymentFailureConfirmationEmailToBreeder = async (
  applicationId: string) => {
  const params = {
    applicationId: applicationId,
  };
  try {
    await fetch(BREEDER_PAYMENT_FAILURE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(res => {
        if (res.status === 200) {
          console.log('Success');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
}
