import { IPetProfileRecord, EPetSize, EPetSex } from '@fido/common';
import { uploadImage } from '@fido/state/lib/api';
import { unit } from '@fido/styles';
import {
  Button,
  convertImageFileToSupportedFormatLossy,
  convertImageURIToSupportedFileFormatLossy,
  Description,
  ImageUploadGrid,
  ImageUploadGridItem,
  InputFormField,
  SwitchFormField,
  Text,
  TextAreaFormField,
  UploadInput,
  SelectFormField,
  DateFormField,
} from '@fido/web-components';
import { List, Map } from 'immutable';
import compact from 'lodash/compact';
import times from 'lodash/times';
import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import { PageContainer } from '../../../common';
import {
  editPetProfileReducer,
  EditPetProfileStateRecord,
  setBio,
  setBreed,
  setDetails,
  setImages,
  setName,
  setPaused,
  setHypoallergenic,
  setSize,
  setSex,
  setPrice,
  setReadyDate,
  setDOB
} from './editPetProfileState';
import { PetProfileDetails } from '../../../petProfileDetails';
import { petBreedSelectOptions, petSizeSelectOptions } from '../../../../utils';

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: row;
`;

const GridContainer = styled.div`
  padding-top: ${unit * 2}px;
  @media (max-width: 768px) {
    width:80%
  }
`;

const StyledInputFormField = styled(InputFormField)`
  padding: ${unit}px 0;

  & > div {
    max-width: 100%;
  }
`;

const StyledTextAreaFormField = styled(TextAreaFormField)`
  padding: ${unit}px 0;

  & > div {
    max-width: 100%;
  }

  textarea {
    min-height: 240px;
  }
`;

const StyledSelectFormField = styled(SelectFormField)`
  padding: ${unit}px 0;
  @media (max-width: 768px) {
    width:100%
  }
`;

const StyledSwitchFormField = styled(SwitchFormField)`
  padding: ${unit * 2}px 0 ${unit}px 0;
`;

const StyledDateFormField = styled(DateFormField)`
  padding: ${unit}px 0;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 50%;

  &:not(:first-of-type) {
    margin-left: ${unit * 4}px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${unit}px 0;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${unit * 2}px;
  padding-top: ${unit * 2}px;
  max-width: 500px;
  @media (max-width: 479px) {
    width: 300px;
  }
`;


const loadImageFiles = async (images: string[]) =>
  compact(
    await Promise.all(
      images.map(imageURL =>
        convertImageURIToSupportedFileFormatLossy(imageURL)
      )
    )
  );

const uploadImageFiles = async (
  userID: string,
  images: (File | null)[]
): Promise<string[]> => {
  const results = await Promise.all(
    compact(images.map(file => file && uploadImage(userID, file)))
  );
  return compact(results.map(result => result.ok()));
};

export interface IEditPetProfileProps {
  profile: IPetProfileRecord;
  userID: string;
  onCancel(): void;
  onSavePetProfile(petProfile: IPetProfileRecord): void;
}

export const EditPetProfile = ({
  profile,
  userID,
  onCancel,
  onSavePetProfile,
}: IEditPetProfileProps) => {
  const [isLoading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(
    editPetProfileReducer,
    EditPetProfileStateRecord()
  );

  const reset = async () => {
    dispatch(setHypoallergenic(profile.hypoallergenic));
    dispatch(setSize(profile.size));
    dispatch(setSex(profile.sex));
    dispatch(setName(profile.name));
    dispatch(setBreed(profile.breed));
    dispatch(setBio(profile.description));
    dispatch(setPaused(profile.paused));
    dispatch(setDetails(profile.details));
    const imageFiles = await loadImageFiles(profile.images.toArray());
    const images = Map(imageFiles.map((f, i) => [i.toString(), f]));
    dispatch(setImages(images));
    dispatch(setPrice(profile.price.toString()));
    dispatch(setReadyDate(profile.readyDate));
    dispatch(setDOB(profile.dob));
  };

  useEffect(() => {
    reset();
  }, [profile]);

  const onClickCancel = () => {
    reset();
    onCancel();
  };
  
  const onClickSave = async () => {
    setLoading(true);
    const images = await uploadImageFiles(
      userID,
      state.images.valueSeq().toArray()
    );
    onSavePetProfile(
      profile
        .set('name', state.name)
        .set('breed', state.breed)
        .set('description', state.bio)
        .set('images', List(images))
        .set('paused', state.paused)
        .set('details', state.details)
        .set('hypoallergenic', state.hypoallergenic)
        .set('size', state.size || EPetSize.medium)
        .set('sex', state.sex || EPetSex.female)
        .set('price', parseFloat(state.price))
        .set('readyDate', state.readyDate)
        .set('dob', state.dob)
    );
    setLoading(false);
  };
  
  return (
    <StyledPageContainer>
      <Column>
        <StyledInputFormField
          label="Name"
          value={state.name}
          onChangeValue={name => dispatch(setName(name))}
        />
        <StyledSelectFormField
          label="Breed"
          placeholder="Pick One..."
          selected={state.breed}
          options={petBreedSelectOptions}
          onSelectOption={breed => dispatch(setBreed(breed))}
        />
        <StyledInputFormField
          label="Price"
          value={state.price}
          onChangeValue={price => dispatch(setPrice(price))}
        />
        <StyledDateFormField
          label="Birth Date"
          value={state.dob}
          onChangeValue={dob => dispatch(setDOB(dob))}
        />
        <StyledDateFormField
          label="Ready Date"
          value={state.readyDate}
          onChangeValue={readyDate => dispatch(setReadyDate(readyDate))}
        />
        <StyledTextAreaFormField
          label="Pup Description"
          value={state.bio}
          onChangeValue={bio => dispatch(setBio(bio))}
        />
        <StyledSwitchFormField
          label="Pending"
          description="Pending status indicates you have received a deposit. A pup profile with pending status will be hidden from the browse feed."
          selected={state.paused}
          onClick={() => dispatch(setPaused(!state.paused))}
        />
        <StyledSwitchFormField
          label="Hypoallergenic"
          description="Hypoallergenic dogs typically do not shed their fur"
          selected={state.hypoallergenic}
          onClick={() => dispatch(setHypoallergenic(!state.hypoallergenic))}
        />
        <StyledSelectFormField
          label="Size"
          placeholder="Pick One..."
          selected={state.size}
          options={petSizeSelectOptions}
          onSelectOption={key => dispatch(setSize(key as EPetSize))}
        />
        <PetProfileDetails
          details={state.details}
          onChangeDetails={details => dispatch(setDetails(details))}
        />
        <ButtonContainer>
          <Button size="large" onClick={onClickCancel}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="primary"
            size="large"
            onClick={onClickSave}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </ButtonContainer>
      </Column>
      <Column>
        <GridContainer>
          <InfoContainer>
            <Text weight="bold">Pictures</Text>
            <Description>
              Upload up to 9 pictures. Max 5mb per image.
            </Description>
          </InfoContainer>
          <UploadInput
            value={state.images.get('0')}
            onChangeValue={async file =>
              dispatch(
                setImages(
                  state.images.set(
                    '0',
                    file
                      ? (await convertImageFileToSupportedFormatLossy(file)) ||
                          null
                      : null
                  )
                )
              )
            }
          />
        </GridContainer>
        <GridContainer>
          <ImageUploadGrid>
            {times(9, key => (
              <ImageUploadGridItem
                key={key}
                value={state.images.get((key + 1).toString())}
                onChangeValue={async file =>
                  dispatch(
                    setImages(
                      state.images.set(
                        (key + 1).toString(),
                        file
                          ? (await convertImageFileToSupportedFormatLossy(
                              file
                            )) || null
                          : null
                      )
                    )
                  )
                }
              />
            ))}
          </ImageUploadGrid>
        </GridContainer>
      </Column>
    </StyledPageContainer>
  );
};
