"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@fido/common");
const result_1 = require("../result");
const buildFirebaseFormatFromFilter = (filter) => ({
    hypoallergenic: filter.hypoallergenic,
    empoweredBreeder: filter.empoweredBreeder,
    cost: filter.cost,
    minMaxValue: filter.minMaxValue,
    size: filter.size,
    breed: filter.breed,
    cuddleCompanion: filter.cuddleCompanion,
    highEnergy: filter.highEnergy,
    kidFriendly: filter.kidFriendly,
    adaptsToApartments: filter.adaptsToApartments,
    sortBy: filter.sortBy,
    location: filter.location
});
const buildFilterFromFirebaseFormat = (data) => common_1.FilterRecord({
    hypoallergenic: data.hypoallergenic,
    empoweredBreeder: data.empoweredBreeder,
    cost: data.cost,
    minMaxValue: data.minMaxValue,
    size: data.size,
    breed: data.breed,
    cuddleCompanion: data.cuddleCompanion,
    highEnergy: data.highEnergy,
    kidFriendly: data.kidFriendly,
    adaptsToApartments: data.adaptsToApartments,
    sortBy: data.sortBy,
    location: data.location
});
exports.updateFilter = (userID, filter) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield common_1.firestore
            .collection('filters')
            .doc(userID)
            .set(buildFirebaseFormatFromFilter(filter));
        return new result_1.Ok(filter);
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
exports.fetchFilter = (userID) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const snapshot = yield common_1.firestore
            .collection('filters')
            .doc(userID)
            .get();
        const data = snapshot.data();
        if (!data) {
            return new result_1.Ok(common_1.FilterRecord());
        }
        const filter = buildFilterFromFirebaseFormat(data);
        return new result_1.Ok(filter);
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
