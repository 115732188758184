"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
const common_1 = require("@fido/common");
exports.SellerPaymentStateRecord = (partial) => {
    var _a, _b;
    return immutable_1.Record({
        sellerPayments: immutable_1.Map(((_b = (_a = partial) === null || _a === void 0 ? void 0 : _a.sellerPayments) === null || _b === void 0 ? void 0 : _b.map(u => common_1.SellerPaymentRecord(u))) || []),
    })();
};
