import {
  calculatePercentMatchValue,
  IPetProfileRecord,
  IUserProfileRecord,
  firestore,
} from '@fido/common';
import {
  IApplicationRecord,
  IMessageRecord,
  EApplicationStatus,
} from '@fido/state';
import { unit } from '@fido/styles';
import {
  Heading,
  isDesktop,
  Stat,
  Table,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRows,
  SelectFormField,
  InputFormField,
} from '@fido/web-components';
import { Map } from 'immutable';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { formatApplicationStatus } from '../../utils';
import { PageContainer } from '../common';

const TableContainer = styled.div``;

const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StatsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${unit * 5}px;
`;

const Line = styled.div`
  border-top: 1px solid #dfe3e8;
`;

const ApplicantsHeading = styled(Heading)`
  margin-bottom: ${unit * 3}px;
`;

const MobileTableRow = styled(TableRow)`
  grid-template-columns: repeat(1, 1fr);
  border: #dfe3e8;
  outline: 0;
  margin-top: 1.5rem;
  width: 95%;
  margin-right: 1rem;
  margin-left: 10px;
  padding: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 0.3rem rgb(0 0 0 / 30%);
  background: #fff;
`;

const AssignPuppyButton = styled.button`
  height: 25px;
  width: 100px;
`;

const ModalView = styled.div`
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  padding: 15px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background: #00000080;
`;

const ContentView = styled.div`
  z-index: 9998;
  position: absolute;
  width: 30%;
  height: 60%;
  top: 20%;
  left: 40%;
  padding-top: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
`;

// Input field
const StyledInputFormField = styled(InputFormField)`
  display: block;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 15px;
  max-width: unset;
`;

// Drop down
const StyledSelectFormField = styled(SelectFormField)`
  width: 100%;
  padding: 0 40px;
  margin: 5px 0 0 0;
`;

const SaveButton = styled.button`
  height: 35px;
  margin: 0 10px 0 0;
`;
const CancelButton = styled.button`
  height: 35px;
  margin: 0 10px 0 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
  padding: 0 40px;
`;

export interface IApplicationsProps {
  userProfiles: Map<string, IUserProfileRecord>;
  petProfiles: Map<string, IPetProfileRecord>;
  applications: Map<string, IApplicationRecord>;
  numberOfMessagesToSeller: number;
  numberOfFavorites: Map<string, number>;
  messages: Map<string, IMessageRecord>;
  loadInitialData(): void;
  loadFavoritedCounts(): void;
  onUpdateApplication(
    applictationID: string,
    application: IApplicationRecord
  ): void;
}

export const Applications = ({
  numberOfMessagesToSeller,
  messages,
  applications,
  userProfiles,
  petProfiles,
  numberOfFavorites,
  loadInitialData,
  loadFavoritedCounts,
  onUpdateApplication,
}: IApplicationsProps) => {
  useEffect(() => {
    loadInitialData();
  }, []);
  useEffect(() => {
    loadFavoritedCounts();
  }, [applications]);

  const totalNumberOfUniqueApplicants = uniqBy(
    applications.valueSeq().toArray(),
    a => a.userID
  ).length;
  const totalNumberOfViews = sum(
    petProfiles
      .map(p => p.views)
      .valueSeq()
      .toArray()
  );
  const [modal, setModal] = useState(false);
  const [puppy, setPuppy] = useState('');
  const [petProfileID, setPetProfileID] = useState('');
  const [applicant, setApplicant] = useState({});
  const [applicantID, setApplicantID] = useState('');
  const [isValid, setValidation] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const totalNumberOfFavorites = sum(numberOfFavorites.valueSeq().toArray());
  const history = useHistory();

  applications = applications.sort(
    (x, y) => +new Date(y.createdAt) - +new Date(x.createdAt)
  );

  const applicantName = (applicant: any) => {
    const applicantUserProfile = userProfiles.get(applicant.userID);
    return applicantUserProfile && applicantUserProfile.name
      ? applicantUserProfile.name
      : applicant.buyerName;
  };

  const resetForm = () => {
    setPuppy('');
    setPetProfileID('');
    setApplicant({});
    setApplicantID('');
    setValidation(true);
    setSubmitting(false);
    setModal(false);
  };

  const pets = petProfiles
    .filter(pet => !pet.sold)
    .map((option, key) => ({
      key: key,
      label: option.name,
      value: option.name,
    }))
    .valueSeq()
    .toArray();

  const updatePetProfile = async () => {
    const snap = await firestore
      .collection('applications')
      .doc(applicantID)
      .get();
    let application = snap.data();
    if (!application) {
      return;
    }
    let updateApplication = {
      userID: application?.userID,
      deposit: application?.deposit,
      petProfileID: petProfileID,
      sellerProfileID: application?.sellerProfileID,
      buyerName: application?.buyerName,
      buyerEmail: application?.buyerEmail,
      createdAt: application?.createdAt,
      status: application?.status,
      sellerReadStatus: application?.sellerReadStatus,
      buyerReadStatus: application?.buyerReadStatus,
      puppyName: puppy,
      createdByLink: true,
    } as IApplicationRecord;
    onUpdateApplication(applicantID, updateApplication);
    resetForm();
  };

  if (isDesktop()) {
    return (
      <PageContainer>
        {modal ? (
          <ModalView>
            <ContentView>
              <Heading style={{ marginBottom: 30 }}>Assign a puppy</Heading>
              <StyledInputFormField
                label="Applicant Name:"
                disabled={true}
                value={applicantName(applicant)}
              />
              <StyledInputFormField
                label="Deposit paid:"
                disabled={true}
                value={applicant?.deposit}
              />
              <StyledSelectFormField
                label="Select a puppy:"
                placeholder="Pick One..."
                selected={puppy}
                options={pets.length > 0 ? Map(
                  pets.map(b => [
                    b?.label,
                    {
                      value: b?.key,
                      label: b?.label,
                    },
                  ])
                ) : undefined}
                onSelectOption={(key, breed) => {
                  setPuppy(breed.label);
                  setPetProfileID(breed?.value);
                }}
              />
              {!isValid ? (
                <Heading
                  style={{
                    marginTop: 5,
                    fontSize: 10,
                    color: 'red',
                    alignSelf: 'flex-start',
                    marginLeft: 40,
                  }}>
                  Select a puppy.
                </Heading>
              ) : null}
              <ButtonContainer>
                <CancelButton onClick={() => resetForm()}>Cancel</CancelButton>
                <SaveButton
                  onClick={() => {
                    if (!puppy) {
                      setValidation(false);
                      return;
                    }
                    setSubmitting(true)
                    updatePetProfile();
                  }}>
                  {submitting ? "Submitting..." : "Save"}
                </SaveButton>
              </ButtonContainer>
            </ContentView>
          </ModalView>
        ) : null}
        <StatsSection>
          <ApplicantsHeading>Stats</ApplicantsHeading>
          <StatContainer>
            <Stat
              size="large"
              label="Applicants"
              value={totalNumberOfUniqueApplicants}
            />
            <Stat size="large" label="Views" value={totalNumberOfViews} />
            <Stat
              size="large"
              label="Messages"
              value={numberOfMessagesToSeller}
            />
            <Stat
              size="large"
              label="Favorites"
              value={totalNumberOfFavorites}
            />
          </StatContainer>
        </StatsSection>
        <TableContainer>
          <ApplicantsHeading>Applicants</ApplicantsHeading>
          <Table>
            <TableHeader>
              <TableHeaderColumn sortable>Name</TableHeaderColumn>
              <TableHeaderColumn>Puppy Profile</TableHeaderColumn>
              <TableHeaderColumn>Match</TableHeaderColumn>
              <TableHeaderColumn>Date Applied</TableHeaderColumn>
              <TableHeaderColumn>Status</TableHeaderColumn>
              <TableHeaderColumn>Messages</TableHeaderColumn>
              <TableHeaderColumn>Assign Puppy</TableHeaderColumn>
            </TableHeader>
            <TableRows>
              {applications
                .map((app, applicationID) => {
                  const applicantUserProfile = userProfiles.get(app.userID);
                  const petProfile = petProfiles.get(app.petProfileID);
                  return (
                    <TableRow
                      key={applicationID}
                      onClick={
                        () =>
                          history.push(`/app/applications/${applicationID}`)
                      }>
                      <TableCell
                        highlighted={
                          app && app.sellerReadStatus === 'read' ? false : true
                        }>
                        {applicantUserProfile && applicantUserProfile.name
                          ? applicantUserProfile.name
                          : app.buyerName}
                      </TableCell>
                      <TableCell>
                        {petProfile && petProfile.name
                          ? petProfile.name
                          : 'General Waitlist'}
                      </TableCell>
                      <TableCell>
                        {applicantUserProfile && petProfile
                          ? calculatePercentMatchValue(
                            applicantUserProfile,
                            petProfile
                          )
                          : '--'}
                      </TableCell>
                      <TableCell>
                        {moment(app.createdAt).format('MMM. DD, YYYY')}
                      </TableCell>
                      <TableCell>
                        {formatApplicationStatus(app.status)}
                      </TableCell>
                      <TableCell>
                        {messages.filter(msg => msg.fromID === app.userID)
                          .size || '--'}
                      </TableCell>
                      <TableCell>
                        {app.status === EApplicationStatus.approved &&
                          (app.petProfileID === 'GENERALWAITLIST' || app.petProfileID === "General Waitlist") ? (
                          <AssignPuppyButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setModal(true);
                              setApplicant(app);
                              setApplicantID(applicationID);
                            }}>
                            Assign a pup
                          </AssignPuppyButton>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })
                .valueSeq()
                .toArray()}
            </TableRows>
          </Table>
        </TableContainer>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer>
        <Heading>Applicants</Heading>
        <TableContainer>
          <Table>
            <TableRows>
              {applications
                .map((app, applicationID) => {
                  const applicantUserProfile = userProfiles.get(app.userID);
                  const petProfile = petProfiles.get(app.petProfileID);
                  return (
                    <MobileTableRow
                      key={applicationID}
                      onClick={() =>
                        history.push(`/app/applications/${applicationID}`)
                      }>
                      <TableCell highlighted>
                        <TableHeaderColumn>Name</TableHeaderColumn>
                        {applicantUserProfile && applicantUserProfile.name
                          ? applicantUserProfile.name
                          : app.buyerName}
                      </TableCell>
                      <Line />
                      <TableCell>
                        <TableHeaderColumn>Puppy Profile</TableHeaderColumn>
                        {petProfile && petProfile.name
                          ? petProfile.name
                          : 'General Waitlist'}
                      </TableCell>
                      <Line />
                      <TableCell>
                        <TableHeaderColumn>Match</TableHeaderColumn>
                        {applicantUserProfile && petProfile
                          ? calculatePercentMatchValue(
                            applicantUserProfile,
                            petProfile
                          )
                          : '--'}
                      </TableCell>
                      <Line />
                      <TableCell>
                        <TableHeaderColumn>Date Applied</TableHeaderColumn>
                        {moment(app.createdAt).format('MMM. DD, YYYY')}
                      </TableCell>
                      <Line />
                      <TableCell>
                        <TableHeaderColumn>Status</TableHeaderColumn>
                        {formatApplicationStatus(app.status)}
                      </TableCell>
                      <Line />
                      <TableCell>
                        <TableHeaderColumn>Messages</TableHeaderColumn>
                        {messages.filter(msg => msg.fromID === app.userID)
                          .size || '--'}
                      </TableCell>
                      <Line />
                    </MobileTableRow>
                  );
                })
                .valueSeq()
                .toArray()}
            </TableRows>
          </Table>
        </TableContainer>
      </PageContainer>
    );
  }
};
