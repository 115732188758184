import { NotificationFromVariant } from '@fido/common';
import {
  createMessage,
  EMessageReadStatus,
  fetchConversations,
  fetchSellerConversations,
  fetchUserProfileByID,
  ICreateMessageRecord,
  selectConversations,
  selectMessages,
  selectUserID,
  selectUserProfiles,
  updateMessageReadStatus,
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { IMessagesProps } from '../Messages';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch, getState: () => IState) => async () => {
    if (!userID) {
      return;
    }
    await dispatch(fetchSellerConversations(userID));
    const conversations = selectConversations(getState());
    conversations.forEach(convo => {
      dispatch(fetchUserProfileByID(convo.userID));
      dispatch(fetchUserProfileByID(convo.sellerProfileID));
    });
  }
);

const selectOnSendMessage = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => async (message: ICreateMessageRecord) => {
    dispatch(createMessage(message, NotificationFromVariant.seller));
  }
);

const selectMarkMessagesAsRead = createSelector(
  selectMessages,
  selectUserID,
  (messages, userID) => (dispatch: Dispatch) => (conversationID: string) => {
    messages
      .filter(msg => msg.conversationID === conversationID)
      .filter(msg => msg.toID === userID)
      .forEach((msg, id) => {
        if (msg.readStatus !== EMessageReadStatus.read) {
          dispatch(updateMessageReadStatus(id, EMessageReadStatus.read));
        }
      });
  }
);

export const selectMessagesProps = (
  dispatch: ThunkDispatch<IState, null, AnyAction>
) => (state: IState): IMessagesProps => ({
  userID: selectUserID(state),
  loadInitialData: dispatch(selectLoadInitialData(state)),
  userProfiles: selectUserProfiles(state),
  conversations: selectConversations(state),
  messages: selectMessages(state),
  onSendMessage: dispatch(selectOnSendMessage(state)),
  markMessagesAsRead: dispatch(selectMarkMessagesAsRead(state)),
});
