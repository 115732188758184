import { EApplicationStatus } from '@fido/state';

export const formatApplicationStatus = (status: EApplicationStatus) => {
  switch (status) {
    case EApplicationStatus.applied:
      return 'Applied';
    case EApplicationStatus.approved:
      return 'Approved';
    case EApplicationStatus.completed:
      return 'Completed';
    case EApplicationStatus.denied:
      return 'Unavailable';
    case EApplicationStatus.applicantCancelled:
      return 'Cancelled';
    case EApplicationStatus.depositPending:
      return 'Deposit Pending';
    case EApplicationStatus.depositReceived:
      return 'Deposit Received';
    case EApplicationStatus.paymentFailed:
      return 'Payment Failed';
  }
};
