import { AnyAction, Dispatch } from 'redux';
import {
  IState,
  setCredential,
  CredentialRecord,
  setAuthLoadingStatus,
  AuthLoadingStatus,
  resetAuthState,
  resetFavoritesState,
  resetFilterState,
  resetMessageState,
  resetPaymentState,
  resetPetProfileState,
  resetSellerProfileState,
  resetUserProfileState,
  IRoleRecord,
  setRole
} from '@fido/state';
import {
  loginWithEmailAndPassword as apiLoginWithEmailAndPassword,
  createUserWithEmailAndPassword as apiCreateUserWithEmailAndPassword,
  logout as apiLogout,
  loginWithFacebook as apiLoginWithFacebook,
} from '@fido/state/lib/api';
import {
  auth,
  firebase,
  createRole,
  fetchRole,
  checkSellerProfileExists,
} from '@fido/common';
import moment from 'moment';
const persistenceLevel = firebase.auth!.Auth.Persistence.LOCAL;

export const logout = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loading }));
  try {
    await apiLogout();
    dispatch(resetAuthState());
    dispatch(resetFavoritesState());
    dispatch(resetFilterState());
    dispatch(resetMessageState());
    dispatch(resetPaymentState());
    dispatch(resetPetProfileState());
    dispatch(resetSellerProfileState());
    dispatch(resetUserProfileState());
  } finally {
    dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loaded }));
  }
};

export const loginWithEmailAndPassword = (
  email: string,
  password: string
) => async (dispatch: Dispatch<AnyAction>, getState: () => IState) => {
  dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loading }));
  try {
    await auth.setPersistence(persistenceLevel);
    const result = await apiLoginWithEmailAndPassword(email, password);
    result.mapError((error: any) => {
      alert(error.message);
    });

    await result.mapAsync(async cred => {
      const credential = cred.user
        ? CredentialRecord({
          uid: cred.user.uid,
          email: cred.user.email || '',
        })
        : undefined;

      if (credential) {
        const result = await fetchRole(credential.uid)

        await result.mapAsync(async role => {
          if (role.userID != '') {
            //Role associated is already done
            role.isSubscribedTo.includes('Breeder')
          } else {
            const sellerProfile = await checkSellerProfileExists(credential.uid)

            await sellerProfile.mapAsync(async profile => {
              if (profile) {
                const roles = ['Breeder']
                let cRole = {
                  isSubscribedTo: roles,
                  userID: credential.uid,
                  currentRole: 'Breeder',
                  createdAt: moment().format(),
                } as IRoleRecord;
                const roleResult = await createRole(credential.uid, cRole);
                await roleResult.mapAsync(async r => {
                  dispatch(
                    setRole({
                      id: r.id,
                      role: r.role
                    })
                  );
                })
              } else {
                const roles = ['Breeder']
                let cRole = {
                  isSubscribedTo: roles,
                  userID: credential.uid,
                  currentRole: 'Breeder',
                  createdAt: moment().format(),
                } as IRoleRecord;
                const roleResult = await createRole(credential.uid, cRole);
                await roleResult.mapAsync(async r => {
                  dispatch(
                    setRole({
                      id: r.id,
                      role: r.role
                    })
                  );
                })
              }
            })
          }
        })
      }
      dispatch(
        setCredential({
          credential,
        })
      );
    })
  } finally {
    dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loaded }));
  }
};

export const createUserWithEmailAndPassword = (
  email: string,
  password: string
) => async (dispatch: Dispatch<AnyAction>, getState: () => IState) => {
  dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loading }));
  try {
    await auth.setPersistence(persistenceLevel);
    const result = await apiCreateUserWithEmailAndPassword(email, password);
    result.mapError((error: any) => {
      if (error.code === 'auth/email-already-in-use') {
        alert(error.message);
      }
    });
    await result.mapAsync(async cred => {
      const credential = cred.user
        ? CredentialRecord({
          uid: cred.user.uid,
          refreshToken: cred.user.refreshToken,
          email: cred.user.email || '',
        })
        : undefined;
      if (credential) {
        const roles = ['Breeder']
        let role = {
          isSubscribedTo: roles,
          userID: credential.uid,
          currentRole: 'Breeder',
          createdAt: moment().format(),
        } as IRoleRecord;
        const roleResult = await createRole(credential.uid, role);
        await roleResult.mapAsync(async r => {
          dispatch(
            setRole({
              id: r.id,
              role: r.role
            })
          );
        })
      }
      dispatch(
        setCredential({
          credential,
        })
      );
    });
  } finally {
    dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loaded }));
  }
};

export const loadSavedCredential = () => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loading }));
  try {
    const unsubscribe: any = await new Promise(resolve => {
      const _unsubscribe = auth.onAuthStateChanged(user => {
        try {
          if (user) {
            const credential = CredentialRecord({
              uid: user.uid,
              email: user.email || '',
            });
            dispatch(
              setCredential({
                credential,
              })
            );
          }
        } finally {
          resolve(_unsubscribe);
        }
      });
    });
    unsubscribe();
  } finally {
    dispatch(setAuthLoadingStatus({ status: AuthLoadingStatus.loaded }));
  }
};

export const loginWithFacebook = () => async (
  dispatch: Dispatch<AnyAction>
) => {
  const provider = new firebase.auth!.FacebookAuthProvider();
  provider.addScope('public_profile');
  provider.addScope('email');
  const popupResult = await auth.signInWithPopup(provider);
  if (!popupResult.credential) {
    return;
  }
  // @ts-ignore
  const token = popupResult.credential.accessToken;
  const result = await apiLoginWithFacebook(token);
  await result.mapAsync(async userCredential => {
    const credential = userCredential.user
      ? CredentialRecord({
        uid: userCredential.user.uid,
        email: userCredential.user.email || '',
      })
      : undefined;
    dispatch(
      setCredential({
        credential,
      })
    );
  });
};
