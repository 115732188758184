"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const authStateTypes_1 = require("./authStateTypes");
exports.authStateReducers = {
    resetAuthState: (state) => authStateTypes_1.AuthStateRecord(),
    setCredential: (state, { payload: { credential } }) => state.set('credential', credential),
    setAuthLoadingStatus: (state, { payload: { status } }) => state.set('authLoadingStatus', status),
};
exports.authStateReducer = reduxHelpers_1.makeReducer(authStateTypes_1.AuthStateRecord(), exports.authStateReducers);
