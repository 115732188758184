import React from 'react';
import styled from 'styled-components';
import { trueWhite } from '@fido/styles';

// @ts-ignore
import backgroundUmageURI from './desktop-login-bg.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${trueWhite};
`;

const ImagePanel = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  width: 50%;
  height: 100%;
  background-image: url(${backgroundUmageURI});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right;
`;

export interface ILoginShellProps {
  children?: React.HTMLProps<HTMLDivElement>['children'];
}

export const LoginShell = ({ children }: ILoginShellProps) => (
  <Container>
    <ContentsContainer>{children}</ContentsContainer>
    <ImagePanel />
  </Container>
);
