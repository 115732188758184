import {
  fetchApplicationsByPetProfile,
  fetchUserProfileByID,
  selectApplications,
  selectPetProfiles,
  selectUserID,
  selectUserProfiles,
  fetchPetProfilesBySeller,
  fetchFavoritesByPetProfile,
  selectFavorites,
  selectMessages,
  updateApplication,
  IApplicationRecord,
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { IApplicationsProps } from '../Applications';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const createPetProfilesBySellerSelector = (sellerID: string) =>
  createSelector(selectPetProfiles, profiles =>
    profiles.filter(profile => profile.sellerProfileID === sellerID)
  );

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch, getState: () => IState) => async () => {
    if (!userID) {
      return;
    }
    await dispatch(fetchPetProfilesBySeller(userID));
    const petProfiles = createPetProfilesBySellerSelector(userID)(getState());
    await Promise.all(
      petProfiles
        .map((p, id) => dispatch(fetchApplicationsByPetProfile(id)))
        .valueSeq()
        .toArray()
    );
    const applications = selectApplications(getState());
    applications.forEach(app => dispatch(fetchUserProfileByID(app.userID)));
  }
);

const selectLoadFavoritedCounts = createSelector(
  selectApplications,
  applications => (dispatch: Dispatch) => () => {
    applications.forEach(application =>
      dispatch(fetchFavoritesByPetProfile(application.petProfileID))
    );
  }
);

const selectNumberOfFavorites = createSelector(
  selectApplications,
  selectFavorites,
  (applications, favorites) =>
    applications.mapEntries(([applicationID, application]) => [
      applicationID,
      favorites.filter(f => f.petProfileID === application.petProfileID).size,
    ])
);

const selectNumberOfMessagesToSeller = createSelector(
  selectMessages,
  selectUserID,
  (messages, userID) => messages.filter(msg => msg.toID === userID).size
);

const selectOnUpdateApplication = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => (
    applicationID: string,
    application: IApplicationRecord
  ) => {
    dispatch(updateApplication(applicationID, application));
  }
)

export const selectApplicationsProps = (dispatch: Dispatch) => (
  state: IState
): IApplicationsProps => ({
  applications: selectApplications(state),
  petProfiles: selectPetProfiles(state),
  userProfiles: selectUserProfiles(state),
  numberOfFavorites: selectNumberOfFavorites(state),
  loadInitialData: dispatch(selectLoadInitialData(state)),
  loadFavoritedCounts: dispatch(selectLoadFavoritedCounts(state)),
  numberOfMessagesToSeller: selectNumberOfMessagesToSeller(state),
  messages: selectMessages(state),
  onUpdateApplication: dispatch(selectOnUpdateApplication(state)),
});
