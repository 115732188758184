"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
exports.desktopBreakpoint = 768;
exports.mobileBreakpoint = 375;
exports.desktop = (styles) => exports.minWidth(exports.desktopBreakpoint, styles);
exports.mobile = (styles) => exports.maxWidth(exports.mobileBreakpoint, styles);
exports.minWidth = (minWidth, styles) => styled_components_1.css `
  @media (min-width: ${minWidth}px) {
    ${styles}
  }
`;
exports.maxWidth = (minWidth, styles) => styled_components_1.css `
  @media (max-width: ${minWidth}px) {
    ${styles}
  }
`;
exports.isDesktop = () => {
    if (typeof window === 'undefined' || !window.matchMedia)
        return false;
    return window.matchMedia(`(min-width: ${exports.desktopBreakpoint}px)`).matches;
};
