"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
var EPetProfilesLoadingStatus;
(function (EPetProfilesLoadingStatus) {
    EPetProfilesLoadingStatus["loading"] = "loading";
    EPetProfilesLoadingStatus["loaded"] = "loaded";
    EPetProfilesLoadingStatus["none"] = "none";
})(EPetProfilesLoadingStatus = exports.EPetProfilesLoadingStatus || (exports.EPetProfilesLoadingStatus = {}));
exports.PetProfileStateRecord = (partial) => {
    var _a, _b;
    return immutable_1.Record({
        profiles: ((_a = partial) === null || _a === void 0 ? void 0 : _a.profiles) || immutable_1.Map(),
        profilesLoadingStatus: ((_b = partial) === null || _b === void 0 ? void 0 : _b.profilesLoadingStatus) || EPetProfilesLoadingStatus.none,
    })();
};
