"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const rolesTypes_1 = require("./rolesTypes");
const result_1 = require("../result");
const firebase_1 = require("../firebase");
const immutable_1 = require("immutable");
const buildRolesFromData = (data) => rolesTypes_1.RoleRecord(data
    ? {
        userID: data.userID,
        currentRole: data.currentRole,
        isSubscribedTo: data.isSubscribedTo,
        createdAt: data.createdAt,
        isBreederApplicationSubmitted: data.isBreederApplicationSubmitted,
        isBreederApproved: data.isBreederApproved
    }
    : {});
exports.fetchRoles = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const profiles = yield firebase_1.firestore
            .collection('userRoles')
            .limit(1000)
            .get();
        const entries = profiles.docs.map((doc) => {
            const data = doc.data();
            return [doc.id, buildRolesFromData(data)];
        });
        return new result_1.Ok(immutable_1.Map(entries));
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
exports.fetchRole = (id) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const snapshot = yield firebase_1.firestore
            .collection('userRoles')
            .doc(id)
            .get();
        const data = snapshot.data();
        return new result_1.Ok(buildRolesFromData(data));
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
exports.updateRole = (userID, profile) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield firebase_1.firestore
            .collection('userRoles')
            .doc(userID)
            .set(profile);
        return new result_1.Ok(profile);
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
exports.createRole = (userID, role) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield firebase_1.firestore.collection('userRoles').doc(userID).set(role);
        return new result_1.Ok({
            id: userID,
            role,
        });
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
