import {
  fetchPetProfilesBySeller,
  selectPetProfiles,
  selectUserID,
  fetchApplicationsByPetProfile,
  selectApplications,
  selectCurrentSellerProfile,
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { IPetProfileProps } from '../PetProfiles';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => {
    if (userID) {
      dispatch(fetchPetProfilesBySeller(userID));
    }
  }
);

const selectLoadApplicationCounts = createSelector(
  selectPetProfiles,
  petProfiles => (dispatch: Dispatch) =>
    petProfiles.forEach((petProfile, petProfileID) => {
      dispatch(fetchApplicationsByPetProfile(petProfileID));
    })
);

const selectNumberOfApplication = createSelector(
  selectPetProfiles,
  selectApplications,
  (petProfiles, applications) =>
    petProfiles.mapEntries(([petProfileID]) => [
      petProfileID,
      applications.filter(app => app.petProfileID === petProfileID).size,
    ])
);

const selectHasCompletedSellerProfile = createSelector(
  selectCurrentSellerProfile,
  sellerProfile => !!(sellerProfile?.name && sellerProfile?.description && sellerProfile?.address && sellerProfile?.paymentSettings.stripePaymentAccountID)
)

export const selectPetProfileProps = (
  dispatch: ThunkDispatch<IState, null, AnyAction>
) => (state: IState): IPetProfileProps => ({
  hasCompletedSellerProfile: selectHasCompletedSellerProfile(state),
  profiles: selectPetProfiles(state),
  numberOfApplication: selectNumberOfApplication(state),
  loadInitialData: () => dispatch(selectLoadInitialData(state)),
  loadApplicationCounts: () => dispatch(selectLoadApplicationCounts(state)),
});
