import {
  createUserWithEmailAndPassword,
  loginWithEmailAndPassword,
  loginWithFacebook,
} from '../../../actionCreators';
import { IState } from '../../../state';
import { ILoginProps } from '../Login';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export const selectLoginProps = (
  dispatch: ThunkDispatch<IState, null, AnyAction>
) => (_state: IState): ILoginProps => ({
  onSubmitLoginWithFacebook: () => dispatch(loginWithFacebook()),
  onSubmitLoginWithEmailAndPassword: async (email: string, password: string) =>
    dispatch(loginWithEmailAndPassword(email, password)),
  onSubmitCreateUserWithEmailAndPassword: async (
    email: string,
    password: string
  ) => dispatch(createUserWithEmailAndPassword(email, password)),
});
