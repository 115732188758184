import { RecordOf, Record, Map } from 'immutable';
import {
  IPetProfileDetailsRecord,
  PetProfileDetailsRecord,
  EPetSex,
  EPetSize,
} from '@fido/common';

export interface IEditPetProfileState {
  name: string;
  breed: string;
  weight: string;
  price: string;
  dob: string;
  bio: string;
  images: Map<string, File | null>;
  paused: boolean;
  hypoallergenic: boolean;
  details: IPetProfileDetailsRecord;
  size?: EPetSize;
  sex?: EPetSex;
  readyDate: string;
}

export type IEditPetProfileStateRecord = RecordOf<IEditPetProfileState>;

export const EditPetProfileStateRecord = (
  partial?: Partial<IEditPetProfileState>
) =>
  Record<IEditPetProfileState>({
    name: partial?.name || '',
    breed: partial?.breed || '',
    weight: partial?.weight || '',
    price: partial?.price || '',
    dob: partial?.dob || '',
    bio: partial?.bio || '',
    images: Map(partial?.images || []),
    paused: !!partial?.paused,
    hypoallergenic: !!partial?.hypoallergenic,
    details: PetProfileDetailsRecord(partial?.details),
    size: partial?.size,
    sex: partial?.sex,
    readyDate: partial?.readyDate || '',
  })();

export enum EEditPetProfileActionType {
  setName = 'setName',
  setBreed = 'setBreed',
  setWeight = 'setWeight',
  setPrice = 'setPrice',
  setDOB = 'setDOB',
  setBio = 'setBio',
  setImages = 'setImages',
  setPaused = 'setPaused',
  setDetails = 'setDetails',
  setHypoallergenic = 'setHypoallergenic',
  setSize = 'setSize',
  setSex = 'setSex',
  setReadyDate = 'setReadyDate',
}

export type EditPetProfileAction =
  | {
      type: EEditPetProfileActionType.setName;
      payload: { name: string };
    }
  | {
      type: EEditPetProfileActionType.setBreed;
      payload: { breed: string };
    }
  | {
      type: EEditPetProfileActionType.setWeight;
      payload: { weight: string };
    }
  | {
      type: EEditPetProfileActionType.setPrice;
      payload: { price: string };
    }
  | {
      type: EEditPetProfileActionType.setDOB;
      payload: { dob: string };
    }
  | {
      type: EEditPetProfileActionType.setBio;
      payload: { bio: string };
    }
  | {
      type: EEditPetProfileActionType.setImages;
      payload: { images: Map<string, File | null> };
    }
  | {
      type: EEditPetProfileActionType.setPaused;
      payload: { paused: boolean };
    }
  | {
      type: EEditPetProfileActionType.setDetails;
      payload: { details: IPetProfileDetailsRecord };
    }
  | {
      type: EEditPetProfileActionType.setHypoallergenic;
      payload: { hypoallergenic: boolean };
    }
  | {
      type: EEditPetProfileActionType.setSex;
      payload: { sex?: EPetSex };
    }
  | {
      type: EEditPetProfileActionType.setSize;
      payload: { size?: EPetSize };
    }
  | {
    type: EEditPetProfileActionType.setReadyDate;
    payload: { readyDate: string };
  };

export const setName = (name: string): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setName,
  payload: {
    name,
  },
});

export const setBreed = (breed: string): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setBreed,
  payload: {
    breed,
  },
});

export const setWeight = (weight: string): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setWeight,
  payload: {
    weight,
  },
});

export const setPrice = (price: string): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setPrice,
  payload: {
    price,
  },
});

export const setDOB = (dob: string): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setDOB,
  payload: {
    dob,
  },
});

export const setBio = (bio: string): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setBio,
  payload: {
    bio,
  },
});

export const setReadyDate = (readyDate: string): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setReadyDate,
  payload: {
    readyDate,
  },
});

export const setImages = (
  images: Map<string, File | null>
): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setImages,
  payload: {
    images,
  },
});

export const setPaused = (paused: boolean): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setPaused,
  payload: {
    paused,
  },
});

export const setDetails = (
  details: IPetProfileDetailsRecord
): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setDetails,
  payload: {
    details,
  },
});

export const setHypoallergenic = (
  hypoallergenic: boolean
): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setHypoallergenic,
  payload: {
    hypoallergenic,
  },
});

export const setSize = (size?: EPetSize): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setSize,
  payload: {
    size,
  },
});

export const setSex = (sex?: EPetSex): EditPetProfileAction => ({
  type: EEditPetProfileActionType.setSex,
  payload: {
    sex,
  },
});

export const editPetProfileReducer = (
  state: IEditPetProfileStateRecord,
  action: EditPetProfileAction
) => {
  switch (action.type) {
    case EEditPetProfileActionType.setName:
      return state.set('name', action.payload.name);
    case EEditPetProfileActionType.setBreed:
      return state.set('breed', action.payload.breed);
    case EEditPetProfileActionType.setWeight:
      return state.set('weight', action.payload.weight);
    case EEditPetProfileActionType.setPrice:
      return state.set('price', action.payload.price);
    case EEditPetProfileActionType.setDOB:
      return state.set('dob', action.payload.dob);
    case EEditPetProfileActionType.setBio:
      return state.set('bio', action.payload.bio);
    case EEditPetProfileActionType.setImages:
      return state.set('images', action.payload.images);
    case EEditPetProfileActionType.setPaused:
      return state.set('paused', action.payload.paused);
    case EEditPetProfileActionType.setDetails:
      return state.set('details', action.payload.details);
    case EEditPetProfileActionType.setHypoallergenic:
      return state.set('hypoallergenic', action.payload.hypoallergenic);
    case EEditPetProfileActionType.setSex:
      return state.set('sex', action.payload.sex);
    case EEditPetProfileActionType.setSize:
      return state.set('size', action.payload.size);
    case EEditPetProfileActionType.setReadyDate:
      return state.set('readyDate', action.payload.readyDate);
    default:
      return state;
  }
};
