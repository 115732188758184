"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
var ELevel;
(function (ELevel) {
    ELevel["low"] = "low";
    ELevel["medium"] = "medium";
    ELevel["high"] = "high";
})(ELevel = exports.ELevel || (exports.ELevel = {}));
exports.PetProfileDetailsRecord = immutable_1.Record({
    kidFriendly: ELevel.medium,
    petFriendly: ELevel.medium,
    dogFriendly: ELevel.medium,
    bondsEasily: ELevel.medium,
    friendlyWithPeople: ELevel.medium,
    toleranceToBeingAlone: ELevel.medium,
    easyToTrain: ELevel.medium,
    drool: ELevel.medium,
    amountOfShedding: ELevel.medium,
    groomingRequired: ELevel.medium,
    energy: ELevel.medium,
    exerciseNeeds: ELevel.medium,
    adaptsToApartmentLife: ELevel.medium,
});
var EPetSize;
(function (EPetSize) {
    EPetSize["small"] = "small";
    EPetSize["medium"] = "medium";
    EPetSize["large"] = "large";
    EPetSize["extraLarge"] = "extraLarge";
})(EPetSize = exports.EPetSize || (exports.EPetSize = {}));
var EPetSex;
(function (EPetSex) {
    EPetSex["male"] = "male";
    EPetSex["female"] = "female";
})(EPetSex = exports.EPetSex || (exports.EPetSex = {}));
var EPetProfileVariant;
(function (EPetProfileVariant) {
    EPetProfileVariant["individual"] = "individual";
    EPetProfileVariant["litter"] = "litter";
})(EPetProfileVariant = exports.EPetProfileVariant || (exports.EPetProfileVariant = {}));
exports.PetProfileRecord = (partial) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    return immutable_1.Record({
        dob: ((_a = partial) === null || _a === void 0 ? void 0 : _a.dob) || '',
        readyDate: ((_b = partial) === null || _b === void 0 ? void 0 : _b.readyDate) || '',
        breed: ((_c = partial) === null || _c === void 0 ? void 0 : _c.breed) || '',
        createdAt: ((_d = partial) === null || _d === void 0 ? void 0 : _d.createdAt) || '',
        sex: ((_e = partial) === null || _e === void 0 ? void 0 : _e.sex) || EPetSex.female,
        name: ((_f = partial) === null || _f === void 0 ? void 0 : _f.name) || '',
        price: ((_g = partial) === null || _g === void 0 ? void 0 : _g.price) || 0,
        sellerProfileID: ((_h = partial) === null || _h === void 0 ? void 0 : _h.sellerProfileID) || '',
        images: immutable_1.List(((_j = partial) === null || _j === void 0 ? void 0 : _j.images) || []),
        description: ((_k = partial) === null || _k === void 0 ? void 0 : _k.description) || '',
        paused: !!((_l = partial) === null || _l === void 0 ? void 0 : _l.paused),
        details: exports.PetProfileDetailsRecord((_m = partial) === null || _m === void 0 ? void 0 : _m.details),
        size: ((_o = partial) === null || _o === void 0 ? void 0 : _o.size) || EPetSize.medium,
        hypoallergenic: ((_p = partial) === null || _p === void 0 ? void 0 : _p.hypoallergenic) || false,
        variant: ((_q = partial) === null || _q === void 0 ? void 0 : _q.variant) || EPetProfileVariant.individual,
        sizeOfLitter: ((_r = partial) === null || _r === void 0 ? void 0 : _r.sizeOfLitter) || 1,
        born: ((_s = partial) === null || _s === void 0 ? void 0 : _s.born) || true,
        sold: ((_t = partial) === null || _t === void 0 ? void 0 : _t.sold) || false,
        views: ((_u = partial) === null || _u === void 0 ? void 0 : _u.views) || 0,
    })();
};
