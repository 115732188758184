"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
const common_1 = require("@fido/common");
exports.FilterStateRecord = (partial) => {
    var _a;
    return immutable_1.Record({
        filter: common_1.FilterRecord((_a = partial) === null || _a === void 0 ? void 0 : _a.filter),
    })();
};
