"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const favoriteStateTypes_1 = require("./favoriteStateTypes");
exports.favoriteStateReducers = {
    resetFavoritesState: (state) => favoriteStateTypes_1.FavoriteStateRecord(),
    setFavorites: (state, { payload: { favorites }, }) => state.set('favorites', favorites),
    setFavorite: (state, { payload: { id, favorite }, }) => state.setIn(['favorites', id], favorite),
    removeFavorite: (state, { payload: { id } }) => state.removeIn(['favorites', id]),
};
exports.favoriteStateReducer = reduxHelpers_1.makeReducer(favoriteStateTypes_1.FavoriteStateRecord(), exports.favoriteStateReducers);
