import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Logo,
  TextButton,
  LoginInput,
  Text,
  ELogoTextStyle,
  Button,
} from '@fido/web-components';
import { unit } from '@fido/styles';

const Separator = styled.div`
  height: 10px;
`;

const TextSpacer = styled.div`
  display: inline-block;
  width: 0.25em;
`;

const LogoContainer = styled.div`
  margin-bottom: ${unit * 10}px;
`;

const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

const fullButtonStyle = {
  width: '100%'
}

export interface LoginWithEmailFormProps {
  onSubmit(email: string, password: string): void;
  onClickResetPassword(email: string): void;
}

export const LoginWithEmailForm = ({
  onSubmit,
  onClickResetPassword,
}: LoginWithEmailFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <>
      <LogoContainer>
        <Logo textStyle={ELogoTextStyle.dark} />
      </LogoContainer>
      <form
        onSubmit={e => {
          onSubmit(email, password);
          e.preventDefault();
        }}>
        <HiddenInput type="submit" />
        <LoginInput
          variant="email"
          inputMode="email"
          placeholder="Email"
          value={email}
          autoFocus
          onChangeValue={setEmail}
        />
        <Separator />
        <LoginInput
          variant="password"
          placeholder="Password"
          value={password}
          autoFocus={false}
          onChangeValue={setPassword}
        />
        <Separator />
        <Button style={fullButtonStyle} variant="primary" size="large" onClick={() => onSubmit(email, password)}>Log In</Button>
      </form>
      <Separator />
      <Text weight="medium">
        Forgot password?
        <TextSpacer />
        <TextButton
          onClick={() => onClickResetPassword(email)}
          variant="primary"
          weight="medium">
          Reset via email
        </TextButton>
      </Text>
    </>
  );
};
