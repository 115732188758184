"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@fido/styles");
const react_1 = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const Text_1 = require("../Text");
const activeStyles = ({ active }) => active
    ? styled_components_1.css `
        opacity: 0.7;
      `
    : styled_components_1.css ``;
const Container = styled_components_1.default.button `
  background: none;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${activeStyles};
  cursor: pointer;
`;
const EditIcon = styled_components_1.default(({ className, style }) => (react_1.default.createElement("svg", { width: "17px", height: "17px", viewBox: "0 0 17 17", className: className, style: style },
    react_1.default.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        react_1.default.createElement("g", { transform: "translate(-798.000000, -281.000000)", fill: "#000000", fillRule: "nonzero" },
            react_1.default.createElement("g", { transform: "translate(799.000000, 281.000000)" },
                react_1.default.createElement("path", { d: "M6.85714286,1.67619048 L1.52380952,1.67619048 C0.345602058,1.67619048 -0.60952381,2.63131634 -0.60952381,3.80952381 L-0.60952381,14.4761905 C-0.60952381,15.6543979 0.345602058,16.6095238 1.52380952,16.6095238 L12.1904762,16.6095238 C13.3686837,16.6095238 14.3238095,15.6543979 14.3238095,14.4761905 L14.3238095,9.14285714 C14.3238095,8.80622644 14.0509164,8.53333333 13.7142857,8.53333333 C13.377655,8.53333333 13.1047619,8.80622644 13.1047619,9.14285714 L13.1047619,14.4761905 C13.1047619,14.9811365 12.6954222,15.3904762 12.1904762,15.3904762 L1.52380952,15.3904762 C1.01886347,15.3904762 0.60952381,14.9811365 0.60952381,14.4761905 L0.60952381,3.80952381 C0.60952381,3.30457775 1.01886347,2.8952381 1.52380952,2.8952381 L6.85714286,2.8952381 C7.19377356,2.8952381 7.46666667,2.62234499 7.46666667,2.28571429 C7.46666667,1.94908358 7.19377356,1.67619048 6.85714286,1.67619048 Z" }),
                react_1.default.createElement("path", { d: "M15.2881413,0.711858756 C16.1573577,1.58107517 16.1573577,2.99035341 15.2881413,3.85956985 L8.05004604,11.0976651 C7.97193018,11.1757809 7.87405292,11.2311981 7.76687886,11.2579916 L4.71925981,12.0198964 C4.27285648,12.1314972 3.86850279,11.7271435 3.98010362,11.2807402 L4.74200838,8.23312114 C4.7688019,8.12594708 4.82421906,8.02806982 4.90233491,7.94995396 L12.1404302,0.711858724 C13.0096466,-0.157357678 14.4189248,-0.157357663 15.2881413,0.711858756 Z M7.30742885,10.1162886 L14.4261444,2.99757302 C14.8192931,2.6044243 14.8192931,1.96700429 14.4261444,1.57385559 C14.0329957,1.18070687 13.3955757,1.18070687 13.002427,1.57385557 L5.8837114,8.69257115 L5.40913892,10.5908611 L7.30742885,10.1162886 Z" }))))))) `
  margin-right: ${styles_1.unit}px;
`;
exports.EditButton = ({ className, style, onClick }) => {
    const [active, setActive] = react_1.useState(false);
    return (react_1.default.createElement(Container, { className: className, style: style, active: active, onClick: onClick, onMouseDown: () => setActive(true), onMouseUp: () => setActive(false) },
        react_1.default.createElement(EditIcon, null),
        react_1.default.createElement(Text_1.Text, null, "Edit")));
};
