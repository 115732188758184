"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("../api");
const sellerProfile_1 = require("../state/sellerProfile");
const state_1 = require("../state");
const common_1 = require("@fido/common");
exports.fetchSellerProfiles = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield common_1.fetchSellerProfiles();
        result.map(profiles => dispatch(state_1.mergeSellerProfiles({
            profiles,
        })));
    }
    finally { }
});
exports.fetchCurrentSellerProfile = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const userID = state_1.selectUserID(getState());
    if (!userID) {
        return;
    }
    dispatch(exports.fetchSellerProfileByID(userID));
});
exports.fetchSellerProfileByID = (sellerProfileID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchSellerProfile(sellerProfileID);
    result.map(profile => dispatch(sellerProfile_1.setSellerProfile({
        id: sellerProfileID,
        profile,
    })));
});
exports.createSellerProfile = (id, sellerProfile) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.createSellerProfile(id, sellerProfile);
    result.map(profile => dispatch(sellerProfile_1.setSellerProfile({
        id,
        profile,
    })));
});
exports.updateSellerProfile = (id, sellerProfile) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.updateSellerProfile(id, sellerProfile);
    result.map(profile => dispatch(sellerProfile_1.setSellerProfile({
        id,
        profile,
    })));
});
exports.updateSellerBankAccount = (id, stripeToken) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.updateSellerBankAccount(id, stripeToken);
    result.map(bankAccount => dispatch(sellerProfile_1.setSellerBankAccount({ id, bankAccount })));
});
exports.fetchSellerBankAccount = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield common_1.fetchSellerBankAccount(id);
    result.map(bankAccount => dispatch(sellerProfile_1.setSellerBankAccount({ id, bankAccount })));
});
exports.fetchCurrentSellerBankAccount = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const userID = state_1.selectUserID(getState());
    if (!userID) {
        return;
    }
    const result = yield common_1.fetchSellerBankAccount(userID);
    result.map(bankAccount => dispatch(sellerProfile_1.setSellerBankAccount({ id: userID, bankAccount })));
});
