import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { unit } from '@fido/styles';
import {
  Text,
  Heading,
  Button,
  InputFormField,
  SelectFormField,
  isDesktop
} from '@fido/web-components';
import { gray } from '@fido/styles';
import { PageContainer } from '../common';
import {
  EApplicationStatus
} from '@fido/state';
import moment from 'moment';
import {
  IUserProfileRecord,
  IPetProfileRecord,
  ISellerProfileRecord,
  UserProfileLocationRecord,
  fetchUserProfile as apiFetchUserProfile,
  updateUserProfile as apiUpdateUserProfile,
} from '@fido/common';
import { Map } from 'immutable';
import { every } from 'lodash';
import { useHistory } from 'react-router';
import {baseUrl} from '../../config'

const Container = styled.div`
  padding: ${unit * 0}px;
`;

const ControlsContainer = styled.div`
  padding-bottom: ${unit * 4}px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MobileFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormColumn = styled.div`
  padding: ${unit * 3}px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const StyledInputFormField = styled(InputFormField)`
  padding: ${unit}px 0;
  max-width: 100%;
`;

const StyledSelectFormField = styled(SelectFormField)`
  padding: ${unit}px 0;
  max-width: 100%;
`;

const GENERALWAITLIST = 'GENERALWAITLIST';
const validateName = (userName: string): boolean => !!userName;
const validateAddress = (sAddress: string): boolean => !!sAddress;
const validateEmail = (sEmail: string): boolean => !!sEmail;
const validateBreed = (sBreed: string): boolean => !!sBreed;
const validateDeposit = (sDeposit: string): boolean => !!sDeposit;

const pro: IProfile = {
  id: GENERALWAITLIST,
  name: 'General Waitlist',
};

const validateForm = (fields: {
  userName: string;
  sAddress: string;
  sEmail: string;
  sBreed: string;
  sDeposit: string;
}) =>
  every([
    validateName(fields.userName),
    validateAddress(fields.sAddress),
    validateEmail(fields.sEmail),
    validateBreed(fields.sBreed),
    validateDeposit(fields.sDeposit),
  ]);

export interface IProfile {
  id: string;
  name: string;
}

export interface IUserProfileLocation {
  city: string;
  state: string;
}

export interface ISelectOption {
  label: string;
}

export interface ICreateUnbornPetProfileProps {
  userID?: string;
  sellerProfile?: ISellerProfileRecord;
  userProfile?: IUserProfileRecord;
  petProfiles: Map<string, IPetProfileRecord>;
  loadInitialData(): void;
  loadApplicationCounts(): void;
  createApplication(
    petProfileID: string,
    sellerProfileID: string,
    deposit: string,
    status: EApplicationStatus,
    buyerName: string,
    buyerEmail: string,
    puppyName: string,
    createdByLink: boolean
  ): void;
}

export const CreateApplicantProfile = ({
  userID,
  userProfile,
  sellerProfile,
  petProfiles,
  loadInitialData,
  loadApplicationCounts,
  createApplication,
}: ICreateUnbornPetProfileProps) => {
  
  const history = useHistory();
  
  useEffect(() => {
    loadInitialData();
  }, []);
  useEffect(() => {
    loadApplicationCounts();
  }, [petProfiles]);
  useEffect(() => {
    loadProfiles();
  }, []);

  const [userName, setUsername] = useState('');
  const [sAddress, setAddress] = useState('');
  const [sEmail, setEmail] = useState('');
  const [sDeposit, setDeposit] = useState('');
  const [sBreed, setBreed] = useState('');
  const [sBreedName, setBreedName] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [showValidStates, setShowValidStates] = useState(false);
  
  const onChangeName = (name: string) => {
    setUsername(name);
  };

  const onChangeAddress = (address: string) => {
    setAddress(address);
  };

  const onChangeEmail = (email: string) => {
    setEmail(email);
  };

  const onChangeBreed = (breed: string, breedName:Map<K, ISelectOption>) => {
    setBreed(breed);
    setBreedName(breedName?.label);
  };

  const onChangeDeposit = (deposit: string) => {
    setDeposit(deposit);
  };

  const valid = validateForm({
    userName,
    sAddress,
    sEmail,
    sBreed,
    sDeposit,
  });

  const onClickDone = () => {
    setShowValidStates(true);
    if (valid) {
      if (
        sellerProfile &&
        sellerProfile.paymentSettings.stripePaymentAccountID === ''
      ) {
        alert('Complete your account setup, before you create an application.');
        return;
      } else {
        setDisableBtn(true);
        createUser(sEmail, 'Abcd@1234');
      } 
    }
  };

  const loadProfiles = () => {
   
    setProfiles(profiles => [...profiles, pro]);
    
    petProfiles
      .filter(profile => !profile.sold)
      .mapEntries(([profileID, profile]) => [
        profileID,
        {
          name: profile.name,
          profileID,
        },
      ])
      .valueSeq()
      .map(profile => fetchPetProfiles(profile))
      .toArray();
  };

  const fetchPetProfiles = profile => {
    let pro: IProfile = {
      id: profile.profileID,
      name: profile.name,
    };
    setProfiles(profiles => [...profiles, pro]);
  };

  const createUser = async (email: string, password: string) => {
    const params = {
      email: email,
      password: password,
    };
    try {
      await fetch(
        `${baseUrl}/createUser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      )
        .then(response => response.json())
        .then(json => {
          if(json.response.code === 200) {
            const userId = json.response.uid;
            if (userId !== '') {
              createUserProfile(userId);
            } 
          }
          if (json.response.code === 500) {
            const error = json.response.error;
            if (error.code === 'auth/email-already-exists') {
              fetchUserDetails(email)
            }
            if (error.code === 'auth/invalid-email') {
              alert(error.message);
            }
          }
        })
        .catch(() => {
          alert('Something went wrong, please try again.');
        });
    } catch (error) {
      alert('Something went wrong, please try again.');
    }
  };

  const fetchUserDetails = async (email: string) => {
    const params = {
      emailAdress: email
    };

    try {
      await fetch(`${baseUrl}/fetchUserDetails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
      .then((response) => response.json())
      .then((json) => {
        const uid = json.body.buyerUser.uid;
        if (uid !== '') {
          checkUserProfileExist(uid)
        } else {
          alert('Something went wrong, please try again.');
        }
      })
      .catch((error) => console.log(error))
    } catch (error) {
    }
  }

  const checkUserProfileExist = async (usrId: string) => {
    const result = await apiFetchUserProfile(usrId);
    result.mapError((error: any) => {
      alert('Something went wrong, please try again.');
    });
    await result.mapAsync(async profile => {
      profile.name === ''
        ? createUserProfile(usrId)
        : createApplicant(sBreed, usrId);
    });
  }

  const createUserProfile = (buyerUsrId: string) => {
    const location = UserProfileLocationRecord({
      city: '',
      state: ''
    });
    let profile = {
      imageURL: '',
      name: userName,
      location: location,
      cellNumber: '',
      enableSms: false,
      bio: '',
      kids: false,
      address: sAddress,
      otherPets: true,
      otherDogs: false,
      yardOrPark: false,
      apartment: false,
      busyLifestyle: false,
      activeLifestyle: false,
      socialButterfly: false,
      solitary: false,
      neatFreak: false,
      createdAt: moment().format(),
    } as IUserProfileRecord;
    createProfile(buyerUsrId, profile);
  };

  const createProfile = async (
    buyerUsrId: string,
    profile: IUserProfileRecord
  ) => {
    const result = await apiUpdateUserProfile(buyerUsrId, profile);
    result.mapError((error: any) => {
      alert('Something went wrong, please try again.');
    });
    await result.mapAsync(async profile => {
      createApplicant(sBreed, buyerUsrId);
    });
  };
  
  const createApplicant = (
    petProfileId: string,
    buyerUsrId: string
  ) => {
    const createdByLink = false
    createApplication(
      petProfileId,
      buyerUsrId,
      sDeposit,
      EApplicationStatus.depositPending,
      userName,
      sEmail,
      sBreedName,
      createdByLink
    );
    setDisableBtn(false);
    history.replace('/app/applications');
  };

  const petBreedSelectOptions = Map(
    profiles.map(p => [
      p.id,
      {
        label: p.name,
      },
    ])
  );

  if(isDesktop()) {
    return (
      <PageContainer>
        <Heading>Applicant Information</Heading>
        <FormContainer>
          <FormColumn>
            <StyledInputFormField
              label="Name"
              placeholder="Name"
              value={userName}
              valid={showValidStates ? validateName(userName) : true}
              onChangeValue={onChangeName}
            />
            <StyledInputFormField
              label="Address"
              placeholder="Address"
              value={sAddress}
              valid={showValidStates ? validateAddress(sAddress) : true}
              onChangeValue={onChangeAddress}
            />
            <StyledInputFormField
              label="Email"
              placeholder="Email"
              value={sEmail}
              valid={showValidStates ? validateEmail(sEmail) : true}
              onChangeValue={onChangeEmail}
            />
          </FormColumn>
          <FormColumn>
            <Heading>Listing or Waitlist</Heading>
            <Text color={gray} weight={'regular'}>
              Select which listing or waitlist you would like to assign this
              applicant to.
            </Text>
            <StyledSelectFormField
              placeholder="Pick One..."
              selected={sBreed}
              valid={showValidStates ? validateBreed(sBreed) : true}
              options={petBreedSelectOptions}
              onSelectOption={onChangeBreed}
            />
            <StyledInputFormField
              label="Reservation Fee"
              value={sDeposit}
              placeholder="$0"
              valid={showValidStates ? validateDeposit(sDeposit) : true}
              onChangeValue={onChangeDeposit}
            />
            <Container>
              <ControlsContainer>
                <input name="remember" type="checkbox" defaultChecked />
                <Text color={gray} weight={'regular'}>
                  Request deposit payment from applicant. Buyer will be notified
                  of this request. Once the payment is completed, funds will be
                  transfered to your account within 48 hours.
                </Text>
              </ControlsContainer>
            </Container>
          </FormColumn>
        </FormContainer>
        <Button
          disabled={disableBtn}
          onClick={onClickDone}
          variant="primary"
          size="large">
          {disableBtn ? 'Submitting' : 'Done'}
        </Button>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer>
        <Heading>Applicant Information</Heading>
        <MobileFormContainer>
          <FormColumn>
            <StyledInputFormField
              label="Name"
              placeholder="Name"
              value={userName}
              valid={showValidStates ? validateName(userName) : true}
              onChangeValue={onChangeName}
            />
            <StyledInputFormField
              label="Address"
              placeholder="Address"
              value={sAddress}
              valid={showValidStates ? validateAddress(sAddress) : true}
              onChangeValue={onChangeAddress}
            />
            <StyledInputFormField
              label="Email"
              placeholder="Email"
              value={sEmail}
              valid={showValidStates ? validateEmail(sEmail) : true}
              onChangeValue={onChangeEmail}
            />
          </FormColumn>
          <FormColumn>
            <Heading>Listing or Waitlist</Heading>
            <Text color={gray} weight={'regular'}>
              Select which listing or waitlist you would like to assign this
              applicant to.
            </Text>
            <StyledSelectFormField
              placeholder="Pick One..."
              selected={sBreed}
              valid={showValidStates ? validateBreed(sBreed) : true}
              options={petBreedSelectOptions}
              onSelectOption={onChangeBreed}
            />
            <StyledInputFormField
              label="Deposit"
              value={sDeposit}
              placeholder="$0"
              valid={showValidStates ? validateDeposit(sDeposit) : true}
              onChangeValue={onChangeDeposit}
            />
            <Container>
              <ControlsContainer>
                <input name="remember" type="checkbox" defaultChecked />
                <Text color={gray} weight={'regular'}>
                  Request deposit payment from applicant. Buyer will be notified
                  of this request. Once the payment is completed, funds will be
                  transfered to your account within 48 hours.
                </Text>
              </ControlsContainer>
            </Container>
          </FormColumn>
        </MobileFormContainer>
        <Button
          disabled={disableBtn}
          onClick={onClickDone}
          variant="primary"
          size="large">
            {disableBtn ? 'Submitting' : 'Done'}
        </Button>
      </PageContainer>
    );
  }
};
