"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@fido/styles");
const styled_components_1 = __importStar(require("styled-components"));
const constants_1 = require("../../constants");
const validCss = (props) => props.valid
    ? styled_components_1.css `
        box-shadow: 0px 0px 0px 0px ${styles_1.gray};
        border: 1px solid ${styles_1.gray};
      `
    : styled_components_1.css `
        box-shadow: 0px 0px 2px 1px ${styles_1.warmPink};
        border: 1px solid ${styles_1.warmPink};
      `;
exports.InputOutline = styled_components_1.default.div `
  width: 100%;
  max-width: ${constants_1.defaultFormFieldWidth}px;
  padding: ${styles_1.unit}px;
  box-sizing: border-box;
  transition: 0.2s ease-in-out all;

  ${validCss}

  input {
    width: 100%;
  }
`;
