"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@fido/common");
const immutable_1 = require("immutable");
const result_1 = require("../result");
const buildApplicationFromFirebaseFormat = (data) => common_1.SellerPaymentRecord({
    createdAt: data.createdAt,
    paymentDate: data.paymentDate,
    amount: data.amount,
    appID: data.appId,
    userID: data.userID,
    petProfileID: data.petProfileID,
    paymentStatus: data.paymentStatus,
    paymentType: data.paymentType,
    petName: data.petName,
    buyerName: data.buyerName,
    sellerID: data.sellerID,
    transactionID: data.transactionID,
});
exports.fetchSellerPaymentsBy = (sellerID) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const sellerPayments = yield common_1.firestore
            .collection('seller-payments')
            .where('sellerID', '==', sellerID)
            .get();
        const entries = sellerPayments.docs.map((doc) => {
            const data = doc.data();
            return [doc.id, buildApplicationFromFirebaseFormat(data)];
        });
        return new result_1.Ok(immutable_1.Map(entries));
    }
    catch (error) {
        return new result_1.Err(error);
    }
});
