import {
  selectUserID,
  fetchUserProfile,
  fetchCurrentSellerProfile,
  selectUserProfile,
  selectCurrentSellerProfile,
  updateSellerProfile,
  fetchCurrentSellerBankAccount,
  selectCurrentSellerBankAccount,
  updateSellerBankAccount,
  updateUserProfile,
  selectCurrentRole,
  fetchRoleByID,
  IRoleRecord,
  updateRole,
} from '@fido/state';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IState } from '../../../state';
import { IAccountProps } from '../Account';
import { ISellerProfileRecord, IUserProfileLocationRecord } from '@fido/common';

type Dispatch = ThunkDispatch<IState, null, AnyAction>;

const selectLoadInitialData = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => {
    if (userID) dispatch(fetchRoleByID(userID));
    dispatch(fetchUserProfile());
    dispatch(fetchCurrentSellerProfile());
    dispatch(fetchCurrentSellerBankAccount());
  }
);

const selectUpdateSellerProfile = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => (sellerProfile: ISellerProfileRecord) => {
    if (!userID) {
      return;
    }
    dispatch(updateSellerProfile(userID, sellerProfile));
  }
);

const selectUpdateLocation = createSelector(
  selectUserID,
  selectUserProfile,
  (userID, userProfile) => (dispatch: Dispatch) => (
    location: IUserProfileLocationRecord
  ) => {
    if (!userID || !userProfile) {
      return;
    }
    dispatch(updateUserProfile(userID, userProfile.set('location', location)));
  }
);

const selectUpdatePaymentDetails = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => (stripeToken: string) => {
    if (!userID) {
      return;
    }
    dispatch(updateSellerBankAccount(userID, stripeToken));
  }
);

const selectUpdateUserRole = createSelector(
  selectUserID,
  userID => (dispatch: Dispatch) => (role: IRoleRecord) =>  
   userID && dispatch(updateRole(userID, role))
);

export const selectAccountProps = (dispatch: Dispatch) => (
  state: IState
): IAccountProps => ({
  userID: selectUserID(state),
  role: selectCurrentRole(state),
  userProfile: selectUserProfile(state),
  sellerProfile: selectCurrentSellerProfile(state),
  sellerBankAccount: selectCurrentSellerBankAccount(state),
  loadInitialData: () => dispatch(selectLoadInitialData(state)),
  updateSellerProfile: dispatch(selectUpdateSellerProfile(state)),
  updatePaymentDetails: dispatch(selectUpdatePaymentDetails(state)),
  updateLocation: dispatch(selectUpdateLocation(state)),
  updateRole: dispatch(selectUpdateUserRole(state)),
});
