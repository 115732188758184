import { IPetProfileRecord, IUserProfileRecord, firebase, IRoleRecord } from '@fido/common';
import { IApplicationRecord, IMessageRecord } from '@fido/state';
import { Map } from 'immutable';
import React, { useEffect } from 'react';
import {
  useParams,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { ViewPetProfile, EditPetProfile } from './components';

const analytics = firebase.analytics();
const petProfileSoldEvent = (profile: IPetProfileRecord) => {
  logAnalyticEvent('puppy_profile_sold', profile)
};
const petProfileDeleteEvent = (profile: IPetProfileRecord) => {
  logAnalyticEvent('puppy_profile_delete', profile);
};
const logAnalyticEvent = (eventName: string, profile: IPetProfileRecord) => {
  analytics.logEvent(eventName, {
    pet_name: profile.name,
    breed: profile.breed,
    price: profile.price,
    dob: profile.dob,
  });
};
export interface IPetProfileProps {
  userID?: string;
  messages: Map<string, IMessageRecord>;
  profiles: Map<string, IPetProfileRecord>;
  userProfiles: Map<string, IUserProfileRecord>;
  applications: Map<string, IApplicationRecord>;
  numberOfFavorites: number;
  numberOfApplicants: number;
  numberOfMessages: number;
  numberOfViews: number;
  loadInitialData(petProfileID: string): void;
  onSavePetProfile(petProfileID: string, petProfile: IPetProfileRecord): void;
  onDeletePetProfile(petProfileID: string): void;
  onMarkPetProfileAsSold(petProfileID: string): void;
  onDeleteFavoriteProfile(petProfileID: string): void;
}

export const PetProfile = ({
  userID,
  messages,
  profiles,
  userProfiles,
  applications,
  numberOfFavorites,
  numberOfApplicants,
  numberOfMessages,
  numberOfViews,
  loadInitialData,
  onSavePetProfile,
  onDeletePetProfile,
  onMarkPetProfileAsSold,
  onDeleteFavoriteProfile,
}: IPetProfileProps) => {
  const { id: petProfileID } = useParams<{ id: string }>();
  useEffect(() => {
    loadInitialData(petProfileID);
  }, [petProfileID]);
  const profile = profiles.get(petProfileID);
  const { path } = useRouteMatch();
  const history = useHistory();
  return (
    <Switch>
      <Route path={`${path}/edit`}>
        {profile && userID && (
          <EditPetProfile
            profile={profile}
            userID={userID}
            onCancel={() => history.goBack()}
            onSavePetProfile={petProfile => {
              onSavePetProfile(petProfileID, petProfile);
              history.goBack();
            }}
          />
        )}
      </Route>
      <Route path={`${path}/`}>
        {profile && (
          <ViewPetProfile
            messages={messages}
            profile={profile}
            petProfileID={petProfileID}
            userProfiles={userProfiles}
            applications={applications}
            numberOfFavorites={numberOfFavorites}
            numberOfApplicants={numberOfApplicants}
            numberOfMessages={numberOfMessages}
            numberOfViews={numberOfViews}
            onPressDeleteProfile={() => {
              onDeletePetProfile(petProfileID);
              petProfileDeleteEvent(profile)
              history.replace('/app/pet-profiles')
            }}
            onPressMarkProfileAsSold={() => {
              onDeleteFavoriteProfile(petProfileID)
              onMarkPetProfileAsSold(petProfileID)
              petProfileSoldEvent(profile)
              history.replace('/app/pet-profiles')
            }}
          />
        )}
      </Route>
    </Switch>
  );
};


