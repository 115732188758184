import { RecordOf, Record, Map } from 'immutable';
import {
  IPetProfileDetailsRecord,
  PetProfileDetailsRecord,
  EPetSize,
  EPetSex,
} from '@fido/common';

export interface ICreatePuppyProfileState {
  name: string;
  breed: string;
  size?: EPetSize;
  sex?: EPetSex;
  price: string;
  dob: string;
  readyDate: string;
  bio: string;
  hypoallergenic: boolean;
  images: Map<string, File | null>;
  details: IPetProfileDetailsRecord;
}

export type ICreatePuppyProfileStateRecord = RecordOf<ICreatePuppyProfileState>;

export const CreatePuppyProfileStateRecord = (
  partial?: ICreatePuppyProfileState
) =>
  Record<ICreatePuppyProfileState>({
    name: partial?.name || '',
    breed: partial?.breed || '',
    size: partial?.size,
    sex: partial?.sex,
    price: partial?.price || '',
    dob: partial?.dob || '',
    readyDate: partial?.readyDate || '',
    bio: partial?.bio || '',
    hypoallergenic: !!partial?.hypoallergenic,
    images: Map(partial?.images || []),
    details: PetProfileDetailsRecord(partial?.details),
  })();

export enum ECreatePuppyProfileActionType {
  setName = 'setName',
  setBreed = 'setBreed',
  setSize = 'setSize',
  setPrice = 'setPrice',
  setDOB = 'setDOB',
  setReadyDate = 'setReadyDate',
  setBio = 'setBio',
  setImages = 'setImages',
  setDetails = 'setDetails',
  setSex = 'setSex',
  setHypoallergenic = 'setHypoallergenic',
}

export type CreatePuppyProfileAction =
  | {
      type: ECreatePuppyProfileActionType.setName;
      payload: { name: string };
    }
  | {
      type: ECreatePuppyProfileActionType.setBreed;
      payload: { breed: string };
    }
  | {
      type: ECreatePuppyProfileActionType.setSize;
      payload: { size?: EPetSize };
    }
  | {
      type: ECreatePuppyProfileActionType.setPrice;
      payload: { price: string };
    }
  | {
      type: ECreatePuppyProfileActionType.setDOB;
      payload: { dob: string };
    }
  | {
      type: ECreatePuppyProfileActionType.setReadyDate;
      payload: { readyDate: string };
    }
  | {
      type: ECreatePuppyProfileActionType.setBio;
      payload: { bio: string };
    }
  | {
      type: ECreatePuppyProfileActionType.setImages;
      payload: { images: Map<string, File | null> };
    }
  | {
      type: ECreatePuppyProfileActionType.setDetails;
      payload: { details: IPetProfileDetailsRecord };
    }
  | {
      type: ECreatePuppyProfileActionType.setSex;
      payload: { sex?: EPetSex };
    }
  | {
      type: ECreatePuppyProfileActionType.setHypoallergenic;
      payload: { hypoallergenic: boolean };
    };

export const setName = (name: string): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setName,
  payload: {
    name,
  },
});

export const setBreed = (breed: string): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setBreed,
  payload: {
    breed,
  },
});

export const setSize = (size?: EPetSize): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setSize,
  payload: {
    size,
  },
});

export const setPrice = (price: string): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setPrice,
  payload: {
    price,
  },
});

export const setDOB = (dob: string): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setDOB,
  payload: {
    dob,
  },
});

export const setReadyDate = (readyDate: string): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setReadyDate,
  payload: {
    readyDate,
  },
});

export const setBio = (bio: string): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setBio,
  payload: {
    bio,
  },
});

export const setSex = (sex?: EPetSex): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setSex,
  payload: {
    sex,
  },
});

export const setHypoallergenic = (
  hypoallergenic: boolean
): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setHypoallergenic,
  payload: {
    hypoallergenic,
  },
});

export const setImages = (
  images: Map<string, File | null>
): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setImages,
  payload: {
    images,
  },
});

export const setDetails = (
  details: IPetProfileDetailsRecord
): CreatePuppyProfileAction => ({
  type: ECreatePuppyProfileActionType.setDetails,
  payload: {
    details,
  },
});

export const createPuppyProfileReducer = (
  state: ICreatePuppyProfileStateRecord,
  action: CreatePuppyProfileAction
) => {
  switch (action.type) {
    case ECreatePuppyProfileActionType.setName:
      return state.set('name', action.payload.name);
    case ECreatePuppyProfileActionType.setBreed:
      return state.set('breed', action.payload.breed);
    case ECreatePuppyProfileActionType.setSize:
      return state.set('size', action.payload.size);
    case ECreatePuppyProfileActionType.setPrice:
      return state.set('price', action.payload.price);
    case ECreatePuppyProfileActionType.setDOB:
      return state.set('dob', action.payload.dob);
    case ECreatePuppyProfileActionType.setReadyDate:
      return state.set('readyDate', action.payload.readyDate);
    case ECreatePuppyProfileActionType.setBio:
      return state.set('bio', action.payload.bio);
    case ECreatePuppyProfileActionType.setImages:
      return state.set('images', action.payload.images);
    case ECreatePuppyProfileActionType.setDetails:
      return state.set('details', action.payload.details);
    case ECreatePuppyProfileActionType.setSex:
      return state.set('sex', action.payload.sex);
    case ECreatePuppyProfileActionType.setHypoallergenic:
      return state.set('hypoallergenic', action.payload.hypoallergenic);
    default:
      return state;
  }
};
