import React, { useState } from 'react';
import {
  Text,
  Button,
  PetProfileButton,
  Heading,
  EPetProfileButtonVariant,
  desktop,
} from '@fido/web-components';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { CreatePuppyProfile, CreateLitterProfile } from './components';
import styled, { css } from 'styled-components';
import { unit } from '@fido/styles';
import { PageContainer } from '../common';
import { IPetProfileRecord, firebase, ISellerProfileRecord } from '@fido/common';

const PageContents = styled.div`
  display: flex;
  flex: 1;
  padding: ${unit * 3}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeadingContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${unit * 4}px 0;

  & > button {
    &:first-child {
      margin-bottom: ${unit * 4}px;
    }
  }

  ${desktop(css`
    flex-direction: row;

    & > button {
      &:first-child {
        margin: 0;
        margin-right: ${unit * 4}px;
      }
    }
  `)}
`;
const analytics = firebase.analytics();
const petProfileCreatedEvent = (profile: IPetProfileRecord) => {
  logAnalyticEvent('puppy_profile_created', profile, 'puppy')
};
const litterProfileCreatedEvent = (profile: IPetProfileRecord) => {
  logAnalyticEvent('litter_profile_created', profile, 'litter');
};
const logAnalyticEvent = (
  eventName: string,
  profile: IPetProfileRecord,
  type: string
) => {
  analytics.logEvent(eventName, {
    profileType: type,
    pet_name: profile.name,
    breed: profile.breed,
    price: profile.price,
    dob: profile.dob,
  });
};

export interface ICreatePetProfileProps {
  userID?: string;
  sellerProfile?: ISellerProfileRecord;
  onSavePetProfile(partialProfile: Partial<IPetProfileRecord>): void;
}

export const CreatePetProfile = ({
  userID,
  sellerProfile,
  onSavePetProfile,
}: ICreatePetProfileProps) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [profileVariant, setProfileVariant] = useState(
    EPetProfileButtonVariant.individual
  );
  return (
    <Switch>
      <Route path={`${path}/profile`}>
        {userID && (
          <CreatePuppyProfile
            userID={userID}
            sellerProfile={sellerProfile}
            onClickSave={profile => {
              onSavePetProfile(profile);
              petProfileCreatedEvent(profile)
              history.push(`/app/pet-profiles`);
            }}
          />
        )}
      </Route>
      <Route path={`${path}/litter`}>
        {userID && (
          <CreateLitterProfile
            userID={userID}
            sellerProfile={sellerProfile}
            onClickSave={profile => {
              onSavePetProfile(profile);
              litterProfileCreatedEvent(profile)
              history.push(`/app/pet-profiles`);
            }}
          />
        )}
      </Route>
      <Route path={`${path}/`}>
        <PageContainer>
          <PageContents>
            <HeadingContainer>
              <Heading>Select profile type</Heading>
            </HeadingContainer>
            <ButtonContainer>
              <PetProfileButton
                variant="individual"
                selected={
                  profileVariant === EPetProfileButtonVariant.individual
                }
                onClick={() =>
                  setProfileVariant(EPetProfileButtonVariant.individual)
                }
              />
              <PetProfileButton
                variant="litter"
                selected={profileVariant === EPetProfileButtonVariant.litter}
                onClick={() =>
                  setProfileVariant(EPetProfileButtonVariant.litter)
                }
              />
            </ButtonContainer>
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                profileVariant === EPetProfileButtonVariant.individual
                  ? history.push(`${path}/profile`)
                  : history.push(`${path}/litter`);
              }}>
              Next
            </Button>
          </PageContents>
        </PageContainer>
      </Route>
      <Route
        render={props => (
          <Redirect
            to={{
              pathname: `${path}/`,
              state: { from: props.location },
            }}
          />
        )}
      />
    </Switch>
  );
};
