"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const petProfile_1 = require("./petProfile");
exports.calculatePercentMatchValue = (userProfile, petProfile) => {
    var _a, _b, _c, _d;
    const userHasRequiredFields = !!(((_a = userProfile) === null || _a === void 0 ? void 0 : _a.name) && ((_b = userProfile) === null || _b === void 0 ? void 0 : _b.location.city) && ((_c = userProfile) === null || _c === void 0 ? void 0 : _c.location.state) && ((_d = userProfile) === null || _d === void 0 ? void 0 : _d.bio));
    if (!userHasRequiredFields) {
        return 0;
    }
    let score = 100;
    score -= userProfile.kids
        ? 4 * positiveAttributeScore(petProfile.details.kidFriendly)
        : 0;
    score -= userProfile.otherPets
        ? 3 * positiveAttributeScore(petProfile.details.petFriendly)
        : 0;
    score -= userProfile.otherDogs
        ? 3 * positiveAttributeScore(petProfile.details.dogFriendly)
        : 0;
    score -= userProfile.solitary
        ? positiveAttributeScore(petProfile.details.bondsEasily)
        : 0;
    score -= userProfile.socialButterfly
        ? 2 * positiveAttributeScore(petProfile.details.friendlyWithPeople)
        : 0;
    score -= userProfile.busyLifestyle
        ? 2 * positiveAttributeScore(petProfile.details.toleranceToBeingAlone)
        : 0;
    score -= userProfile.busyLifestyle
        ? positiveAttributeScore(petProfile.details.easyToTrain)
        : 0;
    score -= userProfile.neatFreak
        ? negativeAttributeScore(petProfile.details.drool)
        : 0;
    score -= userProfile.neatFreak
        ? negativeAttributeScore(petProfile.details.amountOfShedding)
        : 0;
    score -= userProfile.neatFreak
        ? negativeAttributeScore(petProfile.details.groomingRequired)
        : 0;
    score -= userProfile.yardOrPark
        ? 2 * negativeAttributeScore(petProfile.details.energy)
        : 0;
    score -= userProfile.activeLifestyle
        ? 2 * negativeAttributeScore(petProfile.details.exerciseNeeds)
        : 0;
    score -= userProfile.apartment
        ? 2 * negativeAttributeScore(petProfile.details.energy)
        : 0;
    return score;
};
const positiveAttributeScore = (level) => ({
    [petProfile_1.ELevel.low]: 2,
    [petProfile_1.ELevel.medium]: 1,
    [petProfile_1.ELevel.high]: 0,
}[level]);
const negativeAttributeScore = (level) => ({
    [petProfile_1.ELevel.low]: 0,
    [petProfile_1.ELevel.medium]: 1,
    [petProfile_1.ELevel.high]: 2,
}[level]);
