"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
var AuthLoadingStatus;
(function (AuthLoadingStatus) {
    AuthLoadingStatus["loading"] = "loading";
    AuthLoadingStatus["loaded"] = "loaded";
    AuthLoadingStatus["none"] = "none";
})(AuthLoadingStatus = exports.AuthLoadingStatus || (exports.AuthLoadingStatus = {}));
exports.CredentialRecord = immutable_1.Record({
    email: '',
    uid: '',
});
exports.AuthStateRecord = (partial) => {
    var _a, _b;
    return immutable_1.Record({
        credential: (_a = partial) === null || _a === void 0 ? void 0 : _a.credential,
        authLoadingStatus: ((_b = partial) === null || _b === void 0 ? void 0 : _b.authLoadingStatus) || AuthLoadingStatus.none,
    })();
};
