import React, { useState } from 'react';
import styled from 'styled-components';
import {
  LoginInput,
  Text,
  TextButton,
  Logo,
  ELogoTextStyle,
  Description,
  Button,
} from '@fido/web-components';
import { unit, teal } from '@fido/styles';

const Separator = styled.div`
  height: 10px;
`;

const TextSpacer = styled.div`
  display: inline-block;
  width: 0.25em;
`;

const LogoContainer = styled.div`
  margin-bottom: ${unit * 10}px;
`;

const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

const LegalContainer = styled(Description)`
  text-align: center;

  & > a {
    color: ${teal};
  }
`;

const fullButtonStyle = {
  width: '100%'
}

export interface ICreateWithEmailFormProps {
  onSubmit(email: string, password: string): void;
}

export const CreateWithEmailForm = ({
  onSubmit,
}: ICreateWithEmailFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <>
      <LogoContainer>
        <Logo textStyle={ELogoTextStyle.dark} />
      </LogoContainer>
      <form
        onSubmit={e => {
          onSubmit(email, password);
          e.preventDefault();
        }}>
        <HiddenInput type="submit" />
        <LoginInput
          variant="email"
          inputMode="email"
          placeholder="Email"
          value={email}
          autoFocus
          onChangeValue={setEmail}
        />
        <Separator />
        <LoginInput
          variant="password"
          placeholder="Password"
          value={password}
          autoFocus={false}
          onChangeValue={setPassword}
        />
        <Separator />
        <Button style={fullButtonStyle} variant="primary" size="large">Create Account</Button>
      </form>
      <Separator />
      <LegalContainer>
        By continuing, you're agreeing to our<br />
        <a target="_blank" href="https://barkd.com/terms-of-service">
          User Terms of Service
        </a>
        &nbsp;and&nbsp;
        <a target="_blank" href="https://barkd.com/privacy">
          Privacy Policy
        </a>
        .
      </LegalContainer>
    </>
  );
};
