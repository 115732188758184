import { RecordOf, Record } from 'immutable';

export interface IPaymentMethodModalState {
  ssnLast4: string;
  routingNumber: string;
  accountNumber: string;
}

export type IPaymentMethodModalStateRecord = RecordOf<IPaymentMethodModalState>;

export const PaymentMethodModalStateRecord = Record<IPaymentMethodModalState>({
  ssnLast4: '',
  routingNumber: '',
  accountNumber: '',
});

export enum EPaymentMethodModalActionType {
  setSSNLast4 = 'setSSNLast4',
  setRoutingNumber = 'setRoutingNumber',
  setAccountNumber = 'setAccountNumber',
}

export type PaymentMethodModalAction =
  | {
      type: EPaymentMethodModalActionType.setSSNLast4;
      payload: { ssnLast4: string };
    }
  | {
      type: EPaymentMethodModalActionType.setRoutingNumber;
      payload: { routingNumber: string };
    }
  | {
      type: EPaymentMethodModalActionType.setAccountNumber;
      payload: { accountNumber: string };
    };

export const setSSNLast4 = (ssnLast4: string): PaymentMethodModalAction => ({
  type: EPaymentMethodModalActionType.setSSNLast4,
  payload: {
    ssnLast4,
  },
});

export const setRoutingNumber = (
  routingNumber: string
): PaymentMethodModalAction => ({
  type: EPaymentMethodModalActionType.setRoutingNumber,
  payload: {
    routingNumber,
  },
});

export const setAccountNumber = (
  accountNumber: string
): PaymentMethodModalAction => ({
  type: EPaymentMethodModalActionType.setAccountNumber,
  payload: {
    accountNumber,
  },
});

export const paymentMethodModalReducer = (
  state: IPaymentMethodModalStateRecord,
  action: PaymentMethodModalAction
) => {
  switch (action.type) {
    case EPaymentMethodModalActionType.setSSNLast4:
      return state.set('ssnLast4', action.payload.ssnLast4);
    case EPaymentMethodModalActionType.setRoutingNumber:
      return state.set('routingNumber', action.payload.routingNumber);
    case EPaymentMethodModalActionType.setAccountNumber:
      return state.set('accountNumber', action.payload.accountNumber);
  }
};
