"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const rolesTypes_1 = require("./rolesTypes");
exports.rolesStateReducers = {
    resetReviewState: (state) => rolesTypes_1.RolesStateRecord(),
    setRoles: (state, { payload: { roles } }) => state.set('roles', roles),
    setRole: (state, { payload: { id, role }, }) => state.setIn(['roles', id], role),
    mergeRoles: (state, { payload: { role }, }) => state.update('roles', p => p.merge(role)),
};
exports.rolesStateReducer = reduxHelpers_1.makeReducer(rolesTypes_1.RolesStateRecord(), exports.rolesStateReducers);
