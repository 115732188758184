import { RecordOf, Record, Map, List } from 'immutable';
import {
  IUserProfileLocationRecord,
  UserProfileLocationRecord,
} from '@fido/common';
import moment from 'moment';

export interface IProfileSettingsState {
  name: string;
  location: IUserProfileLocationRecord;
  cellNumber: string;
  enableSms: boolean;
  description: string;
  includedWithPuppyDescription: string;
  healthDescription: string;
  healthGuaranteeDescription: string;
  deliveryDescription: string;
  avatar: File | null;
  minValue: string;
  maxValue: string;
  breeds: List<string>;
  images: List<string>;
  address: any;
  latitude: string;
  longitude: string;
  generalWaitlistEnabled: boolean,
  createdAt: string,
  modifiedAt: string,
  sellerID: string,
}

export type IProfileSettingsStateRecord = RecordOf<IProfileSettingsState>;
export const ProfileSettingsStateRecord = (
    partial?: IProfileSettingsState
  ) =>
    Record<IProfileSettingsState>({
  name: partial?.name || '',
  location: UserProfileLocationRecord(partial?.location),
  cellNumber: partial?.cellNumber || '',
  enableSms: partial?.enableSms || true,
  description: partial?.description || '',
  includedWithPuppyDescription: partial?.includedWithPuppyDescription || '',
  healthDescription: partial?.healthDescription || '',
  healthGuaranteeDescription: partial?.healthGuaranteeDescription || '',
  deliveryDescription: partial?.deliveryDescription || '',
  avatar: partial?.avatar || null,
  minValue: partial?.minValue || '',
  maxValue: partial?.maxValue || '',
  images: List(partial?.images || []),
  breeds: List(partial?.breeds || []),
  address: partial?.address || {},
  latitude: partial?.latitude || '',
  longitude: partial?.longitude || '',
  generalWaitlistEnabled: partial?.generalWaitlistEnabled || false,
  createdAt: partial?.createdAt || moment().format(),
  modifiedAt: partial?.modifiedAt || moment().format(),
  sellerID: partial?.sellerID || '',
})();

export enum EProfileSettingsActionType {
  setName = 'setName',
  setDescription = 'setDescription',
  setCellNumber = 'setCellNumber',
  setSmsEnable = 'setSmsEnable',
  setIncludedWithPuppyDescription = 'setIncludedWithPuppyDescription',
  setHealthDescription = 'setHealthDescription',
  setHealthGuaranteeDescription = 'setHealthGuaranteeDescription',
  setDeliveryDescription = 'setDeliveryDescription',
  setLocation = 'setLocation',
  setAvatar = 'setAvatar',
  setMinValue = 'setMinValue',
  setMaxValue = 'setMaxValue',
  setBreeds = 'setBreeds',
  setImages = 'setImages',
  setAddress = 'setAddress',
  setLatitude = 'setLatitude',
  setLongitude = 'setLongitude',
  setGeneralWaitlistEnabled = 'setGeneralWaitlistEnabled',
  setSellerID = 'setSellerID',
}

export type ProfileSettingsAction =
  | {
    type: EProfileSettingsActionType.setSellerID;
    payload: { sellerID: string };
  }
  | {
      type: EProfileSettingsActionType.setName;
      payload: { name: string };
    }
  | {
      type: EProfileSettingsActionType.setDescription;
      payload: { description: string };
    }
  | {
      type: EProfileSettingsActionType.setCellNumber;
      payload: { cellNumber: string };
    }
  | {
      type: EProfileSettingsActionType.setSmsEnable;
      payload: { enableSms: boolean };
    }
  | {
      type: EProfileSettingsActionType.setIncludedWithPuppyDescription;
      payload: { includedWithPuppyDescription: string };
    }
  | {
      type: EProfileSettingsActionType.setHealthDescription;
      payload: { healthDescription: string };
    }
  | {
      type: EProfileSettingsActionType.setHealthGuaranteeDescription;
      payload: { healthGuaranteeDescription: string };
    }
  | {
      type: EProfileSettingsActionType.setDeliveryDescription;
      payload: { deliveryDescription: string };
    }
  | {
      type: EProfileSettingsActionType.setLocation;
      payload: { location: IUserProfileLocationRecord };
    }
  | {
      type: EProfileSettingsActionType.setAvatar;
      payload: { avatar: File | null };
    }
  | {
      type: EProfileSettingsActionType.setMinValue;
      payload: { minValue: string };
    }
  | {
      type: EProfileSettingsActionType.setMaxValue;
      payload: { maxValue: string };
    }
  | {
      type: EProfileSettingsActionType.setBreeds;
      payload: { breeds: List<string> };
    }
  | {
      type: EProfileSettingsActionType.setImages;
      payload: { images: List<string> };
    }
  | {
      type: EProfileSettingsActionType.setAddress;
      payload: { address: any };
    }
  | {
      type: EProfileSettingsActionType.setLatitude;
      payload: {latitude : string };
    }
  | {
      type: EProfileSettingsActionType.setLongitude;
      payload: {longitude : string };
    }
  | {
      type: EProfileSettingsActionType.setGeneralWaitlistEnabled;
      payload: {generalWaitlistEnabled : boolean };
    };

export const setSellerID = (sellerID: string): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setSellerID,
  payload: {
    sellerID,
  },
});

export const setName = (name: string): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setName,
  payload: {
    name,
  },
});

export const setDescription = (description: string): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setDescription,
  payload: {
    description,
  },
});

export const setIncludedWithPuppyDescription = (
  includedWithPuppyDescription: string
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setIncludedWithPuppyDescription,
  payload: {
    includedWithPuppyDescription,
  },
});

export const setHealthDescription = (
  healthDescription: string
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setHealthDescription,
  payload: {
    healthDescription,
  },
});

export const setHealthGuaranteeDescription = (
  healthGuaranteeDescription: string
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setHealthGuaranteeDescription,
  payload: {
    healthGuaranteeDescription,
  },
});

export const setDeliveryDescription = (
  deliveryDescription: string
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setDeliveryDescription,
  payload: {
    deliveryDescription,
  },
});

export const setLocation = (
  location: IUserProfileLocationRecord
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setLocation,
  payload: {
    location,
  },
});

export const setCellNumber = (
  cellNumber: string
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setCellNumber,
  payload: {
    cellNumber,
  },
});

export const setSmsEnable = (
  enableSms: boolean
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setSmsEnable,
  payload: {
    enableSms,
  },
});


export const setAvatar = (
  avatar: File | null
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setAvatar,
  payload: {
    avatar,
  },
});

export const setMinValue = (
  minValue: string
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setMinValue,
  payload: {
    minValue,
  },
});

export const setMaxValue = (
  maxValue: string
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setMaxValue,
  payload: {
    maxValue,
  },
});

export const setBreeds = (
  breeds: List<string>
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setBreeds,
  payload: {
    breeds,
  },
});

export const setImages = (
  images: List<string>
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setImages,
  payload: {
    images,
  },
});

export const setAddress = (
  address: any): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setAddress,
  payload: {
    address,
  },
});

export const setLatitude = (
  latitude: string): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setLatitude,
  payload: {
    latitude,
  },
});

export const setLongitude = (
  longitude: string): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setLongitude,
  payload: {
    longitude,
  },
});

export const setGeneralWaitlistEnabled = (
  generalWaitlistEnabled: boolean
): ProfileSettingsAction => ({
  type: EProfileSettingsActionType.setGeneralWaitlistEnabled,
  payload: {
    generalWaitlistEnabled,
  },
});

export const profileSettingsReducer = (
  state: IProfileSettingsStateRecord,
  action: ProfileSettingsAction
) => {
  switch (action.type) {
    case EProfileSettingsActionType.setSellerID:
      return state.set('sellerID', action.payload.sellerID);
    case EProfileSettingsActionType.setName:
      return state.set('name', action.payload.name);
    case EProfileSettingsActionType.setDescription:
      return state.set('description', action.payload.description);
    case EProfileSettingsActionType.setIncludedWithPuppyDescription:
      return state.set(
        'includedWithPuppyDescription',
        action.payload.includedWithPuppyDescription
      );
    case EProfileSettingsActionType.setHealthDescription:
      return state.set('healthDescription', action.payload.healthDescription);
    case EProfileSettingsActionType.setHealthGuaranteeDescription:
      return state.set(
        'healthGuaranteeDescription',
        action.payload.healthGuaranteeDescription
      );
    case EProfileSettingsActionType.setDeliveryDescription:
      return state.set(
        'deliveryDescription',
        action.payload.deliveryDescription
      );
    case EProfileSettingsActionType.setLocation:
      return state.set('location', action.payload.location);
    case EProfileSettingsActionType.setCellNumber:
      return state.set('cellNumber', action.payload.cellNumber);
    case EProfileSettingsActionType.setSmsEnable:
      return state.set('enableSms', action.payload.enableSms);
    case EProfileSettingsActionType.setAvatar:
      return state.set('avatar', action.payload.avatar);
    case EProfileSettingsActionType.setMinValue:
      return state.set('minValue', action.payload.minValue);
    case EProfileSettingsActionType.setMaxValue:
      return state.set('maxValue', action.payload.maxValue);
    case EProfileSettingsActionType.setBreeds:
      return state.set('breeds', action.payload.breeds);
    case EProfileSettingsActionType.setImages:
      return state.set('images', action.payload.images);
    case EProfileSettingsActionType.setAddress:
      return state.set('address', action.payload.address);
    case EProfileSettingsActionType.setLatitude:
      return state.set('latitude', action.payload.latitude);
    case EProfileSettingsActionType.setLongitude:
      return state.set('longitude', action.payload.longitude);
    case EProfileSettingsActionType.setGeneralWaitlistEnabled:
      return state.set(
        'generalWaitlistEnabled', action.payload.generalWaitlistEnabled
      );
  }
};
