"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reduxHelpers_1 = require("../reduxHelpers");
const paymentStateTypes_1 = require("./paymentStateTypes");
exports.paymentStateReducers = {
    resetPaymentState: (state) => paymentStateTypes_1.PaymentStateRecord(),
    setPayments: (state, { payload: { payments } }) => state.set('payments', payments),
    setPayment: (state, { payload: { payment, id }, }) => state.setIn(['payments', id], payment),
};
exports.paymentStateReducer = reduxHelpers_1.makeReducer(paymentStateTypes_1.PaymentStateRecord(), exports.paymentStateReducers);
