"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@fido/styles");
const react_1 = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const Text_1 = require("../Text");
const Button_1 = require("../Button");
const activeStyles = ({ active }) => active
    ? styled_components_1.css `
        opacity: 0.6;
      `
    : styled_components_1.css ``;
const variantStyles = ({ variant, }) => {
    switch (variant) {
        case Button_1.EButtonVariant.default:
            return styled_components_1.css `
        color: ${styles_1.gray};
      `;
        case Button_1.EButtonVariant.primary:
            return styled_components_1.css `
        color: ${styles_1.turq};
      `;
    }
};
const StyledText = styled_components_1.default(Text_1.Text) `
  cursor: pointer;
  ${activeStyles}
  ${variantStyles}
`;
exports.TextButton = (_a) => {
    var { variant = Button_1.EButtonVariant.default, onClick } = _a, etcProps = __rest(_a, ["variant", "onClick"]);
    const [active, setActive] = react_1.useState(false);
    return (react_1.default.createElement(StyledText, Object.assign({ variant: variant, active: active, onClick: onClick, onMouseDown: () => setActive(true), onMouseUp: () => setActive(false) }, etcProps)));
};
