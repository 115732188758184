import { IPetProfileRecord } from '@fido/common';
import { unit } from '@fido/styles';
import { Button, mobile, ProfileCard, Text } from '@fido/web-components';
import { Map } from 'immutable';
import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: ${unit * 4}px;
`;

const ControlsContainer = styled.div`
  padding-bottom: ${unit * 4}px;
`;

const StyledProfileCard = styled(ProfileCard)`
  cursor: pointer;

  ${mobile(css`
    height: 200px;
  `)}
`;

const ProfileList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${unit * 4}px;

  ${StyledProfileCard} {
    display: flex;
    flex: 1;
    margin-bottom: ${unit * 2}px;
  }
`;

const CompleteProfileText = styled(Text)`
  margin-left: ${unit * 3}px;
`;

export interface IPetProfileProps {
  hasCompletedSellerProfile: boolean;
  profiles: Map<string, IPetProfileRecord>;
  numberOfApplication: Map<string, number>;
  loadInitialData(): void;
  loadApplicationCounts(): void;
}

export const PetProfiles = ({
  hasCompletedSellerProfile,
  profiles,
  numberOfApplication,
  loadInitialData,
  loadApplicationCounts,
}: IPetProfileProps) => {
  useEffect(() => {
    loadInitialData();
  }, []);
  useEffect(() => {
    loadApplicationCounts();
  }, [profiles]);
  const history = useHistory();
  const { path } = useRouteMatch();
  return (
    <Container>
      <ControlsContainer>
        <Button
          variant="primary"
          size="large"
          disabled={!hasCompletedSellerProfile}
          onClick={() => history.push(`${path}/create`)}>
          Add a Pup
        </Button>
        {!hasCompletedSellerProfile && (
          <CompleteProfileText>
            Welcome to the Barkd Breeder Platform! To begin adding puppies to the platform, start by completing your Breeder Profile and Payment Information which you can access at the top right corner of the screen.
          </CompleteProfileText>
        )}
      </ControlsContainer>
      <ProfileList>
        {profiles
          .filter(profile => !profile.sold)
          .mapEntries(([profileID, profile]) => [
            profileID,
            <StyledProfileCard
              key={profileID}
              viewsCount={profile.views}
              title={profile.name}
              description={profile.description}
              imageSrc={profile.images.first()}
              numberOfApplications={numberOfApplication.get(profileID)}
              onClick={() => history.push(`${path}/${profileID}`)}
            />
          ])
          .valueSeq()
          .toArray()}
      </ProfileList>
    </Container>
  );
};
