"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_actions_1 = require("redux-actions");
// tslint:disable-next-line:no-var-requires
const pkg = require('../../package.json');
exports.createPackageActionType = (actionType) => `${pkg.name}/${pkg.version}/${actionType}`;
exports.createPackageAction = (actionType) => redux_actions_1.createAction(exports.createPackageActionType(actionType));
exports.makeReducer = (initialState, reducers) => {
    return redux_actions_1.handleActions(Object.keys(reducers).reduce((previous, key) => (Object.assign({ [exports.createPackageActionType(key)]: reducers[key] }, previous)), {}), initialState);
};
