import { RecordOf, Record } from 'immutable';
import { EPaymentPreference } from '@fido/common';

export interface IPaymentSettingsState {
  depositAmount: string;
  paymentPreference: EPaymentPreference;
}

export type IPaymentSettingsStateRecord = RecordOf<IPaymentSettingsState>;

export const PaymentSettingsStateRecord = Record<IPaymentSettingsState>({
  depositAmount: '',
  paymentPreference: EPaymentPreference.internal,
});

export enum EPaymentSettingsActionType {
  setDepositAmount = 'setDepositAmount',
  setPaymentPreference = 'setPaymentPreference',
}

export type PaymentSettingsAction =
  | {
      type: EPaymentSettingsActionType.setDepositAmount;
      payload: { depositAmount: string };
    }
  | {
      type: EPaymentSettingsActionType.setPaymentPreference;
      payload: { paymentPreference: EPaymentPreference };
    };

export const setDepositAmount = (
  depositAmount: string
): PaymentSettingsAction => ({
  type: EPaymentSettingsActionType.setDepositAmount,
  payload: {
    depositAmount,
  },
});

export const setPaymentPreference = (
  paymentPreference: EPaymentPreference
): PaymentSettingsAction => ({
  type: EPaymentSettingsActionType.setPaymentPreference,
  payload: {
    paymentPreference,
  },
});

export const paymentSettingsReducer = (
  state: IPaymentSettingsStateRecord,
  action: PaymentSettingsAction
) => {
  switch (action.type) {
    case EPaymentSettingsActionType.setDepositAmount:
      return state.set('depositAmount', action.payload.depositAmount);
    case EPaymentSettingsActionType.setPaymentPreference:
      return state.set('paymentPreference', action.payload.paymentPreference);
  }
};
