"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("../api");
const state_1 = require("../state");
exports.createApplication = (create) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.createApplication(create);
    result.map(({ id, application }) => {
        dispatch(state_1.setApplication({
            id,
            application,
        }));
    });
});
exports.fetchApplications = (userID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchApplications(userID);
    result.map(applications => {
        dispatch(state_1.setApplications({
            applications,
        }));
    });
});
exports.fetchApplicationsBySeller = (sellerProfileID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchApplicationsBySeller(sellerProfileID);
    result.map(applications => {
        dispatch(state_1.setApplications({
            applications,
        }));
    });
});
exports.fetchApplicationsByPetProfile = (petProfileID) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.fetchApplicationsByPetProfile(petProfileID);
    result.map(applications => {
        dispatch(state_1.mergeApplications({
            applications,
        }));
    });
});
exports.updateApplication = (id, application) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.updateApplication(id, application);
    result.map(() => {
        dispatch(state_1.setApplication({
            id,
            application,
        }));
    });
});
exports.updateApplicationBuyerReadStatus = (applicationID, readStatus) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.updateApplicationBuyerReadStatus(applicationID, readStatus);
    result.map(({ application }) => {
        dispatch(state_1.setApplication({
            id: applicationID,
            application,
        }));
    });
});
exports.updateApplicationSellerReadStatus = (applicationID, readStatus) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.updateApplicationSellerReadStatus(applicationID, readStatus);
    result.map(({ application }) => {
        dispatch(state_1.setApplication({
            id: applicationID,
            application,
        }));
    });
});
exports.updateApplicationNotes = (applicationID, notes) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield api_1.updateApplicationNotes(applicationID, notes);
    result.map(({ application }) => {
        dispatch(state_1.setApplication({
            id: applicationID,
            application,
        }));
    });
});
