"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const requiredEnvVarNames = [
    'FIDO_SERVICE_HOST',
    'FIDO_SERVICE_PORT',
    'FIDO_SERVICE_PROTOCOL',
];
requiredEnvVarNames.forEach(varName => {
    if (!process.env.hasOwnProperty(varName)) {
        throw new Error(`Required env variable "${varName}" must be provided.`);
    }
});
exports.fidoServiceBaseURL = `${process.env.FIDO_SERVICE_PROTOCOL}://${process.env.FIDO_SERVICE_HOST}:${process.env.FIDO_SERVICE_PORT}`;
