"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const immutable_1 = require("immutable");
exports.ConversationRecord = immutable_1.Record({
    userID: '',
    sellerProfileID: '',
    createdAt: '',
    updatedAt: '',
    type: '',
    path: ''
});
var EMessageReadStatus;
(function (EMessageReadStatus) {
    EMessageReadStatus["read"] = "read";
    EMessageReadStatus["unread"] = "unread";
})(EMessageReadStatus = exports.EMessageReadStatus || (exports.EMessageReadStatus = {}));
exports.MessageRecord = immutable_1.Record({
    conversationID: '',
    text: '',
    fromID: '',
    toID: '',
    createdAt: '',
    readStatus: EMessageReadStatus.unread,
    type: '',
    path: ''
});
exports.CreateMessageRecord = immutable_1.Record({
    conversationID: '',
    text: '',
    fromID: '',
    toID: '',
    type: '',
    path: ''
});
exports.MessageStateRecord = immutable_1.Record({
    conversations: immutable_1.Map(),
    messages: immutable_1.Map(),
});
